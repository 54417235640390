import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import BaseSelect from '../../lib/BaseSelect';
import ButtonClose from '../ButtonClose';
import { useStyles } from './styles';
const sanityCheck = ({ optional, placeholder, selected }) => {
    if (optional && placeholder)
        console.warn('[Select] an optional select should not be accompanied with a placeholder');
    if (!selected && !optional && !placeholder)
        console.warn('[Select] no selected value provided. select should either be optional and be given a placeholder');
};
const stringifySelected = (selected) => (typeof selected === 'undefined' ? '' : String(selected));
const showAllOption = { name: 'show all', id: '' };
const defaultProps = {
    minWidth: 106,
};
const Select = forwardRef((props, ref) => {
    const { classes, options, selected, optional, placeholder, minWidth, fullWidth, disabled, onClose, onEnter, idToNameConvertor, onChange, sorted, searchable, } = props;
    const ownClasses = useStyles({ fullWidth });
    sanityCheck(props);
    const sortFunction = (a, b) => {
        const x = String(a.name).toLowerCase();
        const y = String(b.name).toLowerCase();
        if (x < y)
            return -1;
        if (x > y)
            return 1;
        return 0;
    };
    const getInitialOptions = () => {
        const options = [];
        if (selected)
            options.push({
                name: String(idToNameConvertor ? idToNameConvertor(selected) : selected),
                id: String(selected),
            });
        if (optional)
            options.push(showAllOption);
        if (sorted)
            options.sort(sortFunction);
        return options;
    };
    const [processedOptions, setProcessedOptions] = useState(getInitialOptions);
    const [selectedValue, setSelectedValue] = useState(stringifySelected(selected));
    const [isOptionalSelected, setIsOptionalSelected] = useState(false);
    useImperativeHandle(ref, () => ({
        clearSelection() {
            setSelectedValue('');
        },
    }));
    const handleChange = (newSelected) => {
        setSelectedValue(newSelected);
        onChange(newSelected);
    };
    const handleClose = () => {
        if (optional)
            setSelectedValue('');
        else if (onClose)
            onClose();
    };
    useEffect(() => {
        if (options) {
            const extendedOptions = options.map(option => {
                const id = String(option);
                const name = String(idToNameConvertor ? idToNameConvertor(option) : id);
                return { name, id };
            });
            if (optional)
                extendedOptions.push(showAllOption);
            if (sorted)
                extendedOptions.sort(sortFunction);
            setProcessedOptions(extendedOptions);
        }
    }, [options]);
    useEffect(() => {
        setIsOptionalSelected(Boolean(optional) && !selectedValue);
    }, [selectedValue]);
    useEffect(() => {
        setSelectedValue(stringifySelected(selected));
    }, [selected]);
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root) },
        onClose && React.createElement(ButtonClose, { onClose: handleClose, isEmpty: isOptionalSelected }),
        React.createElement(BaseSelect, { classes: {
                input: clsx({ [ownClasses.selectInputWithClose]: Boolean(onClose) }, classes?.input),
                inputRoot: clsx(ownClasses?.inputRoot),
                menuPaper: classes?.menuPaper,
                openButton: classes?.openButton,
                root: ownClasses.baseSelectRoot,
            }, options: processedOptions, selectedValue: String(selectedValue), placeholder: placeholder, minWidth: minWidth, onChange: handleChange, onEnter: onEnter, disabled: disabled, searchable: Boolean(searchable) })));
});
export default Select;
