import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../../../common/ui/styles/helpers';
import { performanceAnalysisNavigation } from '../../styles';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
        },
        pathLineContainer: {
            width: '100%',
        },
        pathLine: {
            display: 'flex',
        },
        ...performanceAnalysisNavigation(factoryTheme),
    });
});
