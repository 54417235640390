import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../../common/ui/styles/helpers';
export const useStyles = makeStyles(() => {
    return createFactoryStyles({
        navLabelRoot: {
            marginRight: 2,
        },
        navLabel: {
            borderRadius: 0,
        },
    });
});
