import { format } from 'date-fns';
import { getDuration, cloneJSON, isObject } from './utils';
export const shortenSha = (sha) => {
    return sha.slice(0, 12);
};
export const formatDateTime = (datetime) => {
    return format(Date.parse(`${datetime}`), 'yyyy-MM-dd HH:mm:ss');
};
export const computeAutomationDuration = (start, end) => {
    if (start && !end) {
        return getDuration(new Date(start), new Date());
    }
    else if (start && end) {
        return getDuration(new Date(start), new Date(end));
    }
    return undefined;
};
export const getProcessedCommitSelectData = (commitSelectData) => {
    const processed = {};
    const { selected, options } = commitSelectData;
    // if (selected) processed.selected = { label: shortenSha(selected), code: selected };
    if (selected)
        processed.selected = selected;
    // if (options) processed.options = options.map(sha => ({ label: shortenSha(sha), code: sha }));
    if (options)
        processed.options = options;
    return processed;
};
export const checkForNestedProp = (obj, level, ...rest) => {
    if (!isObject(obj))
        return false;
    if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, level) && typeof obj[level] !== 'undefined')
        return true;
    // @ts-ignore
    return checkForNestedProp(obj[level], ...rest);
};
export const constructAutomationSummary = (data, whitelist) => {
    const summary = {};
    if (whitelist.includes('run'))
        summary.run = data.run;
    if (whitelist.includes('status'))
        summary.status = data.status;
    if (whitelist.includes('started'))
        summary.started = data.started;
    if (whitelist.includes('ended'))
        summary.ended = data.ended;
    if (whitelist.includes('select'))
        summary.select = { selected: data.selectedRun, options: data.runOptions };
    if (whitelist.includes('menu'))
        summary.menu = { rerun: true };
    return summary;
};
export const extractDataFromSymbol = (symbol) => {
    const re = /[\/:@]+/;
    const options = symbol.split(re);
    const result = {
        owner: options[0],
        repository: options[1],
        commit: options[2],
        pipeline: options[3],
        pipelineRun: options[4],
        workflow: options[5],
        workflowRun: options[6],
        job: options[7],
        jobRun: options[8],
    };
    return result;
};
export const constructRerunMessage = ({ type, name }) => {
    return `Rerun of the ${name} ${type} is now in progress.\nYou will be presented with the data of the new run shortly.`;
};
export const consturctUrlForCommitPage = commitSymbol => {
    const { owner, repository, commit } = extractDataFromSymbol(commitSymbol);
    return `/${owner}/${repository}/${commit}`;
};
export const constructUrlForJobPage = jobSymbol => {
    const { owner, repository, commit, pipeline, pipelineRun, workflow, workflowRun, job, jobRun, } = extractDataFromSymbol(jobSymbol);
    return `/${owner}/${repository}/${commit}/${pipeline}/${pipelineRun}/${workflow}/${workflowRun}/${job}/${jobRun}`;
};
export const constructUrlForWorkflowPage = workflowSymbol => {
    const { owner, repository, commit, pipeline, pipelineRun, workflow, workflowRun } = extractDataFromSymbol(workflowSymbol);
    return `/${owner}/${repository}/${commit}/${pipeline}/${pipelineRun}/${workflow}/${workflowRun}`;
};
export const constructUrlForPipelinePage = pipelineSymbol => {
    const { owner, repository, commit, pipeline, pipelineRun } = extractDataFromSymbol(pipelineSymbol);
    return `/${owner}/${repository}/${commit}/${pipeline}/${pipelineRun}`;
};
const defaultOptionsForPerformanceAnalysis = {
    analysis: {
        id: {},
        compare: undefined,
        trace: {
            path: [{ optional: true }],
            tracker: { optional: true },
            labels: { names: [] },
            iteration: {},
        },
    },
};
export const constructUrlForPerformanceAnalysisPage = (commitSymbol, id) => {
    const { owner, repository, commit } = extractDataFromSymbol(commitSymbol);
    const analysisOptions = cloneJSON(defaultOptionsForPerformanceAnalysis);
    analysisOptions.analysis.id.selected = id;
    return `/${owner}/${repository}/${commit}/analysis/performance-analysis?q=${JSON.stringify(analysisOptions)}`;
};
export const constructRouteForPipelineRerun = (pipelineSymbol) => {
    const { owner, repository, commit, pipeline } = extractDataFromSymbol(pipelineSymbol);
    return `${owner}/${repository}/${commit}/${pipeline}/rerun`;
};
export const constructRouteForWorkflowRerun = (workflowSymbol) => {
    const { owner, repository, commit, pipeline, pipelineRun, workflow } = extractDataFromSymbol(workflowSymbol);
    return `${owner}/${repository}/${commit}/${pipeline}/${pipelineRun}/${workflow}/rerun`;
};
export const constructRouteForJobRerun = (jobSymbol) => {
    const { owner, repository, commit, pipeline, pipelineRun, workflow, workflowRun, job } = extractDataFromSymbol(jobSymbol);
    return `${owner}/${repository}/${commit}/${pipeline}/${pipelineRun}/${workflow}/${workflowRun}/${job}/rerun`;
};
export const constructRouteForReleaseApproval = (jobSymbol) => {
    const { owner, repository, commit, pipelineRun } = extractDataFromSymbol(jobSymbol);
    return `${owner}/${repository}/${commit}/release/${pipelineRun}/approve`;
};
/**
 * AUTOMATION-PAGE-SPECIFIC HELPERS
 */
export const getRunOptions = (automation) => {
    return Object.keys(automation).map(run => parseInt(run));
};
export const findLastRunOption = (automation) => {
    const runOptions = getRunOptions(automation);
    return runOptions[runOptions.length - 1];
};
