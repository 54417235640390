import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            backgroundColor: factoryTheme.palette.purple[500],
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            '& > a': {
                display: 'block',
            },
        },
        clickable: {
            '&:hover': {
                border: `1px solid ${factoryTheme.palette.green[300]}`,
            },
        },
        header: {
            display: 'flex',
            padding: factoryTheme.spacing(6, 6, 3, 6),
        },
        content: {
            padding: factoryTheme.spacing(3, 6, 6, 6),
        },
        hasEitherContentOrHeader: {
            padding: factoryTheme.spacing(6),
        },
    });
});
