import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: 24,
            backgroundColor: factoryTheme.palette.purple[400],
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
            borderRadius: factoryTheme.shape.borderRadius.small,
            color: factoryTheme.palette.white[200],
            fontSize: factoryTheme.typography.fontSize.smaller,
            fontFamily: factoryTheme.typography.fontFamily.main,
            padding: factoryTheme.spacing(1, 2),
            display: 'block',
            '&:-ms-input-placeholder': {
                color: factoryTheme.palette.purple[100],
            },
            '&::-ms-input-placeholder': {
                color: factoryTheme.palette.purple[100],
            },
            '&::placeholder': {
                color: factoryTheme.palette.purple[100],
                opacity: 1,
            },
        },
    });
});
