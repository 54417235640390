export const SET_USER = 'SET_USER';
const initialState = {
    'full-name': '',
    username: '',
};
export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};
export default reducer;
