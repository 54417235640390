import yup from '../../../validator';
const MAX_KEY_VALUE_LENGTH = 32767;
export const getSchemaForManualEntry = (variables) => yup.object({
    key: yup
        .string()
        .label('Key')
        .required()
        .max(MAX_KEY_VALUE_LENGTH)
        .matches(/^[A-Za-z_][A-Za-z0-9_]*$/)
        .test('unique', '${path} already exists.', value => !variables.map(v => v.split('=')[0]).includes(value)),
    value: yup.string().label('Value').required().max(MAX_KEY_VALUE_LENGTH),
});
export const getSchemaForImport = () => yup.object({
    key: yup
        .string()
        .label('Key')
        .required()
        .max(MAX_KEY_VALUE_LENGTH)
        .matches(/^[A-Za-z_][A-Za-z0-9_]*$/),
    value: yup.string().label('Value').required().max(MAX_KEY_VALUE_LENGTH),
});
