import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Tooltip from '../../lib/BaseTooltip';
import { useStyles } from './styles';
const ConnectionIndicator = ({ isConnected, classes }) => {
    const ownClasses = useStyles({ isConnected });
    const connectionStatus = `Live update: ${isConnected ? 'active' : 'disconnected'}`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx(ownClasses.root, classes?.root), "data-tip": true, "data-for": "connectionStatus" },
            React.createElement(Icon, { className: ownClasses.icon, icon: ['fal', 'bolt'] })),
        React.createElement(Tooltip, { id: "connectionStatus" }, connectionStatus)));
};
export default ConnectionIndicator;
