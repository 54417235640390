import { makeStyles } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: 1,
            backgroundColor: Color(factoryTheme.palette.purple[200]).alpha(0.7).string(),
            border: 0,
            flex: 1,
            alignSelf: 'center',
            margin: 0,
        },
    });
});
