import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
const typeSizeMap = {
    h1: 'small',
    h2: 'small',
    h3: 'smaller',
};
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            display: 'flex',
        },
        text: {
            alignSelf: 'center',
            color: factoryTheme.palette.purple[200],
            '&:hover': {
                color: (props) => props.isClickable ? factoryTheme.palette.green[200] : factoryTheme.palette.purple[200],
            },
        },
        line: {
            marginLeft: factoryTheme.spacing(2),
            border: 0,
            height: 1,
            flex: 1,
            background: factoryTheme.palette.purple[300],
            alignSelf: 'center',
            lineHeight: factoryTheme.spacing(4),
        },
        h1: {
            fontSize: factoryTheme.typography.fontSize[typeSizeMap.h1],
        },
        h2: {
            fontSize: factoryTheme.typography.fontSize[typeSizeMap.h2],
        },
        h3: {
            fontSize: factoryTheme.typography.fontSize[typeSizeMap.h3],
        },
    });
});
