import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        verbose: {
            backgroundColor: 'transparent',
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
        },
    });
});
