import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Select from '../../common/ui/components/Select';
import Box from '../../common/ui/lib/BaseBox';
import Title from '../../common/ui/lib/BaseTitle';
import List from '../../common/ui/lib/BaseList';
import ListItem from '../../common/ui/lib/BaseList/item';
import ButtonGroup from '../../common/ui/lib/BaseButtonGroup';
import Button from '../../common/ui/lib/BaseButton';
import { removeFromArrayByValue } from '../../common/utils';
import { useStyles } from './styles';
const OwnerRepositories = ({ actives, inactives, onMarkAsActive, onMarkAsInactive, installationUrl, emptyMsg, }) => {
    const ownClasses = useStyles();
    const [inactiveRepos, setInactiveRepos] = useState(inactives);
    const [activeRepos, setActiveRepos] = useState(actives);
    const [repoToActivate, setRepoToActivate] = useState();
    const refRepoSelect = useRef(null);
    const routeParams = useParams();
    const handleMarkRepoInactive = (repoName) => {
        const activeReposUpdated = removeFromArrayByValue(activeRepos, repoName);
        setActiveRepos(activeReposUpdated);
        setInactiveRepos([...inactiveRepos, repoName]);
        onMarkAsInactive(repoName);
    };
    const handleRepoSelect = (repoName) => {
        setRepoToActivate(repoName);
    };
    const handleMarkRepoActive = () => {
        if (!repoToActivate)
            throw Error('[OwnerRepositories] `repoToActivate` has not yet been assigned a value');
        refRepoSelect.current?.clearSelection();
        if (!inactiveRepos.includes(repoToActivate))
            return;
        const inactiveReposUpdated = removeFromArrayByValue(inactiveRepos, repoToActivate);
        setInactiveRepos(inactiveReposUpdated);
        setActiveRepos([...activeRepos, repoToActivate]);
        setRepoToActivate(undefined);
        onMarkAsActive(repoToActivate);
    };
    return (React.createElement(Box, { classes: { root: ownClasses.repositories }, header: React.createElement(Title, { type: "h1" }, "Repositories") },
        React.createElement(List, { emptyMsg: emptyMsg }, activeRepos.map(repo => (React.createElement(ListItem, { key: repo, actions: React.createElement(ButtonGroup, null,
                React.createElement(Button, { to: `/${routeParams.owner}/${repo}/settings` },
                    React.createElement(Icon, { icon: ['fal', 'cog'] })),
                React.createElement(Button, { onClick: () => handleMarkRepoInactive(repo) },
                    React.createElement(Icon, { icon: ['fal', 'times'] }))) },
            React.createElement("p", null, repo))))),
        React.createElement("div", { className: ownClasses.activeRepoEntry },
            React.createElement(Select, { options: inactiveRepos, selected: undefined, sorted: true, searchable: true, placeholder: "Select a repository to activate", onChange: handleRepoSelect, onEnter: handleMarkRepoActive, classes: { root: ownClasses.activeRepoSelect }, fullWidth: true, disabled: inactiveRepos.length === 0, ref: refRepoSelect }),
            React.createElement(Button, { disabled: inactiveRepos.length === 0, onClick: handleMarkRepoActive, classes: { root: ownClasses.markAsActiveBtn } },
                React.createElement(Icon, { icon: ['fal', 'plus'] }))),
        React.createElement("a", { className: ownClasses.installationLink, target: "_blank", rel: "noreferrer", href: installationUrl }, "provide access to repositories from Github")));
};
export default OwnerRepositories;
