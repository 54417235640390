import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        box: {
            marginBottom: factoryTheme.spacing(6),
        },
        boxHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerAction: {
            marginLeft: factoryTheme.spacing(2),
        },
        fileUploader: {
            display: 'none',
        },
    });
});
