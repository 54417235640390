import { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import { getEngine } from './engine';
const getDefaultStage = () => {
    const defaultStage = new PIXI.Container();
    defaultStage.sortableChildren = true;
    return defaultStage;
};
export function useChart() {
    const rootRef = useRef(null);
    const overlayRef = useRef(null);
    const canvasRef = useRef(null);
    const [stage, setStage] = useState(getDefaultStage());
    useEffect(() => () => stage.destroy(true), []);
    return { rootRef, overlayRef, canvasRef, stage, setStage };
}
export function useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, ranges, isLogScaled, render) {
    const [useWebglCanvas, setUseWebglCanvas] = useState(false);
    // NOTE: We set the interaction DOM object to be our overlay object
    const setInteractionOverlay = () => {
        // NOTE: Here we set the width & height as properties on the overlay object, because internally PIXI.js uses these properties to calculate mouse event positions
        overlayRef.current.width = width;
        overlayRef.current.height = height;
        const interactionManager = getEngine().renderer.plugins.interaction;
        interactionManager.setTargetElement(overlayRef.current);
        // NOTE: Here we set mouseOverRenderer to true since PIXI.js only generates mouseover/out events on graphics objects when this property is set to true
        interactionManager.mouseOverRenderer = true;
    };
    const swapInWebglCanvas = () => {
        if (!useWebglCanvas) {
            setUseWebglCanvas(true);
            canvasRef.current.remove();
            const webglCanvas = getEngine().renderer.view;
            rootRef.current.appendChild(webglCanvas);
            setInteractionOverlay();
        }
    };
    const swapOutWebglCanvas = () => {
        if (useWebglCanvas) {
            setUseWebglCanvas(false);
            const webglCanvas = getEngine().renderer.view;
            webglCanvas.remove();
            rootRef.current.appendChild(canvasRef.current);
        }
    };
    const renderChart = () => {
        if (width && height) {
            const engine = getEngine();
            const { x, y } = engine.renderer.plugins.interaction.mouse.global;
            render(data, x, y, isLogScaled ? 1 : 0);
            engine.stage.removeChildren();
            // NOTE: We need to set the transform to a non-null value here, because the transform gets reset to null sometimes due to unknown reason
            stage.transform = new PIXI.Transform();
            engine.stage.addChild(stage);
            engine.render();
        }
    };
    const copyChart = () => {
        if (width && height && canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            ctx.drawImage(getEngine().renderer.view, 0, 0);
        }
    };
    useEffect(() => {
        // TODO: Figure out why this only works in window.requestAnimationFrame
        window.requestAnimationFrame(() => {
            stage.destroy(true);
            setStage(getDefaultStage());
            renderChart();
            copyChart();
        });
    }, [ranges]);
    useEffect(() => {
        window.requestAnimationFrame(() => {
            renderChart();
            copyChart();
        });
    }, [width, height, data]);
    return { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart };
}
