import { createMuiTheme, fade } from '@material-ui/core/styles';
import createSpacing from '@material-ui/core/styles/createSpacing';
const createFactoryTheme = (options) => createMuiTheme({
    spacing: 5,
    typography: {
        fontFamily: 'Titillium Web',
    },
    factory: {
        spacing: createSpacing(5),
        palette: {
            white: {
                200: '#F5F2FF',
            },
            purple: {
                50: '#C799F7',
                100: '#A391C3',
                200: '#9482CF',
                300: '#41325A',
                400: '#2C2349',
                500: '#211437',
                600: '#180E29',
                700: '#100718',
            },
            green: {
                100: '#52FACE',
                200: '#3CEDE0',
                300: '#31969C',
            },
            red: {
                100: '#FF7078',
            },
            blue: {
                100: '#858DFF',
            },
            yellow: {
                100: '#FFF17D',
            },
            grey: {
                100: fade('#A391C3', 0.17),
            },
        },
        shape: {
            borderRadius: {
                smallest: 1,
                smaller: 2,
                small: 3,
                large: 6,
                larger: 8,
                largest: 10,
            },
        },
        typography: {
            fontFamily: {
                main: 'Titillium Web',
                fixedWidth: {
                    variationA: 'Ubuntu Mono',
                    variationB: 'OCRAStd',
                },
            },
            htmlFontSize: 12,
            fontSize: {
                smallest: 12,
                smaller: 14,
                small: 16,
                large: 20,
                larger: 24,
                largest: 32,
            },
        },
    },
    props: {
        MuiButtonBase: {
            // No more ripple, on the whole application
            disableRipple: true,
        },
    },
    overrides: {
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'overriden',
                    borderRadius: 'overriden',
                },
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'overriden',
                },
            },
            contained: {
                '&:hover': {
                    backgroundColor: 'overriden',
                },
                '& > $disabled': {
                    backgroundColor: 'overriden',
                },
            },
        },
    },
    ...options,
});
export default createFactoryTheme;
