import { getCookie } from './utils';
export const isUserAuthenticated = typeof getCookie('GithubToken') !== 'undefined';
export const convertParamsToCamelCase = (params) => {
    const camelCaseParams = {};
    Object.keys(params).forEach(param => {
        camelCaseParams[param.replace(/([a-z0-9_])([A-Z])/g, '$1-$2').toLowerCase()] = params[param];
    });
    return camelCaseParams;
};
export const getPageTree = (selectedValues, keys) => {
    const page = {};
    let subPage = page;
    while (keys.length) {
        const key = keys.shift();
        subPage[key] = { selected: selectedValues.shift() };
        subPage = subPage[key];
    }
    return page;
};
