import React from 'react';
import { Link } from 'react-router-dom';
import SummaryAutomation from '../../common/ui/components/SummaryAutomation';
import Title from '../../common/ui/lib/BaseTitle';
import WithConditionalWrapper from '../../common/ui/components/helpers/WithConditionalWrapper';
const SummaryJob = ({ title, summary, url, onRerun, onSshCopy }) => {
    return (React.createElement(SummaryAutomation, { title: React.createElement(WithConditionalWrapper, { condition: Boolean(url), wrapper: children => React.createElement(Link, { to: url }, children) },
            React.createElement(Title, { type: "h2", isClickable: true }, title)), onRerun: onRerun, onSshCopy: onSshCopy, summary: summary }));
};
export default SummaryJob;
