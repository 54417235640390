import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import Input from '../BaseInput';
import yup from '../../../validator';
import ButtonClose from '../../components/ButtonClose';
import GuideInput from '../../components/GuideInput';
import { compareArrays } from '../../../utils';
import { useStyles } from './styles';
const getRangeTextSchema = ({ min, max }) => yup.object({
    min: yup
        .number()
        .label('Min')
        .required()
        .moreThan(min - 1)
        .lessThan(max + 1),
    max: yup
        .number()
        .label('Max')
        .moreThan(yup.ref('min'))
        .lessThan(max + 1),
});
const DEFAULT_MIN = 1;
const DEFAULT_MAX = 12345;
const defaultProps = {
    options: {
        min: DEFAULT_MIN,
        max: DEFAULT_MAX,
    },
};
const BaseSlider = props => {
    const { options, selected, disabled, classes, onRangeChange, onRangeReset } = props;
    const ownClasses = useStyles();
    const [selectedRange, setSelectedRange] = useState([options.min, options.max]);
    const [selectedRangeText, setSelectedRangeText] = useState('-');
    const [rangeTextHint, setRangeTextHint] = useState('');
    const [rangeTextError, setRangeTextError] = useState('');
    const handleRangeChange = (_event, value) => {
        setSelectedRange(value);
    };
    const handleRangeChangeCommit = (_event, value) => {
        if (selected && compareArrays(value, selected))
            return;
        onRangeChange(value);
    };
    const handleRangeTextChange = (event) => {
        setSelectedRangeText(event.target.value);
    };
    const getRangeText = () => {
        const [min, max] = selectedRange;
        if (min === DEFAULT_MIN && max === DEFAULT_MAX)
            return '-';
        else if (min === max)
            return String(min);
        return `${min}:${max}`;
    };
    const getRangeTextValidationError = async (range) => {
        const [min, max] = range.split(':');
        let error = '';
        await getRangeTextSchema(options)
            .validate({ min, max })
            .catch(e => (error = e.message));
        return error;
    };
    const submitRangeText = async (inputEl, range) => {
        const error = await getRangeTextValidationError(range);
        if (error === '') {
            setRangeTextError('');
            inputEl.blur();
            const [min, max] = range.split(':').map(bound => (bound ? Number(bound) : undefined));
            let updatedRange = [0, 0];
            if (min && max)
                updatedRange = [min, max];
            else if (min)
                updatedRange = [min, min];
            setSelectedRange(updatedRange);
            onRangeChange(updatedRange);
        }
        else {
            setRangeTextError(error);
        }
        setRangeTextHint('');
    };
    const handleRangeTextKeyUp = (event) => {
        setRangeTextHint('Hit enter to apply');
        setRangeTextError('');
        if (event.key === 'Enter') {
            submitRangeText(event.currentTarget, selectedRangeText);
        }
    };
    const handleRangeTextBlur = () => {
        setRangeTextHint('');
        setRangeTextError('');
        setSelectedRangeText(getRangeText());
    };
    useEffect(() => {
        !selected && setSelectedRange([options.min, options.max]);
    }, [options]);
    useEffect(() => {
        selected && setSelectedRange(selected);
    }, [selected]);
    useEffect(() => {
        setSelectedRangeText(getRangeText());
    }, [selectedRange]);
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root) },
        React.createElement(ButtonClose, { onClose: onRangeReset }),
        React.createElement("div", { className: clsx(ownClasses.sliderContainer) },
            React.createElement(Slider, Object.assign({ classes: {
                    root: clsx(ownClasses.slider, classes?.slider),
                    thumb: ownClasses.sliderThumb,
                    active: ownClasses.sliderActive,
                    rail: ownClasses.sliderRail,
                } }, options, { value: selectedRange, onChange: handleRangeChange, onChangeCommitted: handleRangeChangeCommit }))),
        React.createElement(Input, { classes: { root: ownClasses.rangeTextInput }, type: "text", disabled: disabled, value: selectedRangeText, onChange: handleRangeTextChange, onKeyUp: handleRangeTextKeyUp, onBlur: handleRangeTextBlur }),
        React.createElement(GuideInput, { error: rangeTextError, hint: rangeTextHint })));
};
BaseSlider.defaultProps = defaultProps;
export default BaseSlider;
