import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            backgroundColor: `${factoryTheme.palette.purple[400]} !important`,
            borderRadius: `${factoryTheme.shape.borderRadius.small}px !important`,
            color: factoryTheme.palette.white[200],
            fontSize: `${factoryTheme.typography.fontSize.smallest}px !important`,
            padding: `${factoryTheme.spacing(1, 2)}px !important`,
            '&:after': {
                borderTopColor: `${factoryTheme.palette.purple[400]} !important`,
            },
        },
    });
});
