import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
import { workflowStyles } from '../../common/styles';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        ...workflowStyles(factoryTheme),
        performanceAnalysisTitle: {
            margin: factoryTheme.spacing(4, 0),
        },
        performanceAnalysisButtons: {
            display: 'grid',
            gridGap: factoryTheme.spacing(4),
            gridAutoRows: 'auto',
            gridTemplateColumns: 'repeat(4, 1fr)',
            '& > a': {
                height: 'fit-content',
                padding: `${factoryTheme.spacing(2)}px 0`,
                '& > span': {
                    lineHeight: '17px',
                },
            },
        },
    });
});
