import * as Yup from 'yup';
Yup.setLocale({
    mixed: {
        required: '${path} is required.',
        notType: ({ path, type }) => `${path} must be a ${type}.`,
    },
    string: {
        matches: '${path} is invalid.',
    },
    number: {
        moreThan: '${path} must be greater than ${more}.',
        lessThan: '${path} must be less than ${less}.',
    },
});
export default Yup;
