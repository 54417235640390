import * as PIXI from 'pixi.js';
import { roundOneDecimal } from '../../common/utils';
import { CHART_CONFIG } from './config';

export const clearChart = chart => {
    while (chart.stage.children.length > 0) {
        const child = chart.stage.getChildAt(0);
        chart.stage.removeChild(child);
    }
};

// The `delete` command is only used to remove a reference from an object, it doesn't actually delete anything in the sense that the operator of the same name in C/++ does.
// To clean up WebGL memory you will have to loop through each of your textures and call the `destroy` method on each of them. Make sure to pass `true` as the param to ensure
// the actual underlying data is cleared from memory. As for any objects you created the best thing to do is remove them all from their respective parents
// (so they lose their references to eachother) and then lose any references to objects you can access.
// After you lose all your references, the garbage collector can do it's job and clean up for you.
// https://www.html5gamedevs.com/topic/1189-pixi-how-to-destroy-cleanup/
export const removeChildIfExists = (container, name) => {
    for (let i = 0; i < container.children.length; i++) {
        const child = container.getChildAt(i);
        if (child.name === name) {
            if (child.clear) child.clear();
            child.removeChildren();
            container.removeChild(child);
            child.destroy(true);
        }
    }
};

export const createChildIfNotExist = (container, name, creationFunc, zIndex = 0) => {
    let child = container.getChildByName(name);
    if (!child) {
        child = creationFunc();
        child.name = name;
        child.zIndex = zIndex;
        container.addChild(child);
    }
    return child;
};

export const getTextSize = (text, style) => {
    const textStyle = new PIXI.TextStyle(style);
    const textMetrics = PIXI.TextMetrics.measureText(text, textStyle);
    return [textMetrics.width, textMetrics.height];
};

// https://codepen.io/unrealnl/pen/aYaxBW
export const drawDashedPolygon = (polygons, line, dash = 5, gap = 10) => {
    let i;
    let p1;
    let p2;
    let dashLeft = 0;
    let gapLeft = 0;

    const rotatedPolygons = [];
    for (i = 0; i < polygons.length; i++) {
        const p = { x: polygons[i].x, y: polygons[i].y };
        const cosAngle = Math.cos(0);
        const sinAngle = Math.sin(0);
        const dx = p.x;
        const dy = p.y;
        p.x = dx * cosAngle - dy * sinAngle;
        p.y = dx * sinAngle + dy * cosAngle;
        rotatedPolygons.push(p);
    }
    for (i = 0; i < rotatedPolygons.length; i++) {
        p1 = rotatedPolygons[i];
        if (i === rotatedPolygons.length - 1) p2 = rotatedPolygons[0];
        else p2 = rotatedPolygons[i + 1];
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        const len = Math.sqrt(dx * dx + dy * dy);
        const normal = { x: dx / len, y: dy / len };
        let progressOnLine = 0;
        line.moveTo(p1.x + gapLeft * normal.x, p1.y + gapLeft * normal.y);
        while (progressOnLine <= len) {
            progressOnLine += gapLeft;
            if (dashLeft > 0) progressOnLine += dashLeft;
            else progressOnLine += dash;
            if (progressOnLine > len) {
                dashLeft = progressOnLine - len;
                progressOnLine = len;
            } else {
                dashLeft = 0;
            }
            line.lineTo(p1.x + progressOnLine * normal.x, p1.y + progressOnLine * normal.y);
            progressOnLine += gap;
            if (progressOnLine > len && dashLeft === 0) {
                gapLeft = progressOnLine - len;
            } else {
                gapLeft = 0;
                line.moveTo(p1.x + progressOnLine * normal.x, p1.y + progressOnLine * normal.y);
            }
        }
    }
};

export const getLabels = (minValue, maxValue, ticksPreferred, intervalsPreferred) => {
    let y;
    let step;
    let min = Number.MAX_VALUE;
    const intervalLength = maxValue - minValue;
    intervalsPreferred.forEach(x => {
        y = Math.abs(ticksPreferred - intervalLength / x);
        if (y < min) {
            min = y;
            step = x;
        }
    });
    const start = Math.floor(minValue / step) * step;
    const end = Math.ceil(maxValue / step) * step;
    return [...Array(Math.round((end - start) / step) + 1).keys()].map(part => roundOneDecimal(step * part + start));
};

export const drawCurvedLine = (vertices, line, offsetX = 0, offsetY = 0) => {
    for (let i = 0; i < vertices.length - 1; i++) {
        let [x1, y1] = vertices[i];
        let [x2, y2] = vertices[i + 1];
        x1 += offsetX;
        x2 += offsetX;
        y1 += offsetY;
        y2 += offsetY;
        const xMid = (x1 + x2) / 2;
        const yMid = (y1 + y2) / 2;
        const ControlPointX1 = (xMid + x1) / 2;
        const ControlPointX2 = (xMid + x2) / 2;
        line.quadraticCurveTo(ControlPointX1, y1, xMid, yMid);
        line.quadraticCurveTo(ControlPointX2, y2, x2, y2);
    }
};

export const createHoverOverlayText = () => {
    const text = new PIXI.Text('', CHART_CONFIG.CHART.HOVER_OVERLAY.TEXT.STYLE);
    text.alpha = CHART_CONFIG.CHART.HOVER_OVERLAY.TEXT.ALPHA;
    return text;
};

export const createCurvedLine = (vertices, style) => {
    if (vertices.length > 0) {
        const line = new PIXI.Graphics();
        line.lineStyle(style);
        line.moveTo(...vertices[0]);
        drawCurvedLine(vertices, line);
        return line;
    }
};

export const createPoints = (vertices, radius, color, alpha) => {
    const points = new PIXI.Graphics();
    vertices.forEach(vertex => {
        points.alpha = alpha;
        points.beginFill(color);
        points.drawCircle(...vertex, radius);
        points.endFill();
    });
    return points;
};

export const createBackground = (width, height) => {
    const background = new PIXI.Graphics();
    background.beginFill(CHART_CONFIG.COORDINATE_SYSTEM.BG_COLOR);
    background.drawRect(
        CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT,
        CHART_CONFIG.CHART.PADDING.VERTICAL.TOP,
        width - (CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT),
        height - (CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM)
    );
    background.endFill();
    return background;
};

const createTick = (x, y, angle) => {
    const tick = new PIXI.Graphics();
    tick.x = x;
    tick.y = y;
    tick.angle = angle;
    tick.lineStyle(CHART_CONFIG.COORDINATE_SYSTEM.TICK.STYLE);
    tick.moveTo(-CHART_CONFIG.COORDINATE_SYSTEM.TICK.SIZE, 0);
    tick.lineTo(CHART_CONFIG.COORDINATE_SYSTEM.TICK.SIZE, 0);
    return tick;
};

export const createHorizontalAxis = (width, height, xLabels, unit) => {
    const createXAxisLine = () => {
        const axis = new PIXI.Graphics();
        axis.lineStyle(CHART_CONFIG.COORDINATE_SYSTEM.AXIS.STYLE);
        axis.moveTo(CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM);
        axis.lineTo(
            width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT,
            height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM
        );
        return axis;
    };

    const createUnitTextX = () => {
        const text = new PIXI.Text(unit, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
        const [textWidth] = getTextSize(unit, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
        text.x = width / 2 - textWidth / 2;
        text.y =
            height -
            CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM +
            CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.VERTICAL_PADDING;
        text.alpha = CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ALPHA;
        return text;
    };

    const createVerticalTick = (x, y) => createTick(x, y, 270);

    const createXAxisText = (x, y, label) => {
        const [textWidth] = getTextSize(label, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
        const text = new PIXI.Text(label, CHART_CONFIG.COORDINATE_SYSTEM.TEXT_X.STYLE);
        [text.x, text.y] = [x - textWidth / 2, y + CHART_CONFIG.COORDINATE_SYSTEM.TEXT_X.VERTICAL_PADDING];
        return text;
    };

    const createVerticalGrid = (x, y) => {
        const gridVertical = new PIXI.Graphics();
        gridVertical.lineStyle(CHART_CONFIG.CHART.GRID_STYLE);
        gridVertical.moveTo(x, y);
        drawDashedPolygon(
            [
                { x: x, y: height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM },
                { x: x, y: CHART_CONFIG.CHART.PADDING.VERTICAL.TOP },
            ],
            gridVertical
        );
        return gridVertical;
    };

    const axis = new PIXI.Container();
    axis.addChild(createXAxisLine());
    axis.addChild(createUnitTextX());
    xLabels.forEach(label => {
        const x = label.pos;
        const y = height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM;
        if (x > width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT || x < CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT)
            return;
        axis.addChild(createVerticalTick(x, y));
        axis.addChild(createXAxisText(x, y, label.label));
        axis.addChild(createVerticalGrid(x, y));
    });
    return axis;
};

export const createVerticalAxis = (width, height, yLabels, unit) => {
    const createYAxisLine = () => {
        const axis = new PIXI.Graphics();
        axis.lineStyle(CHART_CONFIG.COORDINATE_SYSTEM.AXIS.STYLE);
        axis.moveTo(CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM);
        axis.lineTo(CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, CHART_CONFIG.CHART.PADDING.VERTICAL.TOP);
        return axis;
    };

    const createUnitTextY = () => {
        const text = new PIXI.Text(unit, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
        const [textWidth, textHeight] = getTextSize(unit, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
        text.x = width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT + 5;
        text.y = height / 2 - textHeight / 2 + textWidth / 2;
        text.angle = -90;
        text.alpha = CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ALPHA;
        return text;
    };

    const createHorizontalTick = (x, y) => createTick(x, y, 0);

    const createYAxisText = (x, y, label) => {
        const text = new PIXI.Text(label, CHART_CONFIG.COORDINATE_SYSTEM.TEXT_Y.STYLE);
        const [textWidth, textHeight] = getTextSize(label, CHART_CONFIG.COORDINATE_SYSTEM.TEXT_Y.STYLE);
        [text.x, text.y] = [
            x - CHART_CONFIG.COORDINATE_SYSTEM.TEXT_Y.HORIZONTAL_PADDING - textWidth,
            y - textHeight / 2,
        ];
        return text;
    };

    const createHorizontalGrid = (x, y) => {
        const gridHorizontal = new PIXI.Graphics();
        gridHorizontal.lineStyle(CHART_CONFIG.CHART.GRID_STYLE);
        gridHorizontal.moveTo(x, y);
        drawDashedPolygon(
            [
                { x: CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, y: y },
                { x: width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, y: y },
            ],
            gridHorizontal
        );
        return gridHorizontal;
    };

    const axis = new PIXI.Container();
    axis.addChild(createYAxisLine());
    axis.addChild(createUnitTextY());
    yLabels.forEach(label => {
        const x = CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT;
        const y = label.pos;
        if (y > height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM || y < CHART_CONFIG.CHART.PADDING.VERTICAL.TOP)
            return;
        axis.addChild(createHorizontalTick(x, y));
        axis.addChild(createYAxisText(x, y, label.label));
        axis.addChild(createHorizontalGrid(x, y));
    });
    return axis;
};

export const createCoordinateSystem = (width, height, xLabels, yLabels, xUnit, yUnit) => {
    const coordinateSystem = new PIXI.Container();
    coordinateSystem.addChild(createHorizontalAxis(width, height, xLabels, xUnit));
    coordinateSystem.addChild(createVerticalAxis(width, height, yLabels, yUnit));
    return coordinateSystem;
};

export const createSimplePoint = ([x, y], onMouseDown, onMouseOver, onMouseOut) => {
    const point = new PIXI.Graphics();
    point.beginFill(CHART_CONFIG.CHART.LINE.POINT.OUTLINE_COLOR);
    point.drawCircle(x, y, CHART_CONFIG.CHART.LINE.POINT.RADIUS + 1);
    point.endFill();
    point.alpha = CHART_CONFIG.CHART.LINE.POINT.ALPHA;
    point.beginFill(CHART_CONFIG.CHART.LINE.POINT.COLOR);
    point.drawCircle(x, y, CHART_CONFIG.CHART.LINE.POINT.RADIUS);
    point.endFill();
    point.interactive = true;
    point.buttonMode = true;
    point.hitArea = new PIXI.Circle(x, y, CHART_CONFIG.CHART.LINE.INTERACTIVE_RADIUS);
    point.mousedown = onMouseDown;
    point.mouseover = onMouseOver;
    point.mouseout = onMouseOut;
    return point;
};

export const getMap = ([fromStart, fromEnd], [toStart, toEnd]) => {
    const scale = (toEnd - toStart) / (fromEnd - fromStart);
    const map = fromStart !== fromEnd ? x => (x - fromStart) * scale + toStart : () => toStart;
    const inverseScale = (fromEnd - fromStart) / (toEnd - toStart);
    const inverseMap = toStart !== toEnd ? x => (x - toStart) * inverseScale + fromStart : () => fromStart;
    return [map, inverseMap];
};
