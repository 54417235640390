import React from 'react';
import clsx from 'clsx';
import Label from '../../../../common/ui/lib/BaseLabel';
import Select from '../../../../common/ui/components/Select';
import Button from '../../../../common/ui/lib/BaseButton';
import { useStyles } from './styles';
const SelectRun = ({ selected, options, showCompareButton, onClose, onCompare, onRunChange, }) => {
    const ownClasses = useStyles();
    const handleRunChange = (newRun) => onRunChange({ selected: newRun, options });
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { onClose: onClose, classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText } }, "Performance Analysis"),
        React.createElement(Select, { selected: selected, options: options, classes: { root: ownClasses.item, input: ownClasses.selectInput, option: ownClasses.selectOption }, onChange: handleRunChange, idToNameConvertor: id => String(id).padStart(19, '0') }),
        showCompareButton && React.createElement(Button, { onClick: onCompare }, "Compare")));
};
export default SelectRun;
