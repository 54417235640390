import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../../../common/ui/styles/helpers';
const item = (spacing) => ({
    marginRight: spacing(3),
});
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            marginBottom: factoryTheme.spacing(4),
        },
        select: {
            ...item(factoryTheme.spacing),
        },
        label: {
            ...item(factoryTheme.spacing),
        },
        meta: {
            padding: factoryTheme.spacing(2),
            display: 'block',
            backgroundColor: factoryTheme.palette.purple[700],
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
            color: factoryTheme.palette.purple[200],
            margin: `-${factoryTheme.spacing(2)}px 0 ${factoryTheme.spacing(2)}px 0`,
            lineHeight: `${factoryTheme.spacing(4)}px`,
        },
    });
});
