import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import clsx from 'clsx';
import Input from '../../../../../common/ui/lib/BaseInput';
import Yup from '../../../../../common/validator';
import Button from '../../../../../common/ui/lib/BaseButton';
import Checkbox from '../../../../../common/ui/lib/BaseCheckbox';
import { useStyles } from './styles';
const schema = Yup.object({
    pattern: Yup.string().label('Pattern').required(),
    'build-correctness-wait-for-quality': Yup.boolean(),
    'build-performance-wait-for-quality': Yup.boolean(),
    'build-performance-wait-for-correctness': Yup.boolean(),
});
const SettingCheckbox = ({ control, settingKey, onChange, value }) => {
    return (React.createElement(Controller, { name: settingKey, control: control, render: props => (React.createElement(Checkbox, { onChange: e => onChange(props.onChange, settingKey, e.target.checked), checked: value })) }));
};
const BranchRuleForm = ({ onSubmit, rule }) => {
    const ownClasses = useStyles();
    const [ruleSettings, setRuleSettings] = useState(rule);
    const { handleSubmit: submitHandler, register, errors, getValues, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: rule,
    });
    const handleSubmit = () => {
        const updatedValues = getValues();
        onSubmit(updatedValues);
    };
    const handleSettingsChange = (onChange, settingKey, value) => {
        onChange(value);
        const ruleSettingsUpdated = { ...ruleSettings, [settingKey]: value };
        setRuleSettings(ruleSettingsUpdated);
    };
    return (React.createElement("form", { autoComplete: "off", onSubmit: submitHandler(handleSubmit) },
        React.createElement("div", { className: ownClasses.field },
            React.createElement(Input, { classes: { root: ownClasses.patternInput }, defaultValue: rule.pattern, name: "pattern", ref: register, type: "text" }),
            React.createElement("p", { className: ownClasses.error }, errors.pattern?.message)),
        React.createElement("table", { className: ownClasses.table },
            React.createElement("tbody", null,
                React.createElement("tr", { className: ownClasses.tr },
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdLabel) },
                        React.createElement("p", null, "Correctness workflow should wait for Quality workflow to succeed")),
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdInput) },
                        React.createElement(SettingCheckbox, { value: ruleSettings['build-correctness-wait-for-quality'], settingKey: "build-correctness-wait-for-quality", onChange: handleSettingsChange, control: control }))),
                React.createElement("tr", { className: ownClasses.tr },
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdLabel) },
                        React.createElement("p", null, "Performance workflow should wait for Quality workflow to succeed")),
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdInput) },
                        React.createElement(SettingCheckbox, { value: ruleSettings['build-performance-wait-for-quality'], settingKey: "build-performance-wait-for-quality", onChange: handleSettingsChange, control: control }))),
                React.createElement("tr", { className: ownClasses.tr },
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdLabel) },
                        React.createElement("p", null, "Performance workflow should wait for Correctness workflow to succeed")),
                    React.createElement("td", { className: clsx(ownClasses.td, ownClasses.tdInput) },
                        React.createElement(SettingCheckbox, { value: ruleSettings['build-performance-wait-for-correctness'], settingKey: "build-performance-wait-for-correctness", onChange: handleSettingsChange, control: control }))))),
        React.createElement("div", { className: ownClasses.footer },
            React.createElement(Button, { classes: { root: ownClasses.saveBtn }, htmlAttrs: { type: 'submit' } }, "Save"))));
};
export default BranchRuleForm;
