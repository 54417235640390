import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { useStyles } from './styles';
const BaseAlert = ({ children, classes, type, action }) => {
    const ownClasses = useStyles({ type, action });
    return (React.createElement(MuiAlert, { classes: {
            root: clsx(ownClasses.root, classes?.root),
            icon: ownClasses.icon,
            message: clsx(ownClasses.message, classes?.message),
            action: ownClasses.action,
        }, variant: "filled", severity: type, action: action }, children));
};
export default BaseAlert;
