import React, { useState, useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import ButtonClose from '../../components/ButtonClose';
import { useStyles } from './styles';
const defaultProps = {
    width: 'auto',
};
export const displayName = 'BaseLabel';
const BaseLabel = forwardRef((props, ref) => {
    const { children, showEmptyCloseBtn, width, classes, onClose, onClick } = props;
    const ownClasses = useStyles({ width });
    const [closable, setClosable] = useState(false);
    useEffect(() => {
        setClosable(showEmptyCloseBtn || Boolean(onClose));
    }, [showEmptyCloseBtn, onClose]);
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root), ref: ref },
        closable && React.createElement(ButtonClose, { isEmpty: showEmptyCloseBtn, onClose: onClose }),
        React.createElement("div", { className: clsx(ownClasses.label, { [ownClasses.closableLabel]: closable, [ownClasses.clickableLabel]: onClick }, classes?.label), onClick: onClick },
            React.createElement("p", { className: clsx(ownClasses.labelText, classes?.text) }, children))));
});
BaseLabel.defaultProps = defaultProps;
BaseLabel.displayName = displayName;
export default BaseLabel;
