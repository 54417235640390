// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/OCRAStd.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:\"OCRAStd\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:normal}html,body{height:100%;background-color:#180e29;color:#f5f2ff;font-size:14px;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:\"Titillium Web\";margin:0}html *,body *{box-sizing:border-box}html.with-hidden-scrollbar::-webkit-scrollbar,body.with-hidden-scrollbar::-webkit-scrollbar{width:0;background:transparent}#root{height:100%}", ""]);
// Exports
module.exports = exports;
