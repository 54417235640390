import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import LabelGroup from '../../lib/BaseLabelGroup';
import Label from '../../lib/BaseLabel';
import { deletePropertyPath, cloneJSON } from '../../../utils';
import ButtonClose from '../ButtonClose';
import Select from '../Select';
import { useStyles } from './styles';
export const isTreeNode = (tree, treeRef) => {
    return treeRef in tree;
};
const SelectTree = ({ tree, treeRef, selectProps, classes, onClose, onChange }) => {
    const ownClasses = useStyles();
    const constructTreeData = () => {
        if (!isTreeNode(tree, treeRef)) {
            const selectData = tree;
            return { items: [], selectData };
        }
        let subTree = tree;
        const items = [];
        let selectData = null;
        let deletePath = treeRef;
        while (isTreeNode(subTree, treeRef)) {
            const parentTree = deletePropertyPath(tree, deletePath);
            deletePath += `.${treeRef}`;
            items.push({ text: subTree.selected, parentTree });
            subTree = subTree[treeRef];
        }
        selectData = subTree;
        return { items, selectData: selectData };
    };
    const [treeData, setTreeData] = useState(constructTreeData());
    const handleOptionChange = (newOption) => {
        const updatedTree = cloneJSON(tree);
        let subTree = updatedTree;
        while (isTreeNode(subTree, treeRef)) {
            subTree = subTree[treeRef];
        }
        subTree.selected = newOption;
        onChange(updatedTree);
    };
    const handleTreeItemClick = (parentTree) => {
        if (selectProps.optional) {
            let subTree = parentTree;
            let selectData = null;
            while (isTreeNode(subTree, treeRef)) {
                subTree = subTree[treeRef];
            }
            selectData = subTree;
            selectData.optional = true;
            delete selectData.selected;
        }
        onChange(parentTree);
    };
    useEffect(() => {
        setTreeData(constructTreeData());
    }, [tree]);
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root) },
        React.createElement(ButtonClose, { onClose: onClose }),
        React.createElement(LabelGroup, null, treeData.items.map((item, index) => (React.createElement(Label, { key: index, classes: { label: ownClasses.treeItemLabel }, onClick: () => handleTreeItemClick(item.parentTree) }, item.text)))),
        React.createElement(Select, Object.assign({}, treeData.selectData, selectProps, { onChange: handleOptionChange, classes: { input: ownClasses.selectInput } }))));
};
export default SelectTree;
