import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        layoutMain: {
            paddingTop: factoryTheme.spacing(4),
        },
        box: {
            marginBottom: factoryTheme.spacing(6),
        },
        accessTokenField: {
            display: 'flex',
            alignItems: 'center',
        },
        discordIdField: {
            display: 'flex',
            alignItems: 'center',
        },
        discordWebhookUrlField: {
            display: 'flex',
            alignItems: 'center',
        },
        error: {
            color: factoryTheme.palette.red[100],
            marginTop: factoryTheme.spacing(2),
            '&:empty': {
                display: 'none',
            },
        },
        tokenInput: {
            flexGrow: 1,
            marginRight: factoryTheme.spacing(3),
        },
        clearCacheButton: {
            marginTop: factoryTheme.spacing(2),
        },
    });
});
