import React from 'react';
import clsx from 'clsx';
import PerformanceGroups from '../PerformanceGroups';
import PerformanceOverview from '../PerformanceOverview';
import PerformanceTree from '../PerformanceTree';
import { useStyles } from './styles';
const Layouts = {
    PerformanceOverview: (props) => React.createElement(PerformanceOverview, Object.assign({}, props)),
    PerformanceGroups: (props) => (React.createElement(PerformanceGroups, Object.assign({}, props), "Performance Groups ")),
    PerformanceTree: (props) => React.createElement(PerformanceTree, Object.assign({}, props)),
};
const PerformanceComparison = ({ data, analysisId, ranges, onPathChange, onTrackerChange, onIdChange, }) => {
    const ownClasses = useStyles();
    const comparisonTypeComponentName = () => {
        const performanceAnalysis = Object.values(data.compare)[0];
        if (performanceAnalysis['trace-overviews'])
            return 'PerformanceOverview';
        else if (performanceAnalysis['trace-groups'])
            return 'PerformanceGroups';
        else if (performanceAnalysis['trace-tree'])
            return 'PerformanceTree';
        throw new Error('[Performance Page Error]: unknown page type');
    };
    const currentData = () => {
        return data.compare[analysisId];
    };
    const compareData = () => {
        return Object.entries(data.compare)
            .filter(([key]) => key !== analysisId)
            .map(x => x[1])[0];
    };
    const getPathsList = () => {
        let dataKey = null;
        if (comparisonTypeComponentName() === 'PerformanceOverview') {
            dataKey = 'trace-overviews';
        }
        else if (comparisonTypeComponentName() === 'PerformanceGroups') {
            dataKey = 'trace-groups';
        }
        if (dataKey) {
            const currentPaths = Object.keys(currentData()[dataKey]);
            const comparePaths = Object.keys(compareData()[dataKey]);
            const commonPaths = currentPaths.filter(path => comparePaths.includes(path)).sort();
            return [
                commonPaths.concat(currentPaths.filter(path => !commonPaths.includes(path))),
                commonPaths.concat(comparePaths.filter(path => !commonPaths.includes(path))),
            ];
        }
        return [[], []];
    };
    const currentPathList = () => {
        return getPathsList()[0];
    };
    const comparePathList = () => {
        return getPathsList()[1];
    };
    if (!currentData() || !compareData())
        return React.createElement(React.Fragment, null);
    const layout1 = Layouts[`${comparisonTypeComponentName()}`]({
        data: currentData(),
        ranges: ranges,
        pathList: currentPathList(),
        onPathChange: (updatePathTree) => onPathChange(updatePathTree),
        onTrackerChange: (tracker) => onTrackerChange(tracker),
        onIdChange: (id) => onIdChange(id),
    });
    const layout2 = Layouts[`${comparisonTypeComponentName()}`]({
        data: compareData(),
        ranges: ranges,
        pathList: comparePathList(),
        onPathChange: (updatePathTree) => onPathChange(updatePathTree),
        onTrackerChange: (tracker) => onTrackerChange(tracker),
        onIdChange: (id) => onIdChange(id),
    });
    return (React.createElement(React.Fragment, null, Object.values(data.compare).length === 2 && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ownClasses.comparisonParts },
            React.createElement("div", { className: clsx(ownClasses.comparisonPart, ownClasses.comparisonPartOne) }, layout1),
            React.createElement("div", { className: clsx(ownClasses.comparisonPart, ownClasses.comparisonPartTwo) },
                layout2,
                " "))))));
};
PerformanceComparison.displayName = 'PerformanceComparison';
export default PerformanceComparison;
