import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            margin: factoryTheme.spacing(3, 0),
        },
        downloadButton: {
            position: 'fixed',
            top: factoryTheme.spacing(3),
            right: factoryTheme.spacing(3),
        },
        spinner: {
            whiteSpace: 'pre-wrap',
            color: factoryTheme.palette.purple[100],
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationB,
            fontSize: factoryTheme.typography.fontSize.smaller,
            margin: 0,
            top: '0%',
            transform: 'translateY(50%)',
            width: '100%',
            textAlign: 'center',
        },
        emptyMsg: {
            textAlign: 'center',
        },
        code: {
            whiteSpace: 'pre',
            color: factoryTheme.palette.purple[100],
            marginLeft: factoryTheme.spacing(1),
            lineHeight: '25px',
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationB,
            fontSize: factoryTheme.typography.fontSize.smallest,
            top: 0,
            position: 'absolute',
        },
        lineNumbers: {
            width: factoryTheme.spacing(13),
            padding: `0 ${factoryTheme.spacing(1)}px`,
            textAlign: 'right',
            whiteSpace: 'pre',
            left: 0,
            top: 0,
            lineHeight: '25px',
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationB,
            fontSize: factoryTheme.typography.fontSize.smallest,
            color: factoryTheme.palette.purple[200],
            userSelect: 'none',
            zIndex: 1,
            borderRight: `1px solid ${factoryTheme.palette.purple[300]}`,
            backgroundColor: factoryTheme.palette.purple[600],
            position: 'absolute',
        },
    });
});
