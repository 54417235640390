import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import usePage from '../../common/services/page/use';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import Label from '../../common/ui/lib/BaseLabel';
import { useRequester } from '../../common/services/dataChannel/use';
import EnvVariables from '../../common/components/EnvVariable';
import RepoBranchRules from './branchRules/index';
import Button from "../../common/ui/lib/BaseButton";
import Box from "../../common/ui/lib/BaseBox";
import Title from "../../common/ui/lib/BaseTitle";
const PageRepositorySettings = () => {
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('repository');
    const repository = payload;
    const requester = useRequester();
    const history = useHistory();
    const routeParams = useParams();
    const handleVariableAdd = (variable) => {
        requester({
            route: `${routeParams.owner}/${routeParams.repository}/settings/add-variable`,
            body: {
                repository: { 'environment-variables': variable },
            },
        });
    };
    const handleVariableRemove = (variable) => {
        requester({
            route: `${routeParams.owner}/${routeParams.repository}/settings/remove-variable`,
            body: {
                repository: { 'environment-variables': variable },
            },
        });
    };
    const handleBranchRuleAdd = (pattern) => {
        requester({
            route: `${routeParams.owner}/${routeParams.repository}/settings/add-rule`,
            body: { repository: { 'branch-rule': pattern } },
        });
        history.push(`settings/branch-rule/${pattern}`);
    };
    const handleBranchRuleRemove = (pattern) => {
        requester({
            route: `${routeParams.owner}/${routeParams.repository}/settings/remove-rule`,
            body: { repository: { 'branch-rule': pattern } },
        });
    };
    const handleDataRemove = () => {
        const confirmed = confirm(`Are you sure you want to remove the data of ${routeParams.owner}/${routeParams.repository}? This operation cannot be undone.`);
        if (confirmed) {
            requester({
                route: `${routeParams.owner}/${routeParams.repository}/settings/remove-data`,
                body: {},
            });
            history.push(`/${routeParams.owner}`);
        }
    };
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            React.createElement(Label, { onClose: navHandlers.handleRepoSettingsClose }, "settings")) },
        repository?.['branch-rules'] && (React.createElement(RepoBranchRules, { patterns: repository['branch-rules'], emptyMsg: React.createElement(React.Fragment, null,
                "There are no branch rules for",
                ' ',
                React.createElement("strong", null, `${routeParams.owner}/${routeParams.repository}`),
                "."), onAdd: handleBranchRuleAdd, onRemove: handleBranchRuleRemove })),
        repository?.['environment-variables'] && (React.createElement(EnvVariables, { variables: repository?.['environment-variables'], emptyMsg: React.createElement(React.Fragment, null,
                "There are no environment variables for",
                ' ',
                React.createElement("strong", null, `${routeParams.owner}/${routeParams.repository}`),
                "."), owner: `${routeParams.owner}/${routeParams.repository}`, onAdd: handleVariableAdd, onRemove: handleVariableRemove })),
        repository && (React.createElement(Box, { header: React.createElement("div", null,
                React.createElement(Title, { type: "h1" }, "Danger Zone")) },
            React.createElement(React.Fragment, null,
                "Delete all data of this repository",
                React.createElement("div", { style: { float: 'right' } },
                    React.createElement(Button, { type: "danger", onClick: handleDataRemove }, "Delete repository")))))));
};
export default PageRepositorySettings;
