import React from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import SelectAutomation from '../../common/ui/components/SelectAutomation';
import usePage from '../../common/services/page/use';
import WorkflowCorrectness from '../types/Correctness';
import WorkflowQuality from '../types/Quality';
import WorkflowPerformance from '../types/Performance';
import WorkflowValidation from '../types/Validation';
import WorkflowDeployment from '../types/Deployment';
import WorkflowBroadcast from '../types/Broadcast';
import SelectCommit from '../../common/ui/components/SelectCommit';
import ButtonFork from '../../common/ui/components/ButtonFork';
const PageWorkflow = () => {
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('workflow');
    const workflow = payload;
    const workflowSummaryWhitelist = ['run', 'status', 'started', 'ended', 'menu'];
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "There are no jobs recorded for this workflow!", navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                page.owner.repository.parent && React.createElement(ButtonFork, { parentUrl: page.owner.repository.parent }),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            page.owner?.repository?.commit?.automation && (React.createElement(SelectAutomation, { automationTree: page.owner.repository.commit.automation.symbol, onAutomationChange: navHandlers.handleAutomationChange, onAutomationClose: navHandlers.handleAutomationClose }))) },
        workflow?.name === 'correctness' && (React.createElement(WorkflowCorrectness, { correctness: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist })),
        workflow?.name === 'quality' && (React.createElement(WorkflowQuality, { quality: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist })),
        workflow?.name === 'performance' && (React.createElement(WorkflowPerformance, { performance: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist })),
        workflow?.name === 'validation' && (React.createElement(WorkflowValidation, { validation: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist })),
        workflow?.name === 'deployment' && (React.createElement(WorkflowDeployment, { deployment: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist })),
        workflow?.name === 'broadcast' && (React.createElement(WorkflowBroadcast, { broadcast: { [workflow.run]: workflow }, summaryWhitelist: workflowSummaryWhitelist }))));
};
export default PageWorkflow;
