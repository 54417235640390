import Color from 'color';
export const pipelineStyles = ({ factoryTheme, }) => ({
    boxHeader: {
        borderBottom: `1px solid ${Color(factoryTheme.palette.purple[200]).alpha(0.7)}`,
        paddingBottom: factoryTheme.spacing(6),
    },
    boxContent: {
        padding: 0,
    },
    workflowBox: {
        border: 'none',
    },
    workflowDivider: {
        margin: `${factoryTheme.spacing(0, 6)} !important`,
        '&:last-child': {
            display: 'none',
        },
    },
});
