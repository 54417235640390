import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import WithConditionalWrapper from '../../components/helpers/WithConditionalWrapper';
import { useStyles } from './styles';
const BaseBox = ({ url, header, children, classes, onClick }) => {
    const ownClasses = useStyles();
    const hasChildren = children &&
        React.Children.toArray(children).length > 0 &&
        React.Children.toArray(children).every(child => child);
    return (React.createElement("div", { className: clsx(ownClasses.root, { [ownClasses.clickable]: url }, classes?.root), onClick: onClick },
        React.createElement(WithConditionalWrapper, { condition: Boolean(url), wrapper: children => React.createElement(Link, { to: url }, children) },
            React.createElement(React.Fragment, null,
                header && (React.createElement("div", { className: clsx(ownClasses.header, { [ownClasses.hasEitherContentOrHeader]: !hasChildren }, classes?.header) }, header)),
                hasChildren && (React.createElement("div", { className: clsx(ownClasses.content, { [ownClasses.hasEitherContentOrHeader]: !header }, classes?.content) }, children))))));
};
export default BaseBox;
