import React from 'react';
import Label from '../../../../common/ui/lib/BaseLabel';
import Select from '../../../../common/ui/components/Select';
import { cloneJSON } from '../../../../common/utils';
import { getProcessedCommitSelectData } from '../../../../common/helpers';
import { useStyles } from './styles';
const SelectCompare = ({ data, compareChange }) => {
    const ownClasses = useStyles();
    const handleCompareRemove = () => {
        compareChange(undefined);
    };
    const shouldShowRepositorySelect = () => data?.repository?.options || data?.repository?.selected;
    const shouldShowCommitSelect = () => data?.repository?.commit?.options || data?.repository?.commit?.selected;
    const shouldShowAnalysisSelect = () => data?.repository?.commit?.analysis?.id?.options || data?.repository?.commit?.analysis?.id?.selected;
    const handleRepositoryChange = (repository) => {
        const updatedCompare = cloneJSON(data);
        updatedCompare.repository.selected = repository;
        updatedCompare.repository.commit = {};
        compareChange(updatedCompare);
    };
    const handleCommitChange = (commit) => {
        const updatedCompare = cloneJSON(data);
        updatedCompare.repository.commit.selected = commit;
        updatedCompare.repository.commit.analysis = { id: {} };
        compareChange(updatedCompare);
    };
    const handleIdChange = (id) => {
        const updatedCompare = cloneJSON(data);
        updatedCompare.repository.commit.analysis.id.selected = id;
        compareChange(updatedCompare);
    };
    const commitSelectData = shouldShowCommitSelect() ? getProcessedCommitSelectData(data.repository.commit) : null;
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { classes: { root: ownClasses.item }, onClose: handleCompareRemove }, "Compare"),
        shouldShowRepositorySelect() && (React.createElement(Select, { selected: data.repository.selected, options: data.repository.options, placeholder: "Please select repository", onChange: (repository) => handleRepositoryChange(repository.code), classes: { root: ownClasses.item } })),
        shouldShowCommitSelect() && (React.createElement(Select, { selected: commitSelectData.selected, options: commitSelectData.options, placeholder: "Please select commit", onChange: (commit) => handleCommitChange(commit), classes: { root: ownClasses.item } })),
        shouldShowAnalysisSelect() && (React.createElement(Select, { selected: data.repository.commit.analysis.id.selected, options: data.repository.commit.analysis.id.options, placeholder: "Please select analysis", onChange: (id) => handleIdChange(id), classes: { root: ownClasses.item } }))));
};
export default SelectCompare;
