import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Input from '../../../ui/lib/BaseInput';
import ButtonGroup from '../../../ui/lib/BaseButtonGroup';
import Button from '../../../ui/lib/BaseButton';
import { useStyles } from './styles';
import { getDiscordIdSchema } from './schema';
export const DiscordUserId = ({ username, id, showUsername = true, onSubmit }) => {
    const ownClasses = useStyles();
    const { handleSubmit, getValues, errors, register, setValue } = useForm({
        resolver: yupResolver(getDiscordIdSchema()),
    });
    const handleDiscordIdSubmit = () => {
        const updatedValues = getValues();
        onSubmit(username, updatedValues.discordIdentifier);
    };
    return (React.createElement("div", null,
        React.createElement("form", { autoComplete: "off", onSubmit: handleSubmit(handleDiscordIdSubmit) },
            React.createElement("div", { className: ownClasses.discordIdField },
                showUsername && (React.createElement(Input, { type: "text", readOnly: true, disabled: true, defaultValue: username, classes: { root: ownClasses.inputUser } })),
                showUsername && React.createElement("span", null, ":"),
                React.createElement(Input, { name: "discordIdentifier", type: "text", defaultValue: id, ref: register(), classes: { root: ownClasses.tokenInput } }),
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { onClick: () => {
                            setValue('discordIdentifier', '');
                            onSubmit(username, '');
                        } },
                        React.createElement(Icon, { icon: ['fal', 'times'] })),
                    React.createElement(Button, { htmlAttrs: { type: 'submit' } }, "Save")))),
        React.createElement("p", { className: ownClasses.error }, errors.discordIdentifier?.message)));
};
