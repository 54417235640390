import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        fields: {
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            width: '100%',
        },
        addBtn: {
            width: 74,
            marginLeft: factoryTheme.spacing(4),
        },
        error: {
            color: factoryTheme.palette.red[100],
            marginTop: factoryTheme.spacing(2),
            '&:empty': {
                display: 'none',
            },
        },
    });
});
