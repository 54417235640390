import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            marginBottom: factoryTheme.spacing(4),
            '&:hover $commitSha': {
                color: factoryTheme.palette.green[200],
            },
        },
        boxInvalid: {
            '& $commitSha': {
                color: `${factoryTheme.palette.red[100]} !important`,
            },
            '&:hover': {
                border: `1px solid ${factoryTheme.palette.red[100]}`,
            },
        },
        summaryTitle: {
            marginBottom: factoryTheme.spacing(2),
        },
        commitSha: {},
    });
});
export const usePipelineSummaryStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        statusTile: {
            marginRight: factoryTheme.spacing(1),
        },
    });
});
