import { makeStyles } from '@material-ui/core';
import { createFactoryStyles, statusColor } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: 15,
            height: 15,
            borderRadius: factoryTheme.shape.borderRadius.smallest,
        },
        success: {
            backgroundColor: statusColor('success', factoryTheme),
        },
        'up-to-date': {
            backgroundColor: statusColor('success', factoryTheme),
        },
        running: {
            backgroundColor: statusColor('running', factoryTheme),
        },
        invalid: {
            backgroundColor: statusColor('error', factoryTheme),
        },
        unknown: {
            backgroundColor: statusColor('error', factoryTheme),
        },
        error: {
            backgroundColor: statusColor('error', factoryTheme),
        },
        waiting: {
            backgroundColor: statusColor('waiting', factoryTheme),
        },
        'out-of-date': {
            backgroundColor: statusColor('waiting', factoryTheme),
        },
        none: {
            backgroundColor: statusColor('none', factoryTheme),
        },
    });
});
