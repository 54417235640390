import React from 'react';
import * as PIXI from 'pixi.js';
import { getShortNotation, roundOneDecimal, safeLog10 } from '../../../common/utils';
import { CHART_CONFIG } from '../config';
import { createBackground, createVerticalAxis, createHoverOverlayText, drawDashedPolygon, getLabels, getTextSize, removeChildIfExists, getMap, createSimplePoint, } from '../utils';
import { useChart, useChartRenderer } from '../mixins';
import { useStyles } from './style';
const SingleIterationTrendChart = ({ width, height, data, ranges, isLogScaled, onIdChange, }) => {
    const ownClasses = useStyles();
    const { rootRef, overlayRef, canvasRef, stage, setStage } = useChart();
    const calculateDataMap = (data, ranges) => {
        let durationRange;
        if (ranges && ranges.durationRange) {
            ({ durationRange } = ranges);
        }
        else {
            durationRange = [0, Math.max(...data.data.map((x) => x.duration))];
        }
        const topPadding = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const bottomPadding = CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM + CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const [mapY, mapYInverse] = getMap(durationRange, [height - bottomPadding, topPadding]);
        const [mapYLog, mapYLogInverse] = getMap(durationRange.map(safeLog10), [height - bottomPadding, topPadding]);
        return {
            durationRange,
            mapY,
            mapYLog,
            mapYInverse,
            mapYLogInverse,
        };
    };
    const render = (data, mouseX, mouseY, logScaleAnimationProgress) => {
        const { durationRange, mapY, mapYLog, mapYInverse, mapYLogInverse } = calculateDataMap(data, ranges);
        const updateBackground = () => {
            if (!stage.getChildByName(CHART_CONFIG.LAYER.BACKGROUND.NAME)) {
                const background = createBackground(width, height);
                background.name = CHART_CONFIG.LAYER.BACKGROUND.NAME;
                background.zIndex = CHART_CONFIG.LAYER.BACKGROUND.Z_INDEX;
                stage.addChild(background);
            }
        };
        const updateGraph = (data, logScaleAnimationProgress) => {
            const createGraph = (data, logScaleAnimationProgress) => {
                const localMapY = (y) => (1 - logScaleAnimationProgress) * mapY(y) + logScaleAnimationProgress * mapYLog(safeLog10(y));
                const pixelData = data.data.map((point) => ({
                    id: point.id,
                    duration: localMapY(point.duration),
                }));
                const graph = new PIXI.Container();
                pixelData.forEach((pointData) => {
                    const point = createSimplePoint([width / 2, pointData.duration], () => {
                        onIdChange(pointData.id);
                    });
                    graph.addChild(point);
                });
                return graph;
            };
            const lastGraph = stage.getChildByName(CHART_CONFIG.LAYER.GRAPH.NAME);
            if (!lastGraph ||
                lastGraph.chart.data !== data ||
                lastGraph.chart.logScaleAnimationProgress !== logScaleAnimationProgress) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.GRAPH.NAME);
                const graph = createGraph(data, logScaleAnimationProgress);
                graph.name = CHART_CONFIG.LAYER.GRAPH.NAME;
                graph.zIndex = CHART_CONFIG.LAYER.GRAPH.Z_INDEX;
                // @ts-ignore
                graph.chart = { data, logScaleAnimationProgress };
                stage.addChild(graph);
            }
        };
        const updateCoordinateSystem = (data, logScaleAnimationProgress) => {
            const createChartCoordinateSystem = () => {
                const { iteration } = data;
                const iterationLabel = new PIXI.Text(`Iteration ${iteration}`, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
                const [textWidth] = getTextSize(`Iteration ${iteration}`, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
                iterationLabel.x = width / 2 - textWidth / 2;
                iterationLabel.y =
                    height -
                        CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM +
                        CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.SINGLE_ITERATION_VERTICAL_PADDING;
                iterationLabel.alpha = CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ALPHA;
                const durationLabelsLinear = getLabels(...durationRange, 8, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: getShortNotation(label), pos: mapY(label) }));
                const durationLabelsLog = getLabels(...durationRange.map(safeLog10), CHART_CONFIG.COORDINATE_SYSTEM.TICK.COUNT.VERTICAL, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS_WITH_FRACTIONS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: label.toString(), pos: mapYLog(label) }));
                const axisLinear = createVerticalAxis(width, height, durationLabelsLinear, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                axisLinear.name = 'linear';
                axisLinear.alpha = 0;
                const axisLog = createVerticalAxis(width, height, durationLabelsLog, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                axisLog.name = 'log';
                axisLog.alpha = 0;
                const coordinateSystem = new PIXI.Container();
                coordinateSystem.addChild(axisLinear);
                coordinateSystem.addChild(axisLog);
                coordinateSystem.addChild(iterationLabel);
                return coordinateSystem;
            };
            const lastCoordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (!lastCoordinateSystem || lastCoordinateSystem.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
                const coordinateSystem = createChartCoordinateSystem();
                coordinateSystem.name = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME;
                coordinateSystem.zIndex = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.Z_INDEX;
                // @ts-ignore
                coordinateSystem.chart = { data, logScaleAnimationProgress };
                stage.addChild(coordinateSystem);
            }
            const coordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (logScaleAnimationProgress === 1) {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
            else if (logScaleAnimationProgress === 0) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else if (isLogScaled) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
        };
        const updateHoverOverlay = (data, mouseX, mouseY) => {
            const createHoverOverlay = () => {
                const hoverOverlay = new PIXI.Container();
                const lineHorizontal = new PIXI.Graphics();
                lineHorizontal.name = 'lineHorizontal';
                // @ts-ignore
                lineHorizontal.lineStyle(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE);
                drawDashedPolygon([
                    { x: width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, y: 0 },
                    { x: CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, y: 0 },
                ], lineHorizontal, 1, 2);
                hoverOverlay.addChild(lineHorizontal);
                const labelY = createHoverOverlayText();
                labelY.name = 'labelY';
                labelY.x =
                    CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                hoverOverlay.addChild(labelY);
                return hoverOverlay;
            };
            const lastHoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (!lastHoverOverlay || lastHoverOverlay.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
                const hoverOverlay = createHoverOverlay();
                hoverOverlay.name = CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME;
                hoverOverlay.zIndex = CHART_CONFIG.LAYER.HOVER_OVERLAY.Z_INDEX;
                // @ts-ignore
                hoverOverlay.chart = { data, mouseX, mouseY };
                stage.addChild(hoverOverlay);
            }
            const hoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (mouseX >= CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT &&
                mouseY >= CHART_CONFIG.CHART.PADDING.VERTICAL.TOP &&
                mouseX <= width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT &&
                mouseY <= height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM) {
                const mapBackY = isLogScaled ? mapYLogInverse : mapYInverse;
                hoverOverlay.alpha = 1;
                hoverOverlay.getChildByName('lineHorizontal').y = mouseY;
                hoverOverlay.getChildByName('labelY').y = mouseY;
                hoverOverlay.getChildByName('labelY').text = roundOneDecimal(mapBackY(mouseY)).toString();
            }
            else {
                hoverOverlay.alpha = 0;
            }
        };
        updateBackground();
        updateGraph(data, logScaleAnimationProgress);
        updateCoordinateSystem(data, logScaleAnimationProgress);
        updateHoverOverlay(data, mouseX, mouseY);
    };
    const { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart } = useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, ranges, isLogScaled, render);
    const handleMouseEnter = () => {
        swapInWebglCanvas();
        renderChart();
    };
    const handleMouseOut = () => {
        swapOutWebglCanvas();
        renderChart();
        copyChart();
    };
    const handleMouseMove = () => {
        renderChart();
    };
    return (React.createElement("div", { ref: rootRef, className: ownClasses.root },
        React.createElement("div", { ref: overlayRef, className: ownClasses.overlay, onMouseEnter: handleMouseEnter, onMouseOut: handleMouseOut, onMouseMove: handleMouseMove }),
        React.createElement("canvas", { ref: canvasRef, width: width * devicePixelRatio, height: height * devicePixelRatio })));
};
export default SingleIterationTrendChart;
