import React from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import SummaryWorkflow from '../../summary';
import TableJobs from '../../../common/ui/components/TableJobs';
import { constructUrlForPerformanceAnalysisPage, constructUrlForWorkflowPage, formatDateTime, } from '../../../common/helpers';
import Title from '../../../common/ui/lib/BaseTitle';
import Button from '../../../common/ui/lib/BaseButton';
import { WorkflowName } from '../../common/types';
import useSummary from '../../../common/services/summary/use';
import { useStyles } from './styles';
const WorkflowPerformance = ({ performance, summaryWhitelist, classes }) => {
    const ownClasses = useStyles();
    const { selectedRun, summary, handleRunChange, handleWorkflowRerun } = useSummary(performance, summaryWhitelist);
    return (React.createElement(Box, { classes: { root: classes?.root, header: ownClasses.boxHeader, content: ownClasses.boxContent }, header: React.createElement(SummaryWorkflow, { title: `Workflow: ${WorkflowName.Performance}`, summary: summary, url: constructUrlForWorkflowPage(performance[selectedRun].symbol), onRunChange: handleRunChange, onRerun: () => handleWorkflowRerun(WorkflowName.Performance) }) },
        React.createElement(TableJobs, { jobs: performance[selectedRun].jobs }),
        performance[selectedRun].analysis?.['performance-analysis'] && (React.createElement(React.Fragment, null,
            React.createElement(Title, { classes: { root: ownClasses.performanceAnalysisTitle }, type: "h3", hasLine: true }, "Performance Analyses"),
            React.createElement("div", { className: ownClasses.performanceAnalysisButtons }, Object.values(performance[selectedRun].analysis?.['performance-analysis'] || {}).map(analysis => (React.createElement(Button, { key: analysis.id, href: constructUrlForPerformanceAnalysisPage(performance[selectedRun].symbol, analysis.id) },
                "ID: ",
                analysis.id,
                React.createElement("br", null),
                "Name: ",
                analysis.name,
                React.createElement("br", null),
                "Created: ",
                formatDateTime(analysis.created)))))))));
};
export default WorkflowPerformance;
