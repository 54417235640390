import React from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import SummaryWorkflow from '../../summary';
import TableJobs from '../../../common/ui/components/TableJobs';
import { WorkflowName } from '../../common/types';
import useSummary from '../../../common/services/summary/use';
import { constructUrlForWorkflowPage } from '../../../common/helpers';
import { useStyles } from './styles';
const WorkflowBroadcast = ({ broadcast, summaryWhitelist, classes }) => {
    const ownClasses = useStyles();
    const { selectedRun, summary, handleRunChange, handleWorkflowRerun } = useSummary(broadcast, summaryWhitelist);
    return (React.createElement(Box, { classes: { root: classes?.root, header: ownClasses.boxHeader, content: ownClasses.boxContent }, header: React.createElement(SummaryWorkflow, { title: `Workflow: ${WorkflowName.Broadcast}`, summary: summary, url: constructUrlForWorkflowPage(broadcast[selectedRun].symbol), onRunChange: handleRunChange, onRerun: () => handleWorkflowRerun(WorkflowName.Broadcast) }) },
        React.createElement(TableJobs, { jobs: broadcast[selectedRun].jobs })));
};
export default WorkflowBroadcast;
