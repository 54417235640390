import { Link } from 'react-router-dom';
import React from 'react';
import SummaryAutomation from '../../common/ui/components/SummaryAutomation';
import Title from '../../common/ui/lib/BaseTitle';
import WithConditionalWrapper from '../../common/ui/components/helpers/WithConditionalWrapper';
const SummaryPipeline = ({ name, summary, url, onRunChange, onRerun }) => {
    return (React.createElement(SummaryAutomation, { title: React.createElement(WithConditionalWrapper, { condition: Boolean(url), wrapper: children => React.createElement(Link, { to: url }, children) },
            React.createElement(Title, { type: "h1", isClickable: true },
                "Pipeline: ",
                name)), summary: summary, onRunChange: onRunChange, onRerun: onRerun }));
};
export default SummaryPipeline;
