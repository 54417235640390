import { makeStyles } from '@material-ui/core';
import { createFactoryStyles, statusColor } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        automationVerificationHeader: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        labelInvalid: {
            backgroundColor: `${statusColor('error', factoryTheme)} !important`,
            color: `${factoryTheme.palette.purple[500]} !important`,
            fontWeight: 'bold',
        },
        codeTitle: {
            marginBottom: factoryTheme.spacing(5),
        },
        sourceCode: {
            marginBottom: factoryTheme.spacing(5),
        },
        errorCodeLine: {
            color: factoryTheme.palette.red[100],
        },
        errorCodeLineNumber: {
            color: factoryTheme.palette.red[100],
        },
    });
});
