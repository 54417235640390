import React, { useEffect, useState } from 'react';
import MultiIterationLineChart from '../MultiIterationLineChart';
import SingleIterationLineChart from '../SingleIterationLineChart';
const LineChart = ({ width, height, data, ranges, isLogScaled, onIdChange, onTrackerChange, }) => {
    const getIterations = () => {
        return Array.from(new Set(data.map((x) => x.data).flatMap((x) => Object.keys(x).map(x => Number(x)))));
    };
    const getOnlyOneIteration = () => {
        const iterations = getIterations();
        return iterations.length === 1;
    };
    const [onlyOneIteration, setOnlyOneIteration] = useState(getOnlyOneIteration());
    const [singleIterationData, setSingleIterationData] = useState(null);
    const getSingleIterationData = () => {
        const iteration = getIterations()[0];
        const dataaa = { iteration, data: [] };
        data.forEach((line) => {
            const points = Object.values(line.data).flatMap((joint) => joint.flatMap((point) => point));
            dataaa.data = dataaa.data.concat(points);
        });
        return dataaa;
    };
    useEffect(() => {
        setOnlyOneIteration(getOnlyOneIteration());
        setSingleIterationData(getSingleIterationData());
    }, [data]);
    let chart = null;
    if (onlyOneIteration && singleIterationData)
        chart = (React.createElement(SingleIterationLineChart, { data: singleIterationData, width: width, height: height, isLogScaled: isLogScaled, ranges: ranges, onIdChange: id => onIdChange(id) }));
    if (!onlyOneIteration && data)
        chart = (React.createElement(MultiIterationLineChart, { data: data, width: width, height: height, isLogScaled: isLogScaled, ranges: ranges, onIdChange: id => onIdChange(id), onTrackerChange: id => onTrackerChange(id) }));
    return React.createElement(React.Fragment, null, chart);
};
export default LineChart;
