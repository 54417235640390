import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { cloneJSON, deepFind } from '../../../utils';
import Select from '../Select';
import { constructUrlForJobPage, constructRerunMessage, extractDataFromSymbol, constructRouteForJobRerun, } from '../../../helpers';
import WithConditionalWrapper from '../helpers/WithConditionalWrapper';
import Menu from '../../lib/BaseMenu';
import MenuItem from '../../lib/BaseMenu/item';
import { useRequester } from '../../../services/dataChannel/use';
import Title from '../../lib/BaseTitle';
import Time from '../BaseTime';
import useNotification from '../../../services/notification/use';
import { useStyles } from './styles';
var COLUMN_TYPES;
(function (COLUMN_TYPES) {
    COLUMN_TYPES["PRIMARY"] = "primary";
    COLUMN_TYPES["STATUS"] = "status";
    COLUMN_TYPES["REGULAR"] = "regular";
    COLUMN_TYPES["RUN"] = "run";
    COLUMN_TYPES["TIME"] = "time";
    COLUMN_TYPES["MENU"] = "menu";
})(COLUMN_TYPES || (COLUMN_TYPES = {}));
const defaultProps = {
    columns: {
        0: { title: `Job Name`, dataRef: `name`, type: COLUMN_TYPES.PRIMARY },
        1: { title: `Run`, dataRef: `run`, type: COLUMN_TYPES.RUN },
        2: { title: 'Duration', dataRef: { start: `started`, end: `ended` }, type: COLUMN_TYPES.TIME },
        3: { title: 'Status', dataRef: `status`, type: COLUMN_TYPES.STATUS },
        4: { title: '', type: COLUMN_TYPES.MENU, menuItems: { rerun: true } },
    },
};
const TableJobs = props => {
    const { jobs, columns } = props;
    const ownClasses = useStyles();
    const notification = useNotification();
    const request = useRequester();
    const [jobsWithSelectedRun, setJobsWithSelectedRun] = useState({});
    const [rows, setRows] = useState([]);
    const allowsRunSelection = () => {
        const menuColumn = Object.values(columns).find(column => column.type === COLUMN_TYPES.MENU);
        return menuColumn?.menuItems?.rerun;
    };
    const handlesOwnClick = (columnType) => {
        return [COLUMN_TYPES.RUN, COLUMN_TYPES.MENU].includes(columnType);
    };
    const reconstructJobsWithLastRunAsSelected = () => {
        const processedJobs = cloneJSON(jobs);
        if (Object.values(processedJobs).length) {
            const firstJob = Object.values(processedJobs)[0];
            const isSelectedAlreadySet = Object.values(firstJob).some(run => run.selected);
            if (allowsRunSelection() && !isSelectedAlreadySet) {
                Object.values(processedJobs).forEach(runs => {
                    const runNumbers = Object.keys(runs);
                    const lastRunNumber = runNumbers[runNumbers.length - 1];
                    const lastRunData = runs[lastRunNumber];
                    lastRunData.selected = true;
                });
            }
        }
        return processedJobs;
    };
    const findSelectedRun = (jobRuns) => {
        if (allowsRunSelection())
            return Object.values(jobRuns).find(run => run.selected === true);
        return jobRuns;
    };
    const constructCell = (columnType, dataRef, jobName, runOptions, selectedRun) => {
        const cell = {};
        switch (columnType) {
            case COLUMN_TYPES.PRIMARY:
            case COLUMN_TYPES.STATUS:
            case COLUMN_TYPES.REGULAR: {
                if (!deepFind(selectedRun, dataRef))
                    throw Error(`[TableJobs]: Provided dataRef '${dataRef}' does not exist in the job run data.`);
                cell.text = deepFind(selectedRun, dataRef);
                break;
            }
            case COLUMN_TYPES.RUN:
                cell.Select = {
                    selected: selectedRun[dataRef],
                    options: runOptions,
                };
                cell.name = jobName;
                break;
            case COLUMN_TYPES.TIME:
                cell.started = selectedRun[dataRef.start];
                cell.ended = selectedRun[dataRef.end];
                break;
            case COLUMN_TYPES.MENU:
                break;
            default:
                throw new Error(`[TableJobs]: '${columnType}' is not a valid column type`);
        }
        return cell;
    };
    const handleRunSelect = (newRun, dataRef) => {
        const jobs = cloneJSON(jobsWithSelectedRun);
        Object.values(jobs[dataRef]).forEach(runData => (runData.selected = false));
        jobs[dataRef][newRun].selected = true;
        setJobsWithSelectedRun(jobs);
    };
    const handleRerun = (jobSymbol) => {
        const jobName = extractDataFromSymbol(jobSymbol).job;
        notification.show(constructRerunMessage({ name: jobName, type: 'Job' }));
        request({ route: constructRouteForJobRerun(jobSymbol) });
    };
    const constructRows = () => {
        const rows = [];
        const sortedJobs = Object.entries(jobsWithSelectedRun).sort((a, b) => a[1][Object.keys(a[1])[0]].index - b[1][Object.keys(b[1])[0]].index);
        sortedJobs.forEach(([jobName, jobRuns]) => {
            const selectedRunData = findSelectedRun(jobRuns);
            const cells = [];
            Object.values(columns).forEach(column => {
                const runOptions = Object.keys(jobRuns);
                const cell = constructCell(column.type, column.dataRef, jobName, runOptions, selectedRunData);
                cells.push(cell);
            });
            rows.push({ symbol: selectedRunData.symbol, cells });
        });
        return rows;
    };
    useEffect(() => {
        setRows(constructRows());
    }, [jobsWithSelectedRun]);
    useEffect(() => {
        setJobsWithSelectedRun(reconstructJobsWithLastRunAsSelected());
    }, [jobs]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { classes: { root: ownClasses.title }, type: "h3", hasLine: true }, "Jobs"),
        React.createElement("table", { className: ownClasses.table },
            React.createElement("thead", null,
                React.createElement("tr", { className: clsx(ownClasses.tr, ownClasses.trHead) }, Object.values(columns).map(column => (React.createElement("th", { className: clsx(ownClasses.th, ownClasses[column.type]), key: column.title }, column.title))))),
            React.createElement("tbody", null, rows.map((row, index) => (React.createElement("tr", { key: index, className: clsx(ownClasses.tr, ownClasses.trBody) }, Object.values(columns).map((column, cIndex) => (React.createElement("td", { key: column.type, className: clsx(ownClasses.td, ownClasses[column.type]) },
                React.createElement(WithConditionalWrapper, { condition: Boolean(row.symbol) && !handlesOwnClick(column.type), wrapper: children => (React.createElement(Link, { to: constructUrlForJobPage(row.symbol) }, children)) },
                    React.createElement(React.Fragment, null,
                        (column.type === COLUMN_TYPES.PRIMARY ||
                            column.type === COLUMN_TYPES.REGULAR) && (React.createElement("p", null, row.cells[cIndex].text)),
                        column.type === COLUMN_TYPES.TIME && (React.createElement("p", null,
                            React.createElement(Time, { started: row.cells[cIndex].started, ended: row.cells[cIndex].ended }))),
                        column.type === COLUMN_TYPES.RUN && (React.createElement(Select, Object.assign({}, row.cells[cIndex].Select, { classes: {
                                input: ownClasses.selectInput,
                                menuPaper: ownClasses.selectMenu,
                                openButton: ownClasses.selectOpenButton,
                            }, minWidth: 56, onChange: jobOption => handleRunSelect(jobOption, row.cells[cIndex].name) }))),
                        column.type === COLUMN_TYPES.STATUS && (React.createElement("div", { className: clsx(ownClasses.status, ownClasses[row.cells[cIndex].text]) },
                            React.createElement("p", null, row.cells[cIndex].text))),
                        column.type === COLUMN_TYPES.MENU && (React.createElement(Menu, { toggler: React.createElement("div", { className: ownClasses.rowMenu },
                                React.createElement(Icon, { icon: ['fas', 'caret-down'] })) },
                            React.createElement(MenuItem, { onClick: () => handleRerun(row.symbol) },
                                React.createElement("p", { className: ownClasses.menuItemText }, "Rerun"))))))))))))))));
};
TableJobs.defaultProps = defaultProps;
export default TableJobs;
