import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            marginLeft: factoryTheme.spacing(4),
            alignItems: 'center',
        },
        title: {
            marginRight: factoryTheme.spacing(1),
        },
    });
});
