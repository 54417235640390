import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import usePage from '../common/services/page/use';
import LayoutPage from '../common/ui/layouts/Page';
import Navigation from '../common/ui/components/Navigation';
import Select from '../common/ui/components/Select';
import ButtonGithub from '../common/ui/components/ButtonGithub';
import LoadingIndicator from '../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../common/ui/components/Navigation/linebreak';
import SelectCommit from '../common/ui/components/SelectCommit';
import { cloneJSON, setValueOfNestedProp, minmax } from '../common/utils';
import Label from '../common/ui/lib/BaseLabel';
import useNotification from '../common/services/notification/use';
import ScaleNormalizeSwitcher from './common/ui/ScaleNormalizeSwitcher';
import InputTraceId from './common/ui/InputTraceId';
import SliderIteration from './common/ui/SliderTraceIteration';
import SelectTraceLabel from './common/ui/SelectTraceLabel';
import SelectTraceTracker from './common/ui/SelectTraceTracker';
import { removeOptions } from './common/helpers';
import SelectTracePath from './common/ui/SelectTracePath';
import SelectRun from './common/ui/SelectRun';
import SelectCompare from './common/ui/SelectCompare';
import PerformanceGroups from './layouts/PerformanceGroups';
import PerformanceOverview from './layouts/PerformanceOverview';
import PerformanceTree from './layouts/PerformanceTree';
import PerformanceComparison from './layouts/PerformanceComparison';
const Layouts = {
    PerformanceOverview: (props) => React.createElement(PerformanceOverview, Object.assign({}, props)),
    PerformanceGroups: (props) => (React.createElement(PerformanceGroups, Object.assign({}, props), "Performance Groups ")),
    PerformanceComparison: (props) => React.createElement(PerformanceComparison, Object.assign({}, props)),
    PerformanceTree: (props) => React.createElement(PerformanceTree, Object.assign({}, props)),
};
const PagePeformanceAnalaysis = ({ location, history }) => {
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, navHandlers, isNavLoading, page } = usePage('performance-analysis');
    const notification = useNotification();
    const analysisNav = page.owner?.repository?.commit?.analysis?.['performance-analysis'];
    const [ranges, setRanges] = useState({ durationRange: {} });
    const [comparisonMode, setComparisonMode] = useState(false);
    const updatePerformanceAnalysisQueryParam = (analysis) => {
        const query = JSON.stringify(removeOptions({ analysis }, ['iteration']));
        history.push(`${location.pathname}?q=${query}`);
    };
    const handleQueryChange = (queryChunk, performanceAnalysisRef) => {
        const pageCloned = cloneJSON(page);
        const analysis = pageCloned?.owner?.repository?.commit?.analysis?.['performance-analysis'];
        analysis && setValueOfNestedProp(analysis, performanceAnalysisRef, queryChunk);
        updatePerformanceAnalysisQueryParam(analysis);
    };
    const getIterationRange = (obj) => {
        if (!obj)
            return null;
        let min = Number.POSITIVE_INFINITY;
        let max = Number.NEGATIVE_INFINITY;
        if (Object.keys(obj).length === 0 || typeof obj !== 'object')
            return null;
        Object.keys(obj).forEach(key => {
            if (key === 'durations') {
                const minMax = minmax(Object.keys(obj.durations));
                max = Math.max(max, minMax[1]);
                min = Math.min(min, minMax[0]);
            }
            else if (key === 'iteration') {
                max = Math.max(max, obj.iteration);
                min = Math.min(min, obj.iteration);
            }
            else {
                const minMax = getIterationRange(obj[key]);
                if (minMax) {
                    max = Math.max(max, minMax[1]);
                    min = Math.min(min, minMax[0]);
                }
            }
        });
        if (min === Number.POSITIVE_INFINITY || max === Number.NEGATIVE_INFINITY)
            return null;
        return [min, max];
    };
    const getObjMax = (obj) => {
        let max = Number.NEGATIVE_INFINITY;
        Object.values(obj)
            .map((arr) => minmax(arr))
            .forEach(minMaxArray => {
            max = Math.max(max, minMaxArray[1]);
        });
        if (max === Number.NEGATIVE_INFINITY)
            return null;
        return max;
    };
    const getDurationMax = (obj) => {
        if (!obj)
            return null;
        let max = Number.NEGATIVE_INFINITY;
        if (Object.keys(obj).length === 0 || typeof obj !== 'object')
            return null;
        Object.keys(obj).forEach(key => {
            if (key === 'durations') {
                max = Math.max(max, getObjMax(obj.durations) || 0);
            }
            else if (key === 'duration') {
                max = Math.max(max, obj.duration);
            }
            else {
                max = Math.max(max, getDurationMax(obj[key]) || 0);
            }
        });
        if (max === Number.NEGATIVE_INFINITY)
            return null;
        return max;
    };
    const getType = (a) => {
        if (a['trace-overviews'])
            return 'trace-overviews';
        else if (a['trace-groups'])
            return 'trace-groups';
        else if (a['trace-tree'])
            return 'trace-tree';
        else if (a.compare)
            return 'compare';
        throw new Error('[Performance Page Error]: unknown analysis type');
    };
    const calculateRange = (analysis, normalisation) => {
        const getAnalysisPaths = (analysis) => {
            const analysisTypeKey = getType(analysis);
            if (analysisTypeKey === 'trace-overviews' || analysisTypeKey === 'trace-groups') {
                return Object.keys(analysis?.[analysisTypeKey]);
            }
            else if (analysisTypeKey === 'compare') {
                return [...new Set(Object.values(analysis.compare).flatMap((a) => Object.keys(a[getType(a)])))];
            }
            return [];
        };
        if (analysis) {
            const range = { iterationRange: getIterationRange(analysis), durationRange: {} };
            if (normalisation === 'all') {
                const analysisTypeKey = getType(analysis);
                const durationRange = [0, getDurationMax(analysis[analysisTypeKey])];
                const paths = getAnalysisPaths(analysis);
                paths.forEach((path) => {
                    range.durationRange[path] = durationRange;
                });
            }
            else if (normalisation === 'comparison') {
                const paths = getAnalysisPaths(analysis);
                const childDurationRanges = Object.values(analysis.compare).map((childAnalysis) => {
                    const childAnalysisKey = getType(childAnalysis);
                    const durationRange = {};
                    paths.forEach((path) => {
                        durationRange[path] = [0, getDurationMax(childAnalysis[childAnalysisKey][path])];
                    });
                    return durationRange;
                });
                paths.forEach((path) => {
                    range.durationRange[path] = childDurationRanges
                        .map(x => x[path])
                        .filter(x => x !== null)
                        .reduce((x, y) => [Math.min(x[0], y[0]), Math.max(x[1], y[1])]);
                });
            }
            return range;
        }
        return { durationRange: {} };
    };
    useEffect(() => {
        setRanges(calculateRange(payload, 'none'));
    }, [payload]);
    const prepareLayoutForComparison = () => {
        setComparisonMode(true);
    };
    const undoLayoutForComparison = () => {
        setComparisonMode(false);
    };
    useEffect(() => {
        payload && setRanges(calculateRange(payload, 'none'));
        if (payload?.compare)
            prepareLayoutForComparison();
        return () => undoLayoutForComparison();
    }, []);
    useEffect(() => {
        if (payload?.compare)
            prepareLayoutForComparison();
        else
            undoLayoutForComparison();
    });
    const handeAPIClick = () => {
        copy(`${window.location.origin}/api/data${location.pathname + location.search}`);
        notification.show(`API link was copied to clipboard`);
    };
    const getAnalysisTypeKey = (analysis) => {
        if (analysis?.['trace-overviews'])
            return 'PerformanceOverview';
        else if (analysis?.['trace-groups'])
            return 'PerformanceGroups';
        else if (analysis?.['trace-tree'])
            return 'PerformanceTree';
        else if (analysis?.compare)
            return 'PerformanceComparison';
        throw new Error('[Performance Page Error]: unknown analysis type');
    };
    const handleNormalisationTypeChange = (normalisation) => {
        setRanges(calculateRange(payload, normalisation));
    };
    const layout = payload && analysisNav?.id?.selected
        ? Layouts[`${getAnalysisTypeKey(payload)}`]({
            data: payload,
            ranges: ranges,
            analysisId: analysisNav.id.selected,
            onPathChange: (updatePathTree) => handleQueryChange(updatePathTree, 'trace.path'),
            onIdChange: (updatedId) => handleQueryChange(updatedId, 'trace.id'),
            onTrackerChange: (updatedTrackerTree) => handleQueryChange(updatedTrackerTree, 'trace.tracker'),
        })
        : 'Layout is loading';
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "No tracing data found!", comparison: comparisonMode, navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            analysisNav && (React.createElement(React.Fragment, null,
                React.createElement(SelectRun, Object.assign({ showCompareButton: Boolean(!analysisNav.compare) }, analysisNav.id, { onClose: navHandlers.handleRunClose, onCompare: () => handleQueryChange({
                        repository: {
                            selected: `${page.owner?.selected}/${page.owner?.repository?.selected}`,
                            commit: {},
                        },
                    }, 'compare'), onRunChange: updatedRun => handleQueryChange(updatedRun, 'id') })),
                analysisNav?.compare && (React.createElement(React.Fragment, null,
                    React.createElement(NavigationLineBreak, null),
                    React.createElement(SelectCompare, { data: analysisNav.compare, compareChange: updatedCompare => handleQueryChange(updatedCompare, 'compare') }))),
                React.createElement(NavigationLineBreak, null),
                React.createElement(SelectTracePath, { paths: analysisNav?.trace?.path, allowsMultiple: true, onPathChange: updatePathTree => handleQueryChange(updatePathTree, 'trace.path') }),
                React.createElement(NavigationLineBreak, null),
                React.createElement(SelectTraceTracker, { tracker: analysisNav?.trace?.tracker, onTrackerChange: updatePathTree => handleQueryChange(updatePathTree, 'trace.tracker') }),
                React.createElement(NavigationLineBreak, null),
                React.createElement(SelectTraceLabel, Object.assign({}, analysisNav?.trace?.labels, { onLabelChange: updatedLabels => handleQueryChange(updatedLabels, 'trace.labels') })),
                React.createElement(NavigationLineBreak, null),
                React.createElement(SliderIteration, Object.assign({}, analysisNav?.trace?.iteration, { onIterationChange: updatedRange => handleQueryChange(updatedRange, 'trace.iteration') })),
                React.createElement(NavigationLineBreak, null),
                React.createElement(InputTraceId, { id: analysisNav.trace.id, onIdChange: updatedId => handleQueryChange(updatedId, 'trace.id') }),
                React.createElement(NavigationLineBreak, null),
                React.createElement(Label, { onClick: handeAPIClick, showEmptyCloseBtn: true, width: 105 }, "API URL"))),
            React.createElement(NavigationLineBreak, null)) },
        payload && (React.createElement(ScaleNormalizeSwitcher, { showComparison: getAnalysisTypeKey(payload) === 'PerformanceComparison', normalisationTypeChange: handleNormalisationTypeChange })),
        layout));
};
export default PagePeformanceAnalaysis;
