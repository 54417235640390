import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        jobHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        runBox: {
            marginBottom: factoryTheme.spacing(4),
            '&:last-child': {
                marginBottom: 0,
            },
        },
    });
});
