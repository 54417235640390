export const SET_PAGE = 'SET_PAGE';
const initialState = {};
export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page,
});
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};
export default reducer;
