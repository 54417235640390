import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';
import { createFactoryStyles } from '../../styles/helpers';
const typeColorMap = {
    success: 'green',
    error: 'red',
    info: 'blue',
};
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: ({ type, action }) => ({
            width: `280px !important`,
            backgroundColor: Color(factoryTheme.palette[typeColorMap[type]][100]).alpha(0.2).string(),
            border: `1px solid ${factoryTheme.palette[typeColorMap[type]][100]}`,
            borderRadius: factoryTheme.shape.borderRadius.small,
            color: factoryTheme.palette[typeColorMap[type]][100],
            '&:before': {
                width: '100%',
                height: '100%',
                content: '""',
                backgroundColor: factoryTheme.palette.purple[700],
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
            },
            padding: action ? factoryTheme.spacing(1, 5) : factoryTheme.spacing(1, 4),
            position: 'relative',
            overflowWrap: 'anywhere',
        }),
        message: {},
        action: {
            cursor: 'pointer',
            fontSize: factoryTheme.typography.fontSize.smallest,
            position: 'absolute',
            right: 8,
            top: 8,
        },
        icon: {},
    }, {
        icon: {
            display: 'none',
        },
        message: {
            padding: 0,
        },
        action: {
            marginRight: 0,
            paddingLeft: 0,
        },
    });
});
