import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: 22,
            height: 22,
            border: (props) => `1px solid ${props.isConnected ? factoryTheme.palette.green[200] : factoryTheme.palette.purple[200]}`,
            borderRadius: '100%',
            textAlign: 'center',
            lineHeight: '22px',
        },
        icon: {
            width: '21px !important',
            color: (props) => props.isConnected ? factoryTheme.palette.green[200] : factoryTheme.palette.purple[200],
        },
    });
});
