import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
const BaseTitle = ({ type, hasLine, isClickable, classes, children }) => {
    const ownClasses = useStyles({ isClickable });
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root) },
        React.createElement("p", { className: clsx(ownClasses.text, ownClasses[type], classes?.text) }, children),
        hasLine && React.createElement("hr", { className: ownClasses.line })));
};
export default BaseTitle;
