import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import React from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import LogoFactory from '../../common/ui/assets/images/LogoFactory.svg';
import Button from '../../common/ui/lib/BaseButton';
import usePage from '../../common/services/page/use';
import { isUserAuthenticated } from '../../index/Router/helpers';
import { useStyles } from './styles';
const PageOwnerList = ({ location }) => {
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected } = usePage('owners');
    const owners = payload;
    const user = useSelector((state) => state.user);
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected },
        React.createElement("div", { className: ownClasses.logoContainer },
            React.createElement(LogoFactory, { className: ownClasses.logo })),
        React.createElement("div", { className: clsx(ownClasses.ownersGrid, {
                [ownClasses.ownersGridOneCol]: !owners || Object.values(owners).length === 0,
                [ownClasses.ownersGridTwoCol]: owners && Object.values(owners).length === 1,
                [ownClasses.ownersGridThreeCol]: owners && Object.values(owners).length >= 2,
            }) },
            owners &&
                Object.values(owners).map(owner => {
                    return (React.createElement(Button, { key: `owner-${owner.name}`, to: `/${owner.name}`, size: "large", classes: {
                            root: ownClasses.ownerButton,
                            label: ownClasses.ownerButtonLabel,
                        } },
                        React.createElement("div", { className: ownClasses.ownerButtonContent },
                            React.createElement("img", { src: `https://avatars.githubusercontent.com/${owner.name}`, className: ownClasses.ownerButtonAvatar }),
                            React.createElement("p", null,
                                "@",
                                owner.name))));
                }),
            isUserAuthenticated && user['full-name'] && user.username ? (React.createElement(Button, { href: `https://${window.location.host}/api/install`, size: "large", classes: {
                    root: ownClasses.addButton,
                    label: ownClasses.addButtonLabel,
                } },
                React.createElement("div", { className: ownClasses.addButtonContent },
                    React.createElement(Icon, { icon: 'plus-circle', className: ownClasses.addButtonIcon }),
                    React.createElement("p", null, "Add Organisation/User")))) : (React.createElement(Button, { href: `https://${window.location.host}/api/login?path=${location.pathname}`, size: "large", classes: {
                    root: ownClasses.signInButton,
                    label: ownClasses.signInButtonLabel,
                } },
                React.createElement("div", { className: ownClasses.signInButtonContent },
                    React.createElement(Icon, { icon: ['fal', 'sign-in'], className: ownClasses.signInButtonIcon }),
                    React.createElement("p", null, "Login")))))));
};
export default PageOwnerList;
