import { makeStyles, fade } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles, hideScrollbar } from '../../styles/helpers';
const selectMenuMaxHeight = 300;
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            minWidth: ({ minWidth }) => minWidth,
        },
        dropdownRoot: {
            width: '100%',
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            height: '22px',
            boxSizing: 'content-box',
        },
        dropdownInputRoot: {
            '&:before': {
                borderBottom: `0!important`,
            },
            padding: '0!important',
            '&:after': {
                borderBottom: `1px solid ${factoryTheme.palette.green[200]}`,
            },
        },
        dropdownInput: {
            minWidth: 'fit-content!important',
            padding: '0 10px!important;',
            height: '22px',
            color: `${factoryTheme.palette.purple[100]}`,
            backgroundColor: `${factoryTheme.palette.purple[400]}`,
        },
        clearIndicator: {
            color: factoryTheme.palette.green[200],
        },
        dropdownPopper: {
            '& li:hover, li[data-focus=true]': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.1).string(),
            },
            '& *::-webkit-scrollbar': {
                width: 5,
                height: 3,
                position: 'absolute',
            },
            '& *:hover::-webkit-scrollbar-thumb': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
            '& *::-webkit-scrollbar-thumb:window-inactive': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
            '& *::-webkit-scrollbar-track': {
                background: 'transparent',
            },
            '& *::-webkit-scrollbar-thumb': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
        },
        paper: {
            width: ({ minWidth }) => minWidth,
            maxHeight: selectMenuMaxHeight,
            backgroundColor: factoryTheme.palette.purple[600],
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
            borderTop: 'none',
            borderRadius: `0 0 ${factoryTheme.shape.borderRadius.small}px ${factoryTheme.shape.borderRadius.small}px`,
            boxShadow: `0 2px 20px 0 ${fade(factoryTheme.palette.purple[600], 0.29)}`,
            color: factoryTheme.palette.white[200],
            '& li:hover': {
                color: `${factoryTheme.palette.green[200]}!important`,
            },
        },
        list: {
            padding: 0,
        },
        input: {
            backgroundColor: factoryTheme.palette.purple[400],
            borderRadius: factoryTheme.shape.borderRadius.small,
            color: factoryTheme.palette.green[200],
            lineHeight: '24px',
            padding: factoryTheme.spacing(0, 2),
            paddingRight: '30px !important',
            '&[aria-expanded="true"]': {
                borderRadius: `${factoryTheme.shape.borderRadius.small}px ${factoryTheme.shape.borderRadius.small}px 0 0`,
            },
            boxSizing: 'border-box',
        },
        option: {
            width: '100%',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            ...hideScrollbar(),
        },
        selectedOption: {
            color: factoryTheme.palette.green[200],
        },
        placeholder: {
            color: factoryTheme.palette.purple[100],
        },
    });
});
export const useOpenButtonStyle = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: 24,
            borderLeft: `2px solid ${factoryTheme.palette.purple[600]}`,
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            pointerEvents: 'none',
        },
        icon: {
            width: '20px !important',
            color: factoryTheme.palette.green[200],
            fontSize: factoryTheme.typography.fontSize.smallest,
        },
    });
});
