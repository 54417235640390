const MAX_COMMITS_LENGTH = 30;
export const sortCommits = (incomingCommits) => {
    if (!incomingCommits)
        return [];
    const segments = {};
    // NOTE: Keep a list of disconnected segments of commits list
    Object.values(incomingCommits).forEach(commit => {
        if (segments[commit.previous]) {
            segments[commit.sha] = [commit, ...segments[commit.previous]];
            delete segments[commit.previous];
        }
        else {
            segments[commit.sha] = [commit];
        }
    });
    // NOTE: Sort segments based on their created time
    const sortedCommits = Object.values(segments)
        .sort((x, y) => (x[0].created > y[0].created ? -1 : 1))
        .flatMap((x) => x)
        .slice(0, MAX_COMMITS_LENGTH);
    return sortedCommits;
};
