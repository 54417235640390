import { useState, useEffect } from 'react';
import { findLastRunOption, getRunOptions, constructRerunMessage, constructRouteForWorkflowRerun, constructAutomationSummary, constructRouteForPipelineRerun, } from '../../helpers';
import useNotification from '../notification/use';
import { useRequester } from '../dataChannel/use';
const useSummary = (automation, summaryWhitelist) => {
    const [runOptions, setRunOptions] = useState(getRunOptions(automation));
    const [selectedRun, setSelectedRun] = useState(findLastRunOption(automation));
    const [summary, setSummary] = useState({});
    const notification = useNotification();
    const requester = useRequester();
    const handleRunChange = (runOption) => {
        setSelectedRun(runOption);
    };
    const handleWorkflowRerun = (workflowName) => {
        notification.show(constructRerunMessage({ name: workflowName, type: 'Workflow' }));
        requester({ route: constructRouteForWorkflowRerun(automation[selectedRun].symbol) });
    };
    const handlePipelineRerun = (pipelineName) => {
        notification.show(constructRerunMessage({ name: pipelineName, type: 'Pipeline' }));
        requester({ route: constructRouteForPipelineRerun(automation[selectedRun].symbol) });
    };
    useEffect(() => {
        if (selectedRun && runOptions.length) {
            const { run, status, started, ended } = automation[selectedRun];
            const summary = constructAutomationSummary({
                run,
                status,
                started,
                ended,
                selectedRun,
                runOptions,
            }, summaryWhitelist);
            setSummary(summary);
        }
    }, [runOptions, selectedRun]);
    useEffect(() => {
        setRunOptions(getRunOptions(automation));
        setSelectedRun(findLastRunOption(automation));
    }, [automation]);
    return {
        runOptions,
        selectedRun,
        summary,
        handleRunChange,
        handleWorkflowRerun,
        handlePipelineRerun,
    };
};
export default useSummary;
