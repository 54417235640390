import React from 'react';
import Title from '../../common/ui/lib/BaseTitle';
import Summary from '../../common/ui/components/Summary';
import SummaryRow from '../../common/ui/components/Summary/row';
import SummaryCol from '../../common/ui/components/Summary/col';
import StatusTile from '../../common/ui/components/StatusTile';
import Box from '../../common/ui/lib/BaseBox';
import { WorkflowName } from '../../Workflow/common/types';
import { useStyles } from './styles';
const SummaryRepository = ({ pipelines, analyses, url, title, shouldHideWorkflowValidation, }) => {
    const ownClasses = useStyles();
    function renderAnalysis() {
        if (analyses)
            return (React.createElement(React.Fragment, null,
                React.createElement(Title, { type: "h2", hasLine: true, classes: { root: ownClasses.summaryTitle } }, "Analysis"),
                Object.values(analyses).map(analysis => (React.createElement(Summary, { key: analysis.name },
                    React.createElement(SummaryRow, { title: analysis.name, isStacked: true, wide: true },
                        React.createElement(SummaryCol, { isStacked: true }, analysis.summary.map((status, index) => (React.createElement(StatusTile, { classes: {
                                root: ownClasses.statusTile,
                            }, key: `row-${index}`, status: status }))))))))));
        return null;
    }
    return (React.createElement(Box, { classes: { root: ownClasses.root }, url: url, header: React.createElement(Title, { classes: { text: ownClasses.repoTitle }, type: "h1" }, title) },
        React.createElement(Title, { type: "h2", hasLine: true, classes: { root: ownClasses.summaryTitle } }, "Pipelines"),
        Object.values(pipelines).map(pipeline => (React.createElement(Summary, { key: pipeline.name },
            React.createElement(SummaryRow, { title: pipeline.name, isStacked: true, wide: true }, pipeline.workflows.map(workflow => (React.createElement(SummaryCol, { key: workflow.name, isStacked: true, isHidden: shouldHideWorkflowValidation && workflow.name === WorkflowName.Validation }, workflow.summary.map((status, index) => (React.createElement(StatusTile, { classes: {
                    root: ownClasses.statusTile,
                }, key: `${workflow.name}-${index}`, status: status })))))))))),
        renderAnalysis()));
};
export default SummaryRepository;
