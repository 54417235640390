import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        emptyMsg: {
            marginBottom: factoryTheme.spacing(2),
        },
    });
});
export const useItemStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            marginBottom: factoryTheme.spacing(2),
        },
        content: {
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            lineHeight: '22px',
            flexGrow: 1,
            padding: factoryTheme.spacing(0, 2),
        },
        actions: {
            marginLeft: factoryTheme.spacing(4),
        },
    });
});
