import { deepFind } from '../../utils';
const UrlConstructionError = new Error('[URL Construction] the page object does not contain sufficient data for determining the destination URL');
const isDefined = (page, ...paths) => {
    for (const path of paths) {
        if (deepFind(page, path) === undefined)
            return false;
    }
    return true;
};
export const constructUrlForOwnerPage = (page, newOwner) => {
    if (!newOwner && !isDefined(page, 'owner.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${newOwner || page.owner?.selected}`;
    return url;
};
export const constructUrlForOwnerSettingsPage = page => {
    if (!isDefined(page, 'owner.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += '/settings';
    return url;
};
export const constructUrlForRepoPage = (page, newRepo) => {
    if (!isDefined(page, 'owner.selected'))
        throw UrlConstructionError;
    if (!newRepo && !isDefined(page, 'owner.repository.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${newRepo || page.owner?.repository?.selected}`;
    return url;
};
export const constructUrlForRepoPageBranch = (page, newBranch) => {
    if (!newBranch &&
        !isDefined(page, 'owner.selected', 'owner.repository.selected', 'owner.repository.branch.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${page.owner?.repository?.selected}`;
    url += `/${newBranch || page.owner?.repository?.branch?.selected}`;
    return url;
};
export const constructUrlForRepoSettingsPage = page => {
    if (!isDefined(page, 'owner.selected', 'owner.repository.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${page.owner?.repository?.selected}`;
    url += '/settings';
    return url;
};
export const constructUrlForBranchRulePage = (page, newBranchRule) => {
    if (!newBranchRule &&
        !isDefined(page, 'owner.selected', 'owner.repository.selected', 'owner.repository.branch-rule.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${page.owner?.repository?.selected}`;
    url += '/settings/branch-rule';
    url += `/${newBranchRule || page.owner?.repository?.['branch-rule']?.selected}`;
    return url;
};
export const constructUrlForCommitPage = (page, newCommit) => {
    if (!newCommit &&
        !isDefined(page, 'owner.selected', 'owner.repository.selected', 'owner.repository.commit.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${page.owner?.repository?.selected}`;
    url += `/${newCommit || page.owner?.repository?.commit?.selected}`;
    return url;
};
export const constructUrlForAutomationPage = (page, newAutomation) => {
    if (!isDefined(page, 'owner.selected', 'owner.repository.selected', 'owner.repository.commit.selected'))
        throw UrlConstructionError;
    let url = '';
    url += `/${page.owner?.selected}`;
    url += `/${page.owner?.repository?.selected}`;
    url += `/${page.owner?.repository?.commit?.selected}`;
    let automation = newAutomation;
    while (automation && automation.selected) {
        url += `/${automation.selected}`;
        automation = automation.symbol;
    }
    return url;
};
export const constructUrlForArbitraryPage = (page) => {
    if (page) {
        if (isDefined(page, 'owner.repository.branch'))
            return constructUrlForRepoPageBranch(page);
        if (isDefined(page, 'owner.repository.commit.automation'))
            return constructUrlForAutomationPage(page, page?.owner?.repository?.commit?.automation?.symbol);
        if (isDefined(page, 'owner.repository.commit'))
            return constructUrlForCommitPage(page);
        if (isDefined(page, 'owner.repository.branch-rule'))
            return constructUrlForBranchRulePage(page);
        if (isDefined(page, 'owner.repository.settings'))
            return constructUrlForRepoSettingsPage(page);
        if (isDefined(page, 'owner.repository'))
            return constructUrlForRepoPage(page);
        if (isDefined(page, 'owner.settings'))
            return constructUrlForOwnerSettingsPage(page);
        if (isDefined(page, 'owner'))
            return constructUrlForOwnerPage(page);
    }
    throw UrlConstructionError;
};
