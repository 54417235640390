import React from 'react';
import usePage from '../../common/services/page/use';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import Label from '../../common/ui/lib/BaseLabel';
import { useRequester } from '../../common/services/dataChannel/use';
import EnvVariables from '../../common/components/EnvVariable';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import { DiscordWebhookUrl } from '../../common/components/DiscordWebhookUrl/form';
import { DiscordUserIds } from '../../common/components/DiscordUserIds';
import Title from '../../common/ui/lib/BaseTitle';
import useNotification from '../../common/services/notification/use';
import Box from '../../common/ui/lib/BaseBox';
import Repositories from './repositories';
import { useStyles } from './styles';
const PageOwnerSettings = () => {
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('owner');
    const owner = payload;
    const requester = useRequester();
    const notification = useNotification();
    const handleMarkRepoInactive = (repoName) => {
        requester({ route: `${owner.name}/settings/remove-repo`, body: { owner: { repository: repoName } } });
    };
    const handleMarkRepoActive = (repoName) => {
        requester({ route: `${owner.name}/settings/add-repo`, body: { owner: { repository: repoName } } });
    };
    const handleVariableAdd = (variable) => {
        requester({
            route: `${owner.name}/settings/add-variable`,
            body: {
                owner: { 'environment-variables': variable },
            },
        });
    };
    const handleVariableRemove = (variable) => {
        requester({
            route: `${owner.name}/settings/remove-variable`,
            body: {
                owner: { 'environment-variables': variable },
            },
        });
    };
    const handleDiscordWebhookUrlSave = (value) => {
        requester({
            route: `${owner.name}/settings/save-discord-webhook-url`,
            body: {
                owner: { 'discord-webhook-url': value },
            },
        });
        notification.show('Discord Webhook URL saved');
    };
    const handleDiscordIdSave = (username, id) => {
        requester({
            route: `${owner.name}/settings/save-discord-id`,
            body: {
                owner: {
                    username: username,
                    'discord-id': id,
                },
            },
        });
        notification.show('Discord ID saved');
    };
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(React.Fragment, null,
                React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange })),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            React.createElement(Label, { onClose: navHandlers.handleOwnerSettingsClose }, "settings")) },
        owner?.repository && (React.createElement(Repositories, { actives: owner.repository.active, inactives: owner.repository.inactive, onMarkAsActive: handleMarkRepoActive, onMarkAsInactive: handleMarkRepoInactive, installationUrl: owner['installation-link'], emptyMsg: React.createElement(React.Fragment, null,
                "Factory is not tracking any repositories of ",
                React.createElement("strong", null, owner.name),
                ".") })),
        owner?.['environment-variables'] && (React.createElement(EnvVariables, { variables: owner?.['environment-variables'], emptyMsg: React.createElement(React.Fragment, null,
                "There are no environment variables for ",
                React.createElement("strong", null, owner.name),
                "."), owner: owner.name, onAdd: handleVariableAdd, onRemove: handleVariableRemove })),
        owner?.type === 'organisation' && (React.createElement(Box, { header: React.createElement(Title, { type: "h1" }, "Discord Notifications") },
            React.createElement(Title, { hasLine: true, classes: { root: ownClasses.firstTitle }, type: "h1" }, "Discord Webhook URL"),
            React.createElement(DiscordWebhookUrl, { onSubmit: handleDiscordWebhookUrlSave, url: owner?.['discord-webhook-url'] }),
            React.createElement(Title, { hasLine: true, classes: { root: ownClasses.title }, type: "h1" }, "Discord IDs"),
            React.createElement(DiscordUserIds, { users: owner?.['discord-ids'], onSubmit: handleDiscordIdSave })))));
};
export default PageOwnerSettings;
