const green300 = 0x31969c;
const green200 = 0x3cede0;
const purple300 = 0x41325a;
const purple400 = 0x2c2349;
const purple500 = 0x211437;
const purple700 = 0x10071f;
const white = 0xf5f2ff;
const black = 0x0;
export const CHART_CONFIG = {
    OFFSCREEN: 10000000,
    LAYER: {
        BACKGROUND: {
            NAME: 'background',
            Z_INDEX: 0,
        },
        GRAPH: {
            NAME: 'graph',
            Z_INDEX: 1,
        },
        COORDINATE_SYSTEM: {
            NAME: 'coordinate-system',
            Z_INDEX: 2,
        },
        HOVER_OVERLAY: {
            NAME: 'hover-overlay',
            Z_INDEX: 3,
        },
    },
    CHART: {
        HEIGHT: 400,
        BG_COLOR: purple500,
        GRID_STYLE: {
            width: 2,
            color: green200,
            alpha: 0.03,
        },
        SCALE_ANIMATION_DURATION: 200,
        PADDING: {
            HORIZONTAL: {
                LEFT: 35,
                RIGHT: 35,
            },
            VERTICAL: {
                TOP: 5,
                BOTTOM: 40,
            },
        },
        OFFSET: {
            // data will take 100% of the width
            HORIZONTAL: 0,
            // data will have 25 px offset from top and bottom
            VERTICAL: 25,
        },
        POINT: {
            BOLD: {
                COLOR: green200,
                RADIUS: 6,
            },
            JOINT: {
                COLOR: green200,
                RADIUS: 6,
            },
            EXPANSION: {
                COLOR: black,
                RADIUS: 3,
            },
        },
        HOVER_OVERLAY: {
            LINE_STYLE: {
                width: 1,
                color: green200,
                alpha: 0.7,
            },
            TEXT: {
                STYLE: {
                    fontFamily: 'Titillium Web',
                    fontSize: 12,
                    fill: green200,
                },
                ALPHA: 1,
            },
            MARGIN: {
                HORIZONTAL: 5,
                VERTICAL: 20,
            },
        },
        TREND: {
            SHAPE: {
                ALPHA: 0.85,
            },
            LINE: {
                STYLE: {
                    width: 1,
                    color: white,
                    alpha: 0.15,
                },
                BOLD_STYLE: {
                    width: 2,
                    color: green300,
                    alpha: 1,
                },
            },
            POINT: {
                ALPHA: 0.5,
                RADIUS: 1,
                COLOR: green200,
            },
            CONFIG: [
                { COLOR: purple500, PERCENT: '95', HOVER_OVERLAY_VERTICAL_MARGIN: 0 },
                { COLOR: purple400, PERCENT: '75', HOVER_OVERLAY_VERTICAL_MARGIN: 12 },
                { COLOR: purple500, PERCENT: '25', HOVER_OVERLAY_VERTICAL_MARGIN: 36 },
                { COLOR: purple300, PERCENT: '05', HOVER_OVERLAY_VERTICAL_MARGIN: 48 },
                { COLOR: purple400, PERCENT: '50', HOVER_OVERLAY_VERTICAL_MARGIN: 24, BOLD: true },
            ],
        },
        BOX: {
            WIDTH: 50,
            COLOR: purple400,
            ALPHA: 0.85,
            LINE_STYLE: {
                width: 1,
                color: white,
                alpha: 0.15,
            },
            WHISKER_STYLE: {
                width: 3,
                color: purple500,
                alpha: 0.85,
            },
            MEDIAN_STYLE: {
                width: 3,
                color: green300,
                alpha: 1,
            },
        },
        LINE: {
            STYLE: {
                width: 1,
                color: white,
                alpha: 0.3,
            },
            HALF_FOCUS_STYLE: {
                width: 2,
                color: green300,
                alpha: 0.5,
            },
            FOCUS_STYLE: {
                width: 2,
                color: green300,
                alpha: 1,
            },
            POINT: {
                RADIUS: 2,
                RADIUS_HOVERED: 4,
                COLOR: green200,
                ALPHA: 0.8,
                OUTLINE_COLOR: purple300,
            },
            JOINT_POINT: {
                RADIUS: 4,
                COLOR: green200,
                ALPHA: 0.8,
                EXPANDED_INDICATOR_COLOR: purple500,
            },
            INTERACTIVE_RADIUS: 5,
            OVERLAY_COLOR: purple700,
        },
        TREE: {
            PADDING: {
                HORIZONTAL: 10,
                VERTICAL: 10,
            },
            LEVEL_HEIGHT: 30,
            RECT: {
                COLOR: purple500,
                ALPHA: 0.85,
                HEIGHT: 20,
                MIN_WIDTH: 20,
            },
            TEXT: {
                STYLE: {
                    fontFamily: 'Titillium Web',
                    fontSize: 12,
                    fill: white,
                },
                ALPHA: 0.9,
                PADDING: {
                    HORIZONTAL: 5,
                    VERTICAL: 1.5,
                },
            },
            LINE: {
                STYLE: {
                    width: 1,
                    color: white,
                    alpha: 0.15,
                },
                FOCUS_STYLE: {
                    width: 1,
                    color: green200,
                    alpha: 1,
                },
                PADDING: 5,
            },
        },
    },
    COORDINATE_SYSTEM: {
        BG_COLOR: purple300,
        ARROW: {
            ALPHA: 0.65,
            COLOR: green200,
            ANGLE: Math.PI / 18.0,
            LENGTH: 15,
        },
        TICK: {
            // half the size of the TICK on the axis
            SIZE: 2,
            STYLE: {
                width: 2,
                color: green200,
            },
            COUNT: {
                HORIZONTAL: 20,
                VERTICAL: 8,
            },
            STEPS: [
                1,
                2,
                5,
                10,
                20,
                25,
                50,
                100,
                200,
                250,
                500,
                1000,
                2500,
                5000,
                10000,
                25000,
                50000,
                100000,
                250000,
                500000,
                1000000,
                2500000,
                5000000,
                10000000,
                100000000,
            ],
            STEPS_WITH_FRACTIONS: [
                0.05,
                0.1,
                0.2,
                0.5,
                1,
                2,
                5,
                10,
                20,
                25,
                50,
                100,
                200,
                250,
                500,
                1000,
                2500,
                5000,
                10000,
                25000,
                50000,
                100000,
                250000,
                500000,
                1000000,
                2500000,
                5000000,
                10000000,
                100000000,
            ],
        },
        AXIS: {
            STYLE: {
                width: 1,
                color: green200,
                alpha: 0.5,
            },
        },
        OUTLINE: {
            STYLE: {
                width: 1,
                color: purple700,
                alpha: 1,
            },
        },
        TEXT_Y: {
            STYLE: {
                fontFamily: 'Titillium Web',
                fontSize: 12,
                fill: white,
            },
            HORIZONTAL_PADDING: 5,
        },
        TEXT_X: {
            STYLE: {
                fontFamily: 'Titillium Web',
                fontSize: 12,
                fill: white,
            },
            VERTICAL_PADDING: 5,
        },
        UNIT_LABEL: {
            STYLE: {
                fontFamily: 'Titillium Web',
                fontSize: 14,
                fill: white,
            },
            ITERATION: 'Iteration',
            DURATION: 'Duration (ms)',
            VERTICAL_PADDING: 20,
            SINGLE_ITERATION_VERTICAL_PADDING: 2,
            ALPHA: 0.7,
        },
    },
};
