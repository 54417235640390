import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: 24,
            display: 'flex',
            alignItems: 'center',
        },
        slider: {
            width: 300,
            color: factoryTheme.palette.green[200],
            margin: factoryTheme.spacing(0, 3),
            padding: '11px 0',
        },
        rangeTextInput: {
            width: 110,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            color: factoryTheme.palette.green[200],
            textAlign: 'center',
            marginLeft: '-1px',
            paddingLeft: 0,
        },
        sliderContainer: {
            height: 24,
            width: 'fit-content',
            backgroundColor: factoryTheme.palette.purple[400],
        },
        sliderThumb: {
            height: 10,
            width: 10,
            backgroundColor: factoryTheme.palette.green[200],
            marginTop: '-4px',
        },
        sliderRail: {
            color: factoryTheme.palette.purple[500],
        },
        sliderActive: {},
    }, {
        sliderThumb: {
            '&:hover': {
                boxShadow: 'none',
            },
        },
        sliderActive: {
            boxShadow: 'none !important',
        },
        sliderRail: {
            opacity: 1,
        },
    });
});
