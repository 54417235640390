import React, { useState, useEffect } from 'react';
import { getDuration } from '../../../utils';
const computeTime = (start, end) => {
    let time = '-';
    if (start && !end) {
        time = getDuration(new Date(start), new Date());
    }
    else if (start && end)
        time = getDuration(new Date(start), new Date(end));
    return time;
};
const BaseTime = ({ started, ended }) => {
    const [time, setTime] = useState(computeTime(started, ended));
    useEffect(() => {
        const interval = setInterval(() => setTime(computeTime(started, ended)), 1000);
        return () => clearInterval(interval);
    }, [started, ended]);
    return React.createElement(React.Fragment, null, time);
};
export default BaseTime;
