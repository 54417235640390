import React from 'react';
import clsx from 'clsx';
import { useSummaryRowStyles } from './styles';
const SummaryRow = ({ children, title, isStacked, wide }) => {
    const ownClasses = useSummaryRowStyles();
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement("p", { className: clsx(ownClasses.title, { [ownClasses.bigWidth]: wide }) }, title),
        React.createElement("div", { className: clsx(ownClasses.row, { [ownClasses.stacked]: isStacked }) }, children)));
};
export default SummaryRow;
