import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        shaBox: {
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            borderRadius: factoryTheme.shape.borderRadius.small,
            color: factoryTheme.palette.purple[200],
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationA,
            padding: factoryTheme.spacing(0.5, 1),
            '&:hover': {
                color: factoryTheme.palette.green[200],
            },
        },
    });
});
