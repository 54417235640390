import React from 'react';
import * as PIXI from 'pixi.js';
import { getShortNotation, roundOneDecimal, safeLog10 } from '../../../common/utils';
import { CHART_CONFIG } from '../config';
import { useChart, useChartRenderer } from '../mixins';
import { createBackground, createVerticalAxis, createHoverOverlayText, drawDashedPolygon, getLabels, getTextSize, removeChildIfExists, getMap, } from '../utils';
import { useStyles } from './style';
const SingleIterationTrendChart = ({ width, height, data, ranges, isLogScaled, }) => {
    const ownClasses = useStyles();
    const { rootRef, overlayRef, canvasRef, stage, setStage } = useChart();
    const calculateDataMap = (data, ranges) => {
        let durationRange;
        if (ranges && ranges.durationRange) {
            ({ durationRange } = ranges);
        }
        else {
            durationRange = [0, Math.max(...(Object.values(data.percentiles)))];
        }
        const topPadding = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const bottomPadding = CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const [mapY, mapYInverse] = getMap(durationRange, [height - bottomPadding, topPadding]);
        const [mapYLog, mapYLogInverse] = getMap(durationRange.map(safeLog10), [height - bottomPadding, topPadding]);
        return {
            durationRange,
            mapY,
            mapYLog,
            mapYInverse,
            mapYLogInverse,
        };
    };
    const render = (data, mouseX, mouseY, logScaleAnimationProgress) => {
        const { durationRange, mapY, mapYLog, mapYInverse, mapYLogInverse } = calculateDataMap(data, ranges);
        const updateBackground = () => {
            // eslint-disable-next-line no-use-before-define
            if (!stage.getChildByName(CHART_CONFIG.LAYER.BACKGROUND.NAME)) {
                const background = createBackground(width, height);
                background.name = CHART_CONFIG.LAYER.BACKGROUND.NAME;
                background.zIndex = CHART_CONFIG.LAYER.BACKGROUND.Z_INDEX;
                // eslint-disable-next-line no-use-before-define
                stage.addChild(background);
            }
        };
        const updateGraph = (data, logScaleAnimationProgress) => {
            const createGraph = (data, logScaleAnimationProgress) => {
                const localMapY = (y) => (1 - logScaleAnimationProgress) * mapY(y) + logScaleAnimationProgress * mapYLog(safeLog10(y));
                const pixelData = {};
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    pixelData[config.PERCENT] = localMapY(data.percentiles[config.PERCENT]);
                });
                const createWhisker = (yFrom, yTo) => {
                    const x = width / 2;
                    const line = new PIXI.Graphics();
                    line.lineStyle(CHART_CONFIG.CHART.BOX.WHISKER_STYLE);
                    line.moveTo(x, yFrom);
                    line.lineTo(x, yTo);
                    return line;
                };
                const createBox = (maxY, medianY, minY) => {
                    const x1 = width / 2 - CHART_CONFIG.CHART.BOX.WIDTH / 2;
                    const x2 = width / 2 + CHART_CONFIG.CHART.BOX.WIDTH / 2;
                    const rect = new PIXI.Graphics();
                    rect.alpha = CHART_CONFIG.CHART.BOX.ALPHA;
                    rect.beginFill(CHART_CONFIG.CHART.BOX.COLOR);
                    rect.moveTo(x1, maxY);
                    rect.lineTo(x1, minY);
                    rect.lineTo(x2, minY);
                    rect.lineTo(x2, maxY);
                    rect.endFill();
                    const rectOutline = new PIXI.Graphics();
                    rectOutline.lineStyle(CHART_CONFIG.CHART.BOX.LINE_STYLE);
                    rectOutline.moveTo(x1, maxY);
                    rectOutline.lineTo(x1, minY);
                    rectOutline.lineTo(x2, minY);
                    rectOutline.lineTo(x2, maxY);
                    rectOutline.closePath();
                    const line = new PIXI.Graphics();
                    line.lineStyle(CHART_CONFIG.CHART.BOX.MEDIAN_STYLE);
                    line.moveTo(x1, medianY);
                    line.lineTo(x2, medianY);
                    const box = new PIXI.Container();
                    box.addChild(rect);
                    box.addChild(rectOutline);
                    box.addChild(line);
                    return box;
                };
                const graph = new PIXI.Container();
                graph.addChild(createBox(pixelData['75'], pixelData['50'], pixelData['25']));
                graph.addChild(createWhisker(pixelData['75'], pixelData['95']));
                graph.addChild(createWhisker(pixelData['25'], pixelData['05']));
                return graph;
            };
            // eslint-disable-next-line no-use-before-define
            const lastGraph = stage.getChildByName(CHART_CONFIG.LAYER.GRAPH.NAME);
            if (!lastGraph ||
                lastGraph.chart.data !== data ||
                lastGraph.chart.logScaleAnimationProgress !== logScaleAnimationProgress) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.GRAPH.NAME);
                const graph = createGraph(data, logScaleAnimationProgress);
                graph.name = CHART_CONFIG.LAYER.GRAPH.NAME;
                graph.zIndex = CHART_CONFIG.LAYER.GRAPH.Z_INDEX;
                graph.chart = { data, logScaleAnimationProgress };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(graph);
            }
        };
        const updateCoordinateSystem = (data, logScaleAnimationProgress) => {
            const createChartCoordinateSystem = () => {
                const { iteration } = data;
                const iterationLabel = new PIXI.Text(`Iteration ${iteration}`, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
                const [textWidth] = getTextSize(`Iteration ${iteration}`, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.STYLE);
                iterationLabel.x = width / 2 - textWidth / 2;
                iterationLabel.y =
                    height -
                        CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM +
                        CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.SINGLE_ITERATION_VERTICAL_PADDING;
                iterationLabel.alpha = CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ALPHA;
                const durationLabelsLinear = getLabels(...durationRange, 8, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: getShortNotation(label), pos: mapY(label) }));
                const durationLabelsLog = getLabels(...durationRange.map(safeLog10), CHART_CONFIG.COORDINATE_SYSTEM.TICK.COUNT.VERTICAL, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS_WITH_FRACTIONS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: label.toString(), pos: mapYLog(label) }));
                const axisLinear = createVerticalAxis(width, height, durationLabelsLinear, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                axisLinear.name = 'linear';
                axisLinear.alpha = 0;
                const axisLog = createVerticalAxis(width, height, durationLabelsLog, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                axisLog.name = 'log';
                axisLog.alpha = 0;
                const coordinateSystem = new PIXI.Container();
                coordinateSystem.addChild(axisLinear);
                coordinateSystem.addChild(axisLog);
                coordinateSystem.addChild(iterationLabel);
                return coordinateSystem;
            };
            // eslint-disable-next-line no-use-before-define
            const lastCoordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (!lastCoordinateSystem || lastCoordinateSystem.chart.data !== data) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
                const coordinateSystem = createChartCoordinateSystem();
                coordinateSystem.name = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME;
                coordinateSystem.zIndex = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.Z_INDEX;
                coordinateSystem.chart = { data, logScaleAnimationProgress };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(coordinateSystem);
            }
            // eslint-disable-next-line no-use-before-define
            const coordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (logScaleAnimationProgress === 1) {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
            else if (logScaleAnimationProgress === 0) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else if (isLogScaled) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
        };
        const updateHoverOverlay = (data, mouseX, mouseY) => {
            const createHoverOverlay = () => {
                const hoverOverlay = new PIXI.Container();
                const lineHorizontal = new PIXI.Graphics();
                lineHorizontal.name = 'lineHorizontal';
                lineHorizontal.lineStyle(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE);
                drawDashedPolygon([
                    { x: width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, y: 0 },
                    { x: CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, y: 0 },
                ], lineHorizontal, 1, 2);
                hoverOverlay.addChild(lineHorizontal);
                const labelY = createHoverOverlayText();
                labelY.name = 'labelY';
                labelY.x =
                    CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                hoverOverlay.addChild(labelY);
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    const labelTrend = createHoverOverlayText();
                    labelTrend.name = `labelTrend${config.PERCENT}`;
                    labelTrend.x =
                        width -
                            CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT +
                            CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                    labelTrend.y = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + config.HOVER_OVERLAY_VERTICAL_MARGIN;
                    hoverOverlay.addChild(labelTrend);
                });
                return hoverOverlay;
            };
            // eslint-disable-next-line no-use-before-define
            const lastHoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (!lastHoverOverlay || lastHoverOverlay.chart.data !== data) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
                const hoverOverlay = createHoverOverlay();
                hoverOverlay.name = CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME;
                hoverOverlay.zIndex = CHART_CONFIG.LAYER.HOVER_OVERLAY.Z_INDEX;
                hoverOverlay.chart = { data, mouseX, mouseY };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(hoverOverlay);
            }
            // eslint-disable-next-line no-use-before-define
            const hoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (mouseX >= CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT &&
                mouseY >= CHART_CONFIG.CHART.PADDING.VERTICAL.TOP &&
                mouseX <= width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT &&
                mouseY <= height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM) {
                const mapBackY = isLogScaled ? mapYLogInverse : mapYInverse;
                hoverOverlay.alpha = 1;
                hoverOverlay.getChildByName('lineHorizontal').y = mouseY;
                hoverOverlay.getChildByName('labelY').y = mouseY;
                hoverOverlay.getChildByName('labelY').text = roundOneDecimal(mapBackY(mouseY)).toString();
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    const labelTrend = hoverOverlay.getChildByName(`labelTrend${config.PERCENT}`);
                    labelTrend.text = data.percentiles[config.PERCENT].toString();
                });
            }
            else {
                hoverOverlay.alpha = 0;
            }
        };
        updateBackground();
        updateGraph(data, logScaleAnimationProgress);
        updateCoordinateSystem(data, logScaleAnimationProgress);
        updateHoverOverlay(data, mouseX, mouseY);
    };
    const { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart } = useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, ranges, isLogScaled, render);
    const handleMouseEnter = () => {
        swapInWebglCanvas();
        renderChart();
    };
    const handleMouseOut = () => {
        swapOutWebglCanvas();
        renderChart();
        copyChart();
    };
    const handleMouseMove = () => {
        renderChart();
    };
    return (React.createElement("div", { ref: rootRef, className: ownClasses.root },
        React.createElement("div", { ref: overlayRef, className: ownClasses.overlay, onMouseEnter: handleMouseEnter, onMouseOut: handleMouseOut, onMouseMove: handleMouseMove }),
        React.createElement("canvas", { ref: canvasRef, width: width * devicePixelRatio, height: height * devicePixelRatio })));
};
export default SingleIterationTrendChart;
