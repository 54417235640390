import { useHistory } from 'react-router-dom';
import merge from 'deepmerge';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { deepFind } from '../../utils';
import { SET_PAGE } from '../../store/reducers/page';
import { SET_USER } from '../../store/reducers/user';
import useNotification from '../notification/use';
import { constructUrlForArbitraryPage } from '../navigation/helpers';
const SUCCESS_STATUS_CODE = {
    ALL: 200,
    DATA: 201,
    UPDATE: 204,
};
const FAILURE_STATUS_CODE = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    INTERNAL_ERROR: 500,
};
const END_MESSAGE_STATUS_CODES = [
    SUCCESS_STATUS_CODE.ALL,
    SUCCESS_STATUS_CODE.DATA,
    ...Object.values(FAILURE_STATUS_CODE),
];
let ws;
const useRequester = () => {
    return ({ route, body }) => {
        if (!ws)
            throw new Error('[useRequester] There is no active connection to the server.');
        const request = { route, ...(body || {}) };
        ws.send(JSON.stringify(request));
    };
};
const useDataChannel = (location, targetDataRef) => {
    const [payloadChunk, setPayloadChunk] = useState({});
    const [payload, setPayload] = useState({
        user: useSelector((state) => state.user),
        page: useSelector((state) => state.page),
    });
    const [isConnected, setIsConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [shouldReconnect, setShouldReconnect] = useState(true);
    const [hasReceivedEndMessage, setHasReceivedEndMessage] = useState(false);
    const [hasNoData, setHasNoData] = useState(false);
    const [isNavLoading, setIsNavLoading] = useState(true);
    const notification = useNotification();
    const dispatch = useDispatch();
    const requester = useRequester();
    const history = useHistory();
    let pingInterval;
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const updatePayload = (chunk) => {
        // 1. when value is array, take the incoming array
        // 2. when key is `page`, take the incoming value entirely
        const accumulatedPayload = merge(payload, chunk, {
            arrayMerge: (_destArray, srcArray) => srcArray,
            customMerge: key => {
                if (key === 'page')
                    return (_a, b) => b;
            },
        });
        setPayload(accumulatedPayload);
    };
    const init = (reconnect) => {
        ws = new WebSocket(`wss://${window.location.host}/api/service`);
        ws.onopen = () => {
            notification.hideAll();
            setIsConnected(true);
            const hasReconnectSucceeded = reconnectAttempts > 0;
            if (hasReconnectSucceeded) {
                setReconnectAttempts(0);
                setShouldReconnect(true);
            }
            const searchParams = location.search ? JSON.parse(decodeURI(location.search.substr(3))) : undefined;
            if (reconnect) {
                console.log(`DEBUG: ${reconnect}, reconnectAttempts: ${reconnectAttempts}`);
            }
            else {
                console.log(`DEBUG: non reconnect init ${location.pathname}, reconnectAttempts: ${reconnectAttempts}`);
            }
            requester({
                route: location.pathname,
                body: searchParams,
            });
            // NOTE: We implement our own custom ping message due to play framework doesn't implement websocket automatic ping option
            // See https://www.playframework.com/documentation/2.8.x/ScalaWebSockets#Keeping-a-WebSocket-Alive
            const PING_TIME_INTERVAL = 30000;
            pingInterval = window.setInterval(() => {
                ws.send(JSON.stringify({ ping: true }));
            }, PING_TIME_INTERVAL);
        };
        ws.onmessage = msg => {
            const incomingPayloadChunk = JSON.parse(msg.data);
            if (incomingPayloadChunk.status.code >= 400)
                notification.show(incomingPayloadChunk.status.message, { type: 'error' });
            if (incomingPayloadChunk.status.code === SUCCESS_STATUS_CODE.UPDATE && incomingPayloadChunk.page) {
                const url = constructUrlForArbitraryPage(incomingPayloadChunk.page);
                history.push(url);
            }
            else {
                setPayloadChunk(incomingPayloadChunk);
            }
        };
        ws.onclose = () => {
            setIsConnected(false);
            if (shouldReconnect) {
                setReconnectAttempts(attempts => attempts + 1);
            }
            window.clearInterval(pingInterval);
        };
    };
    const closeWs = () => {
        // ws is being closed deliberately, therefore all its listeners are removed
        // specifically, we don't want onClose listener to kick in and show an error message
        ws.onopen = null;
        ws.onclose = null;
        ws.onmessage = null;
        ws.onerror = null;
        ws.close();
    };
    useEffect(() => {
        init();
        return closeWs;
    }, []);
    useEffect(() => {
        const reconnect = async () => {
            const MAX_RECONNECT_ATTEMPTS = 5;
            const RECONNECT_INTERVAL_MS = 2000;
            if (reconnectAttempts <= MAX_RECONNECT_ATTEMPTS) {
                await sleep(RECONNECT_INTERVAL_MS);
                closeWs();
                init(`reconnecting ${location.pathname}`);
            }
            else {
                setReconnectAttempts(0);
            }
        };
        if (reconnectAttempts > 0)
            reconnect();
    }, [reconnectAttempts]);
    useEffect(() => {
        payloadChunk && updatePayload(payloadChunk);
        if (payloadChunk.status && END_MESSAGE_STATUS_CODES.includes(payloadChunk.status.code)) {
            setHasReceivedEndMessage(true);
        }
        if (payloadChunk.page ||
            (payloadChunk.status && Object.values(FAILURE_STATUS_CODE).includes(payloadChunk.status.code))) {
            setIsNavLoading(false);
        }
    }, [payloadChunk]);
    useEffect(() => {
        setHasNoData(hasReceivedEndMessage && !deepFind(payload, targetDataRef));
    }, [hasReceivedEndMessage]);
    useEffect(() => {
        if (payload.page)
            dispatch({ type: SET_PAGE, payload: payload.page });
        if (payload.user)
            dispatch({ type: SET_USER, payload: payload.user });
    }, [payload]);
    return {
        payload,
        hasNoData,
        hasReceivedEndMessage,
        isNavLoading,
        isConnected,
    };
};
export { useDataChannel, useRequester };
