import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        codeTitle: {
            marginBottom: factoryTheme.spacing(5),
        },
        codeScript: {
            marginBottom: factoryTheme.spacing(5),
        },
    });
});
