import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        repositories: {
            marginBottom: factoryTheme.spacing(6),
        },
        activeRepoEntry: {
            display: 'flex',
            alignItems: 'center',
        },
        activeRepoSelect: {
            flexGrow: 1,
            marginRight: factoryTheme.spacing(4),
        },
        markAsActiveBtn: {
            width: 74,
        },
        installationLink: {
            width: 'fit-content',
            color: `${factoryTheme.palette.purple[100]} !important`,
            fontSize: factoryTheme.typography.fontSize.smallest,
            textDecoration: 'underline !important',
            display: 'block',
            padding: factoryTheme.spacing(1, 2),
        },
        firstTitle: {
            marginBottom: factoryTheme.spacing(4),
        },
        title: {
            marginTop: factoryTheme.spacing(4),
            marginBottom: factoryTheme.spacing(4),
        },
    });
});
