import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select from '../Select';
import Menu from '../../lib/BaseMenu';
import MenuItem from '../../lib/BaseMenu/item';
import Label from '../../lib/BaseLabel';
import { formatDateTime } from '../../../helpers';
import Tooltip from '../../lib/BaseTooltip';
import Time from '../BaseTime';
import { useStyles } from './styles';
const SummaryAutomation = ({ summary, title, onRunChange, onRerun, onSshCopy }) => {
    const ownClasses = useStyles();
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement("div", { className: ownClasses.title }, title),
        React.createElement("div", { className: ownClasses.details },
            summary.run && (React.createElement("div", { className: ownClasses.detail },
                React.createElement(Label, { width: 35 }, summary.run))),
            summary.select && (React.createElement("div", { className: ownClasses.detail },
                React.createElement(Select, Object.assign({}, summary.select, { minWidth: 50, onChange: newRun => onRunChange && onRunChange(parseInt(newRun)) })))),
            summary.status && (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-tip": true, "data-for": "status", className: ownClasses.detail },
                    React.createElement(Label, { width: 80, classes: { label: ownClasses[summary.status], text: ownClasses.statusText } }, summary.status)),
                React.createElement(Tooltip, { id: "status" }, "Status"))),
            summary.started && (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-tip": true, "data-for": "started", className: ownClasses.detail },
                    React.createElement(Label, { width: 165 }, formatDateTime(summary.started))),
                React.createElement(Tooltip, { id: "started" }, "Started"),
                React.createElement("div", { "data-tip": true, "data-for": "duration", className: ownClasses.detail },
                    React.createElement(Label, { width: 115 }, React.createElement(Time, { started: summary.started, ended: summary.ended }))),
                React.createElement(Tooltip, { id: "duration" }, "Duration"))),
            summary.menu && (React.createElement("div", { className: ownClasses.detail },
                React.createElement(Menu, { toggler: React.createElement("div", { className: ownClasses.menuToggler },
                        React.createElement(Icon, { icon: ['fas', 'caret-down'] })) },
                    summary.menu.rerun && (React.createElement(MenuItem, { onClick: onRerun },
                        React.createElement("p", { className: ownClasses.menuItemText }, "Rerun"))),
                    summary.menu.copySsh && (React.createElement("span", { "data-tip": true, "data-for": "copySsh" },
                        React.createElement(MenuItem, { disabled: summary.menu.copySsh.disabled, onClick: onSshCopy },
                            React.createElement("p", { className: ownClasses.menuItemText }, "Copy SSH command")),
                        summary.menu.copySsh.tooltip && (React.createElement(Tooltip, { id: "copySsh" }, summary.menu.copySsh.tooltip))))))))));
};
export default SummaryAutomation;
