import React from 'react';
import clsx from 'clsx';
import Box from '../../common/ui/lib/BaseBox';
import Title from '../../common/ui/lib/BaseTitle';
import CommitSha from '../../common/ui/components/CommitSha';
import Summary from '../../common/ui/components/Summary';
import SummaryRow from '../../common/ui/components/Summary/row';
import SummaryCol from '../../common/ui/components/Summary/col';
import StatusTile from '../../common/ui/components/StatusTile';
import { PipelineName } from '../../Pipeline/common/types';
import { useStyles, usePipelineSummaryStyles } from './styles';
const PipelineSummary = ({ title, pipeline }) => {
    const ownClasses = usePipelineSummaryStyles();
    const compareAutomations = (a, b) => {
        if (a.index < b.index)
            return -1;
        if (a.index > b.index)
            return 1;
        return 0;
    };
    return (React.createElement(Summary, null, pipeline && (React.createElement(SummaryRow, { title: title }, Object.values(pipeline.workflows)
        .sort(compareAutomations)
        .map(workflow => workflow.jobs && (React.createElement(SummaryCol, { key: `build-${workflow.index}`, dynamicStyle: { flexGrow: Math.max(Object.entries(workflow.jobs).length, 1) } }, Object.values(workflow.jobs)
        .sort(compareAutomations)
        .map(job => (React.createElement(StatusTile, { key: job.index, status: job.status, classes: { root: ownClasses.statusTile } }))))))))));
};
const SumaryCommit = ({ title, url, pipelines, commitSymbol, shouldHidePipelineRelease, configuration, }) => {
    const ownClasses = useStyles();
    return (React.createElement(Box, { classes: { root: clsx(ownClasses.root, { [ownClasses.boxInvalid]: configuration?.status === 'invalid' }) }, url: url, header: React.createElement(Title, { type: "h1" },
            React.createElement(CommitSha, { classes: { root: ownClasses.commitSha }, commitSymbol: commitSymbol, isNotLinked: true }),
            title) },
        Object.keys(pipelines).length > 0 && (React.createElement(Title, { classes: { root: ownClasses.summaryTitle }, type: "h2", hasLine: true }, "Pipelines")),
        ['Build', 'Release']
            .filter(pipeline => !(shouldHidePipelineRelease && pipeline === PipelineName.Release))
            .map((title) => {
            if (pipelines[title.toLocaleLowerCase()]) {
                return (React.createElement(PipelineSummary, { key: title, title: title, pipeline: pipelines[title.toLocaleLowerCase()] }));
            }
        })));
};
export default SumaryCommit;
