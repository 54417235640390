import React, { useRef, useState } from 'react';
import { getTime } from 'date-fns';
import * as PIXI from 'pixi.js';
import { useStyles } from '../SingleIterationLineChart/style';
import { useChart, useChartRenderer } from '../mixins';
import { CHART_CONFIG } from '../config';
import { createBackground, createHoverOverlayText, drawDashedPolygon, getLabels, getTextSize, removeChildIfExists, getMap, createHorizontalAxis, } from '../utils';
import { getShortNotation, roundOneDecimal } from '../../../common/utils';
const TreeChart = ({ width, height, data }) => {
    const ownClasses = useStyles();
    const { rootRef, overlayRef, canvasRef, stage, setStage } = useChart();
    const traceTooltipWrapper = useRef(null);
    const [hoveredTrace, setHoveredTrace] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const calculateDataMap = (data) => {
        let min = Number.MAX_VALUE;
        let max = Number.MIN_VALUE;
        const processTree = (tree) => {
            min = Math.min(min, getTime(new Date(tree.started)));
            max = Math.max(max, getTime(new Date(tree.ended)));
            if (tree.traces) {
                Object.entries(tree.traces).forEach(([, subTree]) => processTree(subTree));
            }
        };
        processTree(data);
        const startTime = min;
        const durationRange = [0, max - min];
        const leftPadding = CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.TREE.PADDING.HORIZONTAL;
        const rightPadding = CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT + CHART_CONFIG.CHART.TREE.PADDING.HORIZONTAL;
        const [mapDuration, mapDurationInverse] = getMap(durationRange, [leftPadding, width - rightPadding]);
        return { durationRange, mapDuration, mapDurationInverse, startTime };
    };
    const render = (data, mouseX, mouseY) => {
        const { durationRange, mapDuration, mapDurationInverse, startTime } = calculateDataMap(data);
        const updateBackground = () => {
            // eslint-disable-next-line no-use-before-define
            if (!stage.getChildByName(CHART_CONFIG.LAYER.BACKGROUND.NAME)) {
                const background = createBackground(width, height);
                background.name = CHART_CONFIG.LAYER.BACKGROUND.NAME;
                background.zIndex = CHART_CONFIG.LAYER.BACKGROUND.Z_INDEX;
                // eslint-disable-next-line no-use-before-define
                stage.addChild(background);
            }
        };
        const updateGraph = (data) => {
            const createGraph = (data) => {
                const processTree = (tree) => {
                    const result = {};
                    result.text = tree.name;
                    result.duration = tree.duration;
                    result.left = mapDuration(getTime(new Date(tree.started)) - startTime);
                    result.right = mapDuration(getTime(new Date(tree.ended)) - startTime);
                    result.children = [];
                    if (tree.traces) {
                        Object.entries(tree.traces).forEach(([, subTree]) => {
                            const subResult = processTree(subTree);
                            result.children.push(subResult);
                        });
                    }
                    result.children.sort((x, y) => y.right - x.right);
                    result.children.forEach((x) => {
                        x.parent = result;
                    });
                    return result;
                };
                const tree = processTree(data);
                tree.text = data.path;
                const lineContainer = new PIXI.Container();
                const rectContainer = new PIXI.Container();
                const textContainer = new PIXI.Container();
                const edge = [];
                const renderTree = (tree, level) => {
                    const { left } = tree;
                    const width = Math.max(tree.right - tree.left, CHART_CONFIG.CHART.TREE.RECT.MIN_WIDTH);
                    const right = left + width;
                    const parentLevel = level - 1;
                    let currLevel = level;
                    while (edge[currLevel] && right > edge[currLevel]) {
                        currLevel++;
                    }
                    edge[currLevel] = left;
                    const top = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP +
                        CHART_CONFIG.CHART.TREE.PADDING.VERTICAL +
                        currLevel * CHART_CONFIG.CHART.TREE.LEVEL_HEIGHT;
                    tree.graphLine = null;
                    if (parentLevel >= 0) {
                        tree.graphLine = new PIXI.Graphics();
                        tree.graphLine.alpha = CHART_CONFIG.CHART.TREE.RECT.ALPHA;
                        tree.graphLine.lineStyle(CHART_CONFIG.CHART.TREE.LINE.STYLE);
                        tree.graphLine.moveTo(tree.left, top);
                        tree.graphLine.lineTo(tree.left, CHART_CONFIG.CHART.PADDING.VERTICAL.TOP +
                            CHART_CONFIG.CHART.TREE.PADDING.VERTICAL +
                            parentLevel * CHART_CONFIG.CHART.TREE.LEVEL_HEIGHT +
                            CHART_CONFIG.CHART.TREE.RECT.HEIGHT);
                        if (tree.parent.right < tree.left)
                            tree.graphLine.lineTo(tree.parent.right, CHART_CONFIG.CHART.PADDING.VERTICAL.TOP +
                                CHART_CONFIG.CHART.TREE.PADDING.VERTICAL +
                                parentLevel * CHART_CONFIG.CHART.TREE.LEVEL_HEIGHT +
                                CHART_CONFIG.CHART.TREE.RECT.HEIGHT);
                        lineContainer.addChild(tree.graphLine);
                    }
                    let { text } = tree;
                    let [textWidth] = getTextSize(text, CHART_CONFIG.CHART.TREE.TEXT.STYLE);
                    if (textWidth > width - 2 * CHART_CONFIG.CHART.TREE.TEXT.PADDING.HORIZONTAL) {
                        do {
                            text = `${text.replace('...', '').slice(0, -1)}...`;
                            [textWidth] = getTextSize(text, CHART_CONFIG.CHART.TREE.TEXT.STYLE);
                        } while (textWidth > width - CHART_CONFIG.CHART.TREE.TEXT.PADDING.HORIZONTAL * 2);
                    }
                    tree.graphText = new PIXI.Text(text, CHART_CONFIG.CHART.TREE.TEXT.STYLE);
                    tree.graphText.alpha = CHART_CONFIG.CHART.TREE.TEXT.ALPHA;
                    tree.graphText.x = left + CHART_CONFIG.CHART.TREE.TEXT.PADDING.HORIZONTAL;
                    tree.graphText.y = top + CHART_CONFIG.CHART.TREE.TEXT.PADDING.VERTICAL;
                    textContainer.addChild(tree.graphText);
                    tree.graphRect = new PIXI.Graphics();
                    tree.graphRect.alpha = CHART_CONFIG.CHART.TREE.RECT.ALPHA;
                    tree.graphRect.lineStyle(CHART_CONFIG.CHART.TREE.LINE.STYLE);
                    tree.graphRect.drawRect(left, top, width, CHART_CONFIG.CHART.TREE.RECT.HEIGHT);
                    tree.graphRect.beginFill(CHART_CONFIG.CHART.TREE.RECT.COLOR);
                    tree.graphRect.drawRect(left, top, width, CHART_CONFIG.CHART.TREE.RECT.HEIGHT);
                    tree.graphRect.endFill();
                    tree.graphRect.interactive = true;
                    tree.graphRect.hitArea = new PIXI.Rectangle(left, top, width, CHART_CONFIG.CHART.TREE.RECT.HEIGHT);
                    tree.graphRect.mouseover = (event) => {
                        // @ts-ignore
                        const { clientX: x, clientY: y } = event.data.originalEvent;
                        setHoveredTrace(`${tree.text}: ${tree.duration}ms`);
                        setTooltipPosition({ x: x, y: y - 30 });
                        let node = tree;
                        while (node) {
                            Object.assign(node.graphRect.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.TREE.LINE.FOCUS_STYLE);
                            node.graphRect.geometry.invalidate();
                            if (node.graphLine) {
                                Object.assign(node.graphLine.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.TREE.LINE.FOCUS_STYLE);
                                node.graphLine.geometry.invalidate();
                            }
                            node = node.parent;
                        }
                    };
                    tree.graphRect.mousemove = (event) => {
                        // @ts-ignore
                        const { clientX: x, clientY: y } = event.data.originalEvent;
                        setTooltipPosition({ x: x, y: y - 30 });
                    };
                    tree.graphRect.mouseout = () => {
                        setHoveredTrace(null);
                        let node = tree;
                        while (node) {
                            Object.assign(node.graphRect.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.TREE.LINE.STYLE);
                            node.graphRect.geometry.invalidate();
                            if (node.graphLine) {
                                Object.assign(node.graphLine.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.TREE.LINE.STYLE);
                                node.graphLine.geometry.invalidate();
                            }
                            node = node.parent;
                        }
                    };
                    rectContainer.addChild(tree.graphRect);
                    tree.children.forEach((subTree) => renderTree(subTree, currLevel + 1));
                };
                renderTree(tree, 0);
                const graph = new PIXI.Container();
                graph.sortableChildren = true;
                rectContainer.zIndex = 1;
                graph.addChild(rectContainer);
                lineContainer.zIndex = 2;
                graph.addChild(lineContainer);
                textContainer.zIndex = 1;
                graph.addChild(textContainer);
                return graph;
            };
            // eslint-disable-next-line no-use-before-define
            const lastGraph = stage.getChildByName(CHART_CONFIG.LAYER.GRAPH.NAME);
            if (!lastGraph || lastGraph.chart.data !== data) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.GRAPH.NAME);
                const graph = createGraph(data);
                graph.name = CHART_CONFIG.LAYER.GRAPH.NAME;
                graph.zIndex = CHART_CONFIG.LAYER.GRAPH.Z_INDEX;
                graph.chart = { data };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(graph);
            }
        };
        const updateCoordinateSystem = (data) => {
            const createChartCoordinateSystem = () => {
                const durationLabels = getLabels(...durationRange, CHART_CONFIG.COORDINATE_SYSTEM.TICK.COUNT.HORIZONTAL, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: getShortNotation(label), pos: mapDuration(label) }));
                const axis = createHorizontalAxis(width, height, durationLabels, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                return axis;
            };
            // eslint-disable-next-line no-use-before-define
            const lastCoordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (!lastCoordinateSystem || lastCoordinateSystem.chart.data !== data) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
                const coordinateSystem = createChartCoordinateSystem();
                coordinateSystem.name = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME;
                coordinateSystem.zIndex = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.Z_INDEX;
                coordinateSystem.chart = { data };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(coordinateSystem);
            }
        };
        const updateHoverOverlay = (data, mouseX, mouseY) => {
            const createHoverOverlay = () => {
                const hoverOverlay = new PIXI.Container();
                const lineVertical = new PIXI.Graphics();
                lineVertical.name = 'lineVertical';
                lineVertical.lineStyle(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE);
                drawDashedPolygon([
                    { x: 0, y: height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM },
                    { x: 0, y: CHART_CONFIG.CHART.PADDING.VERTICAL.TOP },
                ], lineVertical, 1, 2);
                hoverOverlay.addChild(lineVertical);
                const labelX = createHoverOverlayText();
                labelX.name = 'labelX';
                labelX.y =
                    height -
                        CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM -
                        CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.VERTICAL;
                hoverOverlay.addChild(labelX);
                return hoverOverlay;
            };
            // eslint-disable-next-line no-use-before-define
            const lastHoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (!lastHoverOverlay || lastHoverOverlay.chart.data !== data) {
                // eslint-disable-next-line no-use-before-define
                removeChildIfExists(stage, CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
                const hoverOverlay = createHoverOverlay();
                hoverOverlay.name = CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME;
                hoverOverlay.zIndex = CHART_CONFIG.LAYER.HOVER_OVERLAY.Z_INDEX;
                hoverOverlay.chart = { data, mouseX, mouseY };
                // eslint-disable-next-line no-use-before-define
                stage.addChild(hoverOverlay);
            }
            // eslint-disable-next-line no-use-before-define
            const hoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (mouseX >= CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT &&
                mouseY >= CHART_CONFIG.CHART.PADDING.VERTICAL.TOP &&
                mouseX <= width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT &&
                mouseY <= height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM) {
                hoverOverlay.alpha = 1;
                hoverOverlay.getChildByName('lineVertical').x = mouseX;
                hoverOverlay.getChildByName('labelX').x = mouseX;
                hoverOverlay.getChildByName('labelX').text = roundOneDecimal(mapDurationInverse(mouseX)).toString();
            }
            else {
                hoverOverlay.alpha = 0;
            }
        };
        updateBackground();
        updateGraph(data);
        updateCoordinateSystem(data);
        updateHoverOverlay(data, mouseX, mouseY);
    };
    const { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart } = useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, null, false, render);
    const handleMouseEnter = () => {
        swapInWebglCanvas();
        renderChart();
    };
    const handleMouseOut = () => {
        swapOutWebglCanvas();
        renderChart();
        copyChart();
    };
    const handleMouseMove = () => {
        renderChart();
    };
    return (React.createElement("div", { ref: rootRef, className: ownClasses.root },
        React.createElement("div", { ref: overlayRef, className: ownClasses.overlay, onMouseEnter: handleMouseEnter, onMouseOut: handleMouseOut, onMouseMove: handleMouseMove }),
        React.createElement("div", { className: ownClasses.tooltip, ref: traceTooltipWrapper, style: { left: tooltipPosition.x, top: tooltipPosition.y, display: hoveredTrace ? 'flex' : 'none' } }, hoveredTrace),
        React.createElement("canvas", { ref: canvasRef, width: width * window.devicePixelRatio, height: height * window.devicePixelRatio })));
};
export default TreeChart;
