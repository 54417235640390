import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { useItemStyles } from './styles';
const BaseMenuItem = forwardRef(({ children, onClick, value, disabled, width, classes }, ref) => {
    const ownClasses = useItemStyles();
    return (React.createElement(MenuItem, { onClick: onClick, classes: { root: clsx(ownClasses.root, classes?.root) }, ref: ref, value: value, style: { width }, disabled: disabled }, children));
});
BaseMenuItem.displayName = 'BaseMenuItem';
export default BaseMenuItem;
