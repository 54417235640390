import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
// const BaseInput: React.FC<BaseInputProps> = ({ classes, ...props }) => {
const BaseInput = forwardRef(({ classes, ...props }, ref) => {
    const ownClasses = useStyles();
    return React.createElement("input", Object.assign({ className: clsx(ownClasses.root, classes?.root) }, props, { ref: ref }));
});
BaseInput.displayName = 'BaseInput';
export default BaseInput;
