import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import Label from '../../../../common/ui/lib/BaseLabel';
import { cloneJSON } from '../../../../common/utils';
import SelectTree from '../../../../common/ui/components/SelectTree';
import Button from '../../../../common/ui/lib/BaseButton';
import NavigationLineBreak from '../../../../common/ui/components/Navigation/linebreak';
import { addOptionalSelectToEnd } from '../../helpers';
import { useStyles } from './styles';
const SelectTracePath = ({ paths, onPathChange, allowsMultiple }) => {
    const ownClasses = useStyles();
    const handlePathChangeInList = (indexOfPathToChange, newPathTree) => {
        const updatedPathList = cloneJSON(paths);
        updatedPathList[indexOfPathToChange] = addOptionalSelectToEnd(newPathTree, 'path');
        onPathChange(updatedPathList);
    };
    const handlePathClearInList = (indexOfPathToClear) => {
        const updatedPathList = cloneJSON(paths);
        updatedPathList[indexOfPathToClear] = { optional: true };
        onPathChange(updatedPathList);
    };
    const handlePathChange = (newPathTree) => {
        const updatedPaths = cloneJSON(paths);
        updatedPaths[0] = addOptionalSelectToEnd(newPathTree, 'path');
        onPathChange(updatedPaths);
    };
    const handlePathClear = () => {
        onPathChange([{ optional: true }]);
    };
    const handlePathRemove = (indexOfPathToRemove) => {
        const updatedPaths = cloneJSON(paths);
        updatedPaths.splice(indexOfPathToRemove, 1);
        onPathChange(updatedPaths);
    };
    const handlePathAdd = () => {
        const updatedPaths = cloneJSON(paths);
        updatedPaths.push({ optional: true });
        onPathChange(updatedPaths);
    };
    return (React.createElement(React.Fragment, null, allowsMultiple ? (paths.map((path, index) => (React.createElement("div", { key: index, className: ownClasses.pathLineContainer },
        React.createElement("div", { className: ownClasses.pathLine },
            React.createElement(Label, { classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText }, showEmptyCloseBtn: index === 0, onClose: () => handlePathRemove(index) }, "Trace Path"),
            React.createElement(SelectTree, { tree: path, selectProps: { optional: true }, treeRef: "path", classes: { root: ownClasses.item }, onChange: newPathTree => handlePathChangeInList(index, newPathTree), onClose: () => handlePathClearInList(index) }),
            index === paths.length - 1 && (React.createElement(Button, { onClick: handlePathAdd },
                React.createElement(Icon, { icon: ['fal', 'plus'] })))),
        index !== paths.length - 1 && React.createElement(NavigationLineBreak, null))))) : (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { showEmptyCloseBtn: true, classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText } }, "Trace Path"),
        React.createElement(SelectTree, { tree: paths[0], selectProps: { optional: true }, treeRef: "path", classes: { root: ownClasses.item }, onChange: newPathTree => handlePathChange(newPathTree), onClose: () => handlePathClear() })))));
};
export default SelectTracePath;
