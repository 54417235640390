import React from 'react';
import ButtonGroup from '../../../../common/ui/lib/BaseButtonGroup';
import Button from '../../../../common/ui/lib/BaseButton';
import { useStyles } from './styles';
const Switcher = ({ title, first, second, selected, onSwitch }) => {
    const ownClasses = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ownClasses.root },
            React.createElement("span", { className: ownClasses.title }, title),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { disabled: selected === first, onClick: () => onSwitch(first) }, first),
                React.createElement(Button, { disabled: selected === second, onClick: () => onSwitch(second) }, second)))));
};
export default Switcher;
