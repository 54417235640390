import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: '100%',
            minWidth: 22,
            borderRadius: `${factoryTheme.shape.borderRadius.small}px 0 0 ${factoryTheme.shape.borderRadius.small}px`,
            marginRight: 2,
            padding: 0,
            '&:hover': {
                borderColor: 'transparent',
                cursor: ({ isEmpty }) => (isEmpty ? 'default' : 'pointer'),
            },
            '&:hover svg': {
                color: factoryTheme.palette.green[200],
            },
        },
        icon: {
            color: factoryTheme.palette.white[200],
            fontSize: factoryTheme.typography.fontSize.smaller,
        },
    });
});
