import React, { useEffect, useState } from 'react';
import SummaryPipeline from '../../summary';
import Box from '../../../common/ui/lib/BaseBox';
import Divider from '../../../common/ui/lib/BaseDivider';
import WorkflowValidation from '../../../Workflow/types/Validation';
import WorkflowDeployment from '../../../Workflow/types/Deployment';
import WorkflowBroadcast from '../../../Workflow/types/Broadcast';
import { PipelineName } from '../../common/types';
import useSummary from '../../../common/services/summary/use';
import { constructRouteForReleaseApproval, constructUrlForPipelinePage } from '../../../common/helpers';
import Button from '../../../common/ui/lib/BaseButton';
import { useRequester } from '../../../common/services/dataChannel/use';
import { useEffectWithObjectDependencies } from '../../../common/utils';
import { useStyles } from './styles';
const PipelineRelease = props => {
    const { release, summaryWhitelist } = props;
    const requester = useRequester();
    const ownClasses = useStyles();
    const { selectedRun, summary, handleRunChange, handlePipelineRerun } = useSummary(release, summaryWhitelist);
    const workflowSummaryWhitelist = [
        'status',
        'started',
        'ended',
        'select',
        'menu',
    ];
    const [approval, setApproval] = useState({ ...release[selectedRun].approval });
    const [isApproved, setIsApproved] = useState(Boolean(approval?.['is-approved']));
    useEffect(() => {
        setApproval(release?.[selectedRun]?.approval);
    }, [release?.[selectedRun]?.approval]);
    const isReleasable = () => {
        if (!approval)
            throw new Error('[WorkflowValidation] There is no `approval` provided to assess if releasable.');
        const validationStatus = approval['validation-status'];
        return validationStatus !== 'running' && validationStatus !== 'error' && !isApproved;
    };
    const constructReleaseApprovalMsg = () => {
        if (!approval)
            throw new Error('[WorkflowValidation] There is no `approval` provided to construct the message for.');
        const validationStatus = approval['validation-status'];
        if (!['success', 'running', 'error', 'waiting'].includes(validationStatus))
            throw new Error(`[WorkflowValidation] Status '${validationStatus}' is not a valid status for the Validation Workflow.`);
        let msg = '';
        if (validationStatus === 'success') {
            msg = isApproved
                ? `Release ${release[selectedRun]?.['version-candidate']} has been approved.`
                : `Release ${release[selectedRun]?.['version-candidate']}`;
        }
        else if (['running', 'waiting'].includes(validationStatus))
            msg = `Build or Validation in progress.`;
        else if (validationStatus === 'error')
            msg = `Build or Validation has failed.`;
        return msg;
    };
    const [releaseApprovalMessage, setReleaseApprovalMessage] = useState(constructReleaseApprovalMsg());
    useEffectWithObjectDependencies(() => {
        if (approval && approval['is-approved'] != isApproved) {
            setIsApproved(approval['is-approved']);
        }
    }, [approval]);
    useEffectWithObjectDependencies(() => {
        setReleaseApprovalMessage(constructReleaseApprovalMsg());
    }, [approval, isApproved]);
    return (React.createElement(Box, { classes: { header: ownClasses.boxHeader, content: ownClasses.boxContent }, header: React.createElement(SummaryPipeline, { name: PipelineName.Release, summary: summary, url: constructUrlForPipelinePage(release[selectedRun].symbol), onRunChange: handleRunChange, onRerun: () => handlePipelineRerun(PipelineName.Release) }) },
        release[selectedRun].workflows.validation && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowValidation, { classes: { root: ownClasses.workflowBox }, validation: release[selectedRun].workflows.validation, approval: { ...release[selectedRun].approval }, version: release[selectedRun]?.['version-candidate'], summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider }))),
        { ...release[selectedRun].approval } && (React.createElement(Button, { type: "primary", classes: { root: ownClasses.approvalButton }, disabled: !isReleasable(), onClick: async () => {
                setIsApproved(true);
                requester({
                    route: constructRouteForReleaseApproval(release[selectedRun].symbol),
                });
            } }, releaseApprovalMessage)),
        release[selectedRun].workflows.deployment && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowDeployment, { classes: { root: ownClasses.workflowBox }, deployment: release[selectedRun].workflows.deployment, summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider }))),
        release[selectedRun].workflows.broadcast && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowBroadcast, { classes: { root: ownClasses.workflowBox }, broadcast: release[selectedRun].workflows.broadcast, summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider })))));
};
export default PipelineRelease;
