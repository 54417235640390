import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import Title from '../../../common/ui/lib/BaseTitle';
import TitleTraceCommit from '../../common/ui/TitleTraceCommit';
import TrendChart from '../../charts/TrendChart';
import LineChart from '../../charts/LineChart';
import { CHART_CONFIG } from '../../charts/config';
import { getEngine } from '../../charts/engine';
import { groupBy, percentile } from '../../../common/utils';
import Switcher from '../../common/ui/Switcher';
import { useStyles } from './styles';
const PerformanceGroups = ({ data, pathList, ranges, onIdChange, onTrackerChange, }) => {
    const ownClasses = useStyles();
    const [selectedScales, setSelectedScales] = useState({});
    const [selectedCharts, setSelectedCharts] = useState({});
    const [orderedPathList, setOrderedPathList] = useState([]);
    const [chartWidth, setChartWidth] = useState(0);
    const [chartHeight, setChartHeight] = useState(0);
    const containerRef = useRef(null);
    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        const _chartWidth = containerRef.current.clientWidth;
        // eslint-disable-next-line no-underscore-dangle
        const _chartHeight = CHART_CONFIG.CHART.HEIGHT;
        setChartWidth(_chartWidth);
        setChartHeight(_chartHeight);
        getEngine(_chartWidth, _chartHeight);
    }, []);
    const getOrderedPathList = () => {
        if (!data?.['trace-groups'])
            return [];
        return pathList || Object.keys(data['trace-groups']);
    };
    // const handleClick = (path: any) => {
    //     pathChange([{ selected: path, path: { optional: true } }]);
    // };
    const trendChartData = (data) => {
        const tracesByIteration = groupBy(Object.values(data.traces), (x) => x.iteration);
        const iterations = Object.keys(tracesByIteration).sort((x, y) => Number(x) - Number(y));
        const durationsByIteration = {};
        iterations.forEach(iteration => {
            durationsByIteration[iteration] = tracesByIteration[iteration]
                .map((x) => x.duration)
                .sort((a, b) => a - b);
        });
        const result = {};
        const percentiles = CHART_CONFIG.CHART.TREND.CONFIG.map(x => x.PERCENT);
        percentiles.forEach(percent => {
            const percentileData = {};
            iterations.forEach(iteration => {
                percentileData[iteration] = percentile(durationsByIteration[iteration], Number(percent));
            });
            result[percent] = percentileData;
        });
        return result;
    };
    const lineChartData = (data) => {
        const tracesByTrackerIteration = groupBy(Object.values(data.traces), (x) => x.tracker, (x) => x.iteration);
        const lines = [];
        Object.entries(tracesByTrackerIteration).forEach(([tracker, data]) => {
            Object.entries(data).forEach(([iteration, items]) => {
                data[iteration] = items.map((x) => ({ duration: x.duration, id: x.id }));
            });
            lines.push({ tracker, data });
        });
        return lines;
    };
    const getScale = (path) => {
        return selectedScales[path] || 'Linear';
    };
    const getChart = (path) => {
        return selectedCharts[path] || 'Trend';
    };
    useEffect(() => {
        setOrderedPathList(getOrderedPathList());
    }, [data, pathList]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: containerRef },
            data?.commit && (React.createElement(TitleTraceCommit, { data: data?.commit, id: data?.id, classes: { root: ownClasses.title }, name: data?.name, created: data?.created })),
            orderedPathList.map((path) => {
                return (React.createElement(Box, { classes: { root: ownClasses.box }, key: path, header: React.createElement(React.Fragment, null,
                        React.createElement(Title, { type: "h1" },
                            "Traces for ",
                            path),
                        React.createElement(Switcher, { title: "Scale:", first: "Linear", second: "Log", selected: getScale(path), onSwitch: scale => {
                                setSelectedScales({ ...selectedScales, [path]: scale });
                            } }),
                        React.createElement(Switcher, { title: "Chart:", first: "Trend", second: "Line", selected: getChart(path), onSwitch: chart => {
                                setSelectedCharts({ ...selectedCharts, [path]: chart });
                            } })) },
                    getChart(path) === 'Trend' && (React.createElement(TrendChart, { width: chartWidth, height: chartHeight, data: trendChartData(data?.['trace-groups'][path]), isLogScaled: getScale(path) === 'Log', ranges: {
                            iterationRange: ranges.iterationRange,
                            durationRange: ranges.durationRange[path],
                        }, switchToLine: () => {
                            setSelectedCharts({ ...selectedCharts, [path]: 'Line' });
                        } })),
                    getChart(path) === 'Line' && (React.createElement(LineChart, { width: chartWidth, height: chartHeight, data: lineChartData(data?.['trace-groups'][path]), isLogScaled: getScale(path) === 'Log', ranges: {
                            iterationRange: ranges.iterationRange,
                            durationRange: ranges.durationRange[path],
                        }, onIdChange: (id) => onIdChange(id), onTrackerChange: (id) => onTrackerChange(id) }))));
            }))));
};
PerformanceGroups.displayName = 'PerformanceGroups';
export default PerformanceGroups;
