import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        input: {
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationA,
        },
        option: {
            fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationA,
        },
    });
});
