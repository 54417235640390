import clsx from 'clsx';
import React, { useState } from 'react';
import yup from '../../../../common/validator';
import Label from '../../../../common/ui/lib/BaseLabel';
import GuideInput from '../../../../common/ui/components/GuideInput';
import Input from '../../../../common/ui/lib/BaseInput';
import ButtonClose from '../../../../common/ui/components/ButtonClose';
import { useStyles } from './styles';
const schema = yup.object({
    id: yup.string().label('Trace ID').uuid(),
});
const InputTraceId = ({ id, onIdChange }) => {
    const [controlledId, setControlledId] = useState(id || '');
    const [inputHint, setInputHint] = useState();
    const [inputError, setInputError] = useState();
    const ownClasses = useStyles();
    const handleIdInputChange = (event) => {
        setControlledId(event.target.value);
    };
    const submitRangeText = (inputEl, enteredId) => {
        schema
            .validate({ id: enteredId })
            .then(() => {
            inputEl.blur();
            onIdChange(controlledId);
            setInputError('');
        })
            .catch(e => setInputError(e.message));
        setInputHint('');
    };
    const handleIdInputKeyUp = (event) => {
        setInputHint('Hit enter to apply');
        setInputError('');
        if (event.key === 'Enter') {
            submitRangeText(event.currentTarget, controlledId);
        }
    };
    const handleIdInputBlur = () => {
        setInputHint('');
        setInputError('');
        setControlledId(controlledId);
        if (controlledId === '' && id !== '') {
            onIdChange('');
        }
    };
    const handleInputIdClear = () => {
        if (id === '')
            setControlledId('');
        else
            onIdChange('');
    };
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { showEmptyCloseBtn: true, classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText } }, "Trace ID"),
        React.createElement(ButtonClose, { onClose: handleInputIdClear, isEmpty: controlledId === '' }),
        React.createElement(Input, { type: "text", classes: { root: ownClasses.inputId }, value: controlledId, onChange: handleIdInputChange, onKeyUp: handleIdInputKeyUp, onBlur: handleIdInputBlur }),
        React.createElement(GuideInput, { error: inputError, hint: inputHint })));
};
export default InputTraceId;
