import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            'line-height': 0,
            position: 'relative',
        },
        overlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
        tooltip: {
            backgroundColor: factoryTheme.palette.purple[400],
            position: 'fixed',
            width: 'auto',
            alignItems: 'center',
            padding: 12,
            transform: 'translate(-50%, 0)',
        },
    });
});
