import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';
import { createFactoryStyles } from '../../styles/helpers';
const getTypeColorMap = (factoryTheme) => ({
    primary: {
        label: factoryTheme.palette.purple[500],
        background: factoryTheme.palette.green[200],
        hoverBorder: 'transparent',
        hoverLabel: factoryTheme.palette.purple[500],
        disabledBackground: Color(factoryTheme.palette.green[200]).alpha(0.2).string(),
        disabledLabel: factoryTheme.palette.purple[500],
    },
    secondary: {
        label: factoryTheme.palette.green[200],
        background: factoryTheme.palette.purple[400],
        hoverBorder: factoryTheme.palette.green[200],
        hoverLabel: factoryTheme.palette.green[200],
        disabledBackground: Color(factoryTheme.palette.purple[400]).alpha(0.6).string(),
        disabledLabel: Color(factoryTheme.palette.green[200]).alpha(0.6).string(),
    },
    danger: {
        label: factoryTheme.palette.red[100],
        background: factoryTheme.palette.purple[400],
        hoverBorder: factoryTheme.palette.red[100],
        hoverLabel: factoryTheme.palette.red[100],
        disabledBackground: Color(factoryTheme.palette.purple[400]).alpha(0.6).string(),
        disabledLabel: Color(factoryTheme.palette.green[200]).alpha(0.6).string(),
    },
});
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: (props) => (props.size === 'large' ? 'auto' : 24),
            border: '1px solid transparent',
            padding: factoryTheme.spacing(0, 2),
            borderRadius: factoryTheme.shape.borderRadius.small,
        },
        label: {
            color: (props) => getTypeColorMap(factoryTheme)[props.type].label,
            fontSize: (props) => factoryTheme.typography.fontSize[props.size],
            fontWeight: (props) => (props.type === 'primary' ? 600 : 400),
            lineHeight: '14px',
            '& p, & svg': {
                lineHeight: '14px',
            },
        },
        variantContained: {
            backgroundColor: (props) => getTypeColorMap(factoryTheme)[props.type].background,
            '&:hover': {
                borderColor: (props) => getTypeColorMap(factoryTheme)[props.type].hoverBorder,
                '& > $label': {
                    color: (props) => getTypeColorMap(factoryTheme)[props.type].hoverLabel,
                },
            },
            '&.Mui-disabled': {
                backgroundColor: (props) => getTypeColorMap(factoryTheme)[props.type].disabledBackground,
                cursor: 'not-allowed',
                '& > $label': {
                    color: (props) => getTypeColorMap(factoryTheme)[props.type].disabledLabel,
                },
                '&:hover': {
                    borderColor: 'transparent',
                },
            },
        },
    }, {
        root: {
            minWidth: 'auto',
        },
        label: {
            textTransform: 'none',
        },
    });
});
