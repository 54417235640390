import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
const GuideInput = ({ error, hint }) => {
    const ownClasses = useStyles();
    return (React.createElement(React.Fragment, null,
        error && React.createElement("p", { className: clsx(ownClasses.guide, ownClasses.error) }, error),
        hint && React.createElement("p", { className: clsx(ownClasses.guide, ownClasses.hint) }, hint)));
};
export default GuideInput;
