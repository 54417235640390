import React, { useEffect, useRef } from 'react';
import './styles.scss';
const ScaleNormalizeSwitcher = ({ showComparison, normalisationTypeChange }) => {
    const radioSwitchContainer = useRef(null);
    const INITIAL_TOP = 80;
    const handleScroll = () => {
        // @ts-ignore
        radioSwitchContainer.current.style.top = `${Math.max(INITIAL_TOP - window.scrollY, 20)}px`;
    };
    useEffect(() => {
        // @ts-ignore
        radioSwitchContainer.current.style.top = `${INITIAL_TOP}px`;
        window.addEventListener('scroll', handleScroll);
    }, []);
    return (React.createElement("div", { className: 'radio-switch-container', style: { width: showComparison ? 1600 : 1000 }, ref: radioSwitchContainer },
        React.createElement("div", { className: 'radio-switch' },
            React.createElement("div", { className: 'switcher-title' }, "Normalise"),
            React.createElement("div", { className: 'switcher-content' },
                React.createElement("label", { className: 'radio-container' },
                    React.createElement("input", { type: "radio", name: "radio", defaultChecked: true, onChange: () => normalisationTypeChange('none') }),
                    React.createElement("span", { className: 'checkmark' }),
                    React.createElement("div", { className: 'switcher-label' }, "None")),
                showComparison && (React.createElement("label", { className: 'radio-container' },
                    React.createElement("input", { type: "radio", name: "radio", onChange: () => normalisationTypeChange('comparison') }),
                    React.createElement("span", { className: 'checkmark' }),
                    React.createElement("div", { className: 'switcher-label' }, "Comparison"))),
                React.createElement("label", { className: 'radio-container' },
                    React.createElement("input", { type: "radio", name: "radio", onChange: () => normalisationTypeChange('all') }),
                    React.createElement("span", { className: 'checkmark' }),
                    React.createElement("div", { className: 'switcher-label' }, "All"))))));
};
export default ScaleNormalizeSwitcher;
