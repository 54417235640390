import { useSelector } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import React from 'react';
import LogoFactory from '../../assets/images/LogoFactory.svg';
import Menu from '../../lib/BaseMenu';
import MenuItem from '../../lib/BaseMenu/item';
import Button from '../../lib/BaseButton';
import { isUserAuthenticated } from '../../../../index/Router/helpers';
import { useRequester } from '../../../services/dataChannel/use';
import ConnectionIndicator from '../../components/ConnectionIndicator';
import { removeCookie } from './utils';
import { useHeaderStyles } from './styles';
const PageHeader = ({ isConnected }) => {
    const ownClasses = useHeaderStyles();
    const location = useLocation();
    const history = useHistory();
    const requester = useRequester();
    const user = useSelector((state) => state.user);
    const handleLogout = () => {
        requester({ route: 'logout' });
        removeCookie('GithubToken');
        history.go(0);
    };
    return (React.createElement(Container, { className: ownClasses.root },
        React.createElement(Grid, { className: ownClasses.content, container: true, alignItems: "center", justify: "space-between" },
            React.createElement(Link, { to: "/" },
                React.createElement(LogoFactory, { className: ownClasses.logo })),
            React.createElement(ConnectionIndicator, { isConnected: isConnected, classes: { root: ownClasses.connectionIndicator } }),
            isUserAuthenticated && user['full-name'] && user.username ? (React.createElement(Menu, { preventTogglerRotate: true, toggler: React.createElement(Button, { variant: "text", classes: {
                        root: ownClasses.menuToggler,
                    } },
                    user['full-name'],
                    React.createElement("img", { className: ownClasses.menuAvatar, src: `https://avatars.githubusercontent.com/${user.username}` })) },
                React.createElement(Link, { to: "/settings" },
                    React.createElement(MenuItem, { classes: { root: ownClasses.profileMenuItem } },
                        React.createElement("p", null, "Settings"),
                        React.createElement(Icon, { icon: ['fal', 'user-cog'] }))),
                React.createElement(MenuItem, { onClick: handleLogout, classes: { root: ownClasses.profileMenuItem } },
                    React.createElement("p", null, "Logout"),
                    React.createElement(Icon, { icon: ['fal', 'sign-out'] })))) : (React.createElement(Button, { classes: { root: ownClasses.loginBtn }, variant: "text", href: `https://${window.location.host}/api/login?path=${location.pathname}` },
                React.createElement("div", { className: ownClasses.loginBtnContent },
                    React.createElement("p", null, "Login"),
                    React.createElement(Icon, { icon: ['fal', 'sign-in'] })))))));
};
export default PageHeader;
