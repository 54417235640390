import React, { useRef } from 'react';
import { ClickAwayListener, MenuList, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';
import { useMenuStyles } from './styles';
const BaseMenu = ({ children, toggler: Toggler, preventTogglerRotate }) => {
    const ownClasses = useMenuStyles();
    const anchorRef = useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const handleToggle = () => setIsOpen(prevIsOpen => !prevIsOpen);
    const handleClose = () => setIsOpen(false);
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement("div", { ref: anchorRef, onClick: handleToggle, className: clsx({ [ownClasses.openToggler]: isOpen && !preventTogglerRotate }) }, Toggler),
        React.createElement(Popper, { className: ownClasses.popper, open: isOpen, anchorEl: anchorRef.current, disablePortal: true, placement: "bottom-end" },
            React.createElement(Paper, null,
                React.createElement(ClickAwayListener, { onClickAway: handleClose },
                    React.createElement(MenuList, { classes: { root: ownClasses.list }, onClick: handleClose }, children))))));
};
export default BaseMenu;
