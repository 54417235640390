import { makeStyles } from '@material-ui/core';
import { hideScrollbar, createFactoryStyles } from '../../common/ui/styles/helpers';
const ownerAvatarWidth = 42;
const ownerButtonWidth = 276;
const button = (factoryTheme) => ({
    display: 'block',
    backgroundColor: factoryTheme.palette.purple[500],
    border: `1px solid ${factoryTheme.palette.purple[300]}`,
    borderRadius: 0,
    padding: factoryTheme.spacing(2, 3),
});
const buttonContent = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > p': {
        lineHeight: '20px',
    },
};
const buttonIcon = (factoryTheme) => ({
    borderRadius: '100%',
    marginBottom: factoryTheme.spacing(2),
});
const buttonLabel = (factoryTheme) => ({
    height: 'auto',
    color: factoryTheme.palette.purple[200],
});
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        logoContainer: {
            width: '100%',
            textAlign: 'center',
            padding: '10% 0',
        },
        logo: {
            width: 250,
            height: 235,
        },
        ownersGrid: {
            width: '100%',
            display: 'grid',
            columnGap: '80px',
            justifyContent: 'center',
            rowGap: '50px',
            marginBottom: factoryTheme.spacing(10),
            ...hideScrollbar(),
        },
        ownersGridOneCol: {
            gridTemplateColumns: `repeat(1, ${ownerButtonWidth}px)`,
        },
        ownersGridTwoCol: {
            gridTemplateColumns: `repeat(2, ${ownerButtonWidth}px)`,
        },
        ownersGridThreeCol: {
            gridTemplateColumns: `repeat(3, ${ownerButtonWidth}px)`,
        },
        ownerButton: {
            ...button(factoryTheme),
        },
        ownerButtonContent: {
            ...buttonContent,
        },
        ownerButtonLabel: {
            ...buttonLabel(factoryTheme),
        },
        ownerButtonAvatar: {
            ...buttonIcon(factoryTheme),
            width: ownerAvatarWidth,
            height: ownerAvatarWidth,
        },
        addButton: {
            ...button(factoryTheme),
        },
        addButtonContent: {
            ...buttonContent,
        },
        addButtonLabel: {
            ...buttonLabel(factoryTheme),
        },
        addButtonIcon: {
            ...buttonIcon(factoryTheme),
            fontSize: ownerAvatarWidth,
        },
        signInButton: {
            ...button(factoryTheme),
        },
        signInButtonContent: {
            ...buttonContent,
            '& svg': {
                borderRadius: 0,
            },
        },
        signInButtonLabel: {
            ...buttonLabel(factoryTheme),
        },
        signInButtonIcon: {
            ...buttonIcon(factoryTheme),
            fontSize: ownerAvatarWidth,
        },
    });
});
