import React from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import SummaryWorkflow from '../../summary';
import TableJobs from '../../../common/ui/components/TableJobs';
import TableAnalyzes from '../../../common/ui/components/TableAnalyzes';
import { WorkflowName } from '../../common/types';
import useSummary from '../../../common/services/summary/use';
import { constructUrlForWorkflowPage } from '../../../common/helpers';
import { useStyles } from './styles';
const WorkflowQuality = ({ quality, summaryWhitelist, classes }) => {
    const ownClasses = useStyles();
    const { selectedRun, summary, handleRunChange, handleWorkflowRerun } = useSummary(quality, summaryWhitelist);
    const analysis = quality?.[selectedRun]?.analysis ? quality[selectedRun].analysis : null;
    return (React.createElement(Box, { classes: { root: classes?.root, header: ownClasses.boxHeader, content: ownClasses.boxContent }, header: React.createElement(SummaryWorkflow, { title: `Workflow: ${WorkflowName.Quality}`, summary: summary, url: constructUrlForWorkflowPage(quality[selectedRun].symbol), onRunChange: handleRunChange, onRerun: () => handleWorkflowRerun(WorkflowName.Quality) }) },
        quality?.[selectedRun]?.jobs && React.createElement(TableJobs, { jobs: quality[selectedRun].jobs }),
        analysis && React.createElement(TableAnalyzes, { analyzes: analysis })));
};
export default WorkflowQuality;
