import React from 'react';
import clsx from 'clsx';
import { useItemStyles } from './styles';
const BaseListItem = ({ children, actions, classes }) => {
    const ownClasses = useItemStyles();
    return (React.createElement("li", { className: ownClasses.root },
        React.createElement("div", { className: clsx(ownClasses.content, classes?.content) }, children),
        actions && React.createElement("div", { className: clsx(ownClasses.actions, classes?.actions) }, actions)));
};
export default BaseListItem;
