import React from 'react';
import clsx from 'clsx';
import Label from '../../../../common/ui/lib/BaseLabel';
import Select from '../../../../common/ui/components/Select';
import { useStyles } from './styles';
const SelectTraceLabel = ({ names, options, onLabelChange }) => {
    const ownClasses = useStyles();
    const handleLabelRemove = (labelName) => {
        if (typeof names === 'undefined')
            throw new Error('[SelectTraceLabel]: handleLabelRemove is being called by mistake because "names" is undefined');
        const updatedNames = [...names];
        const updatedOptions = options ? [...options] : [];
        updatedNames.splice(names.indexOf(labelName), 1);
        updatedOptions.push(labelName);
        onLabelChange({ names: updatedNames, options: updatedOptions });
    };
    const handleLabelAdd = (labelName) => {
        if (typeof options === 'undefined')
            throw new Error('[SelectTraceLabel]: handleLabelAdd is being called by mistake because "options" is undefined');
        const updatedNames = names ? [...names] : [];
        const updatedOptions = [...options];
        updatedNames.push(labelName);
        updatedOptions.splice(options.indexOf(labelName), 1);
        onLabelChange({ names: updatedNames, options: updatedOptions });
    };
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { showEmptyCloseBtn: true, classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText } }, "Trace Labels"),
        names?.map(name => (React.createElement(Label, { classes: { label: ownClasses.item }, key: name, onClose: () => handleLabelRemove(name) }, name))),
        React.createElement(Select, { options: options, optional: true, onChange: label => handleLabelAdd(label) })));
};
export default SelectTraceLabel;
