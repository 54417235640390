import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(() => {
    return createFactoryStyles({
        root: {
            height: 24,
            display: 'flex',
        },
        treeItemLabel: {
            borderRadius: '0 !important',
        },
        selectInput: {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
        },
    });
});
