import React, { useState, useEffect } from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import SelectAutomation from '../../common/ui/components/SelectAutomation';
import usePage from '../../common/services/page/use';
import Box from '../../common/ui/lib/BaseBox';
import Title from '../../common/ui/lib/BaseTitle';
import Button from '../../common/ui/lib/BaseButton';
import { capitalise } from '../../common/utils';
import SummaryWorkflow from '../summary';
import TableJobs from '../../common/ui/components/TableJobs';
import useNotification from '../../common/services/notification/use';
import { constructRerunMessage, constructRouteForWorkflowRerun, constructUrlForWorkflowPage, } from '../../common/helpers';
import { useRequester } from '../../common/services/dataChannel/use';
import SelectCommit from '../../common/ui/components/SelectCommit';
import ButtonFork from '../../common/ui/components/ButtonFork';
import { useStyles } from './styles';
const PageWorkflowRuns = () => {
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('workflows');
    const workflows = payload;
    const [sortedRuns, setSortedRuns] = useState([]);
    const notification = useNotification();
    const requester = useRequester();
    const extractWorkflowSummary = (run) => {
        const { status, started, ended } = run;
        return { status, started, ended };
    };
    const handleRerun = (workflowName) => {
        const lastRun = sortedRuns[sortedRuns.length - 1];
        notification.show(constructRerunMessage({ name: workflowName, type: 'Workflow' }));
        requester({ route: constructRouteForWorkflowRerun(lastRun.symbol) });
    };
    useEffect(() => {
        workflows && setSortedRuns(Object.values(workflows.runs).sort((a, b) => (a.run < b.run ? 1 : -1)));
    }, [workflows]);
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "There are no runs available for this pipeline!", navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                page.owner.repository.parent && React.createElement(ButtonFork, { parentUrl: page.owner.repository.parent }),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            page.owner?.repository?.commit?.automation && (React.createElement(SelectAutomation, { automationTree: page.owner.repository.commit.automation.symbol, onAutomationChange: navHandlers.handleAutomationChange, onAutomationClose: navHandlers.handleAutomationClose }))) }, workflows && (React.createElement(Box, { header: React.createElement("div", { className: ownClasses.workflowHeader },
            React.createElement(Title, { type: "h1" },
                "Workflow: ",
                capitalise(workflows.name)),
            React.createElement(Button, { onClick: () => handleRerun(capitalise(workflows.name)) },
                React.createElement("p", null, "Rerun"))) }, sortedRuns?.map(run => (React.createElement(Box, { key: run.index, classes: { root: ownClasses.runBox }, header: React.createElement(SummaryWorkflow, { url: constructUrlForWorkflowPage(run.symbol), title: `Run ${run.run}`, summary: extractWorkflowSummary(run) }) },
        React.createElement(TableJobs, { jobs: run.jobs }))))))));
};
export default PageWorkflowRuns;
