import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ spacing }) => {
    return createFactoryStyles({
        pathBox: {
            marginBottom: spacing(4),
            '&:last-child': {
                marginBottom: 0,
            },
        },
    });
});
