import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import List from '../../../common/ui/lib/BaseList';
import ListItem from '../../../common/ui/lib/BaseList/item';
import ButtonGroup from '../../../common/ui/lib/BaseButtonGroup';
import Button from '../../../common/ui/lib/BaseButton';
const RepoBranchRuleList = ({ emptyMsg, patterns, onRemove }) => {
    const routeParams = useParams();
    return (React.createElement(List, { emptyMsg: emptyMsg }, patterns.map(pattern => (React.createElement(ListItem, { key: pattern, actions: React.createElement(ButtonGroup, null,
            React.createElement(Button, { href: `/${routeParams.owner}/${routeParams.repository}/settings/branch-rule/${pattern}` },
                React.createElement(Icon, { icon: ['fal', 'cog'] })),
            React.createElement(Button, { onClick: () => onRemove(pattern) },
                React.createElement(Icon, { icon: ['fal', 'times'] }))) },
        React.createElement("p", null, pattern))))));
};
export default RepoBranchRuleList;
