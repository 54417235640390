import React, { useState, useRef } from 'react';
import Button from '../../ui/lib/BaseButton';
import Title from '../../ui/lib/BaseTitle';
import Box from '../../ui/lib/BaseBox';
import useNotification from '../../services/notification/use';
import { removeFromArrayByValue } from '../../utils';
import List from './list';
import Form from './form';
import { useStyles } from './styles';
import { getSchemaForImport } from './form/schema';
const EnvVariables = ({ variables, emptyMsg, owner, onAdd, onRemove }) => {
    const ownClasses = useStyles();
    const [envVars, setEnvVars] = useState(variables);
    const notification = useNotification();
    const refImportFileLoader = useRef(null);
    const schema = getSchemaForImport();
    const removeVar = (variable) => {
        const envVarsUpdated = removeFromArrayByValue(envVars, variable);
        setEnvVars(envVarsUpdated);
    };
    const handleVariableRemove = (variable) => {
        removeVar(variable);
        onRemove(variable);
    };
    const handleVariableAdd = (variable) => {
        setEnvVars(currentEnvVars => [...currentEnvVars, variable]);
        onAdd(variable);
    };
    const handleImport = (event) => {
        const { files } = event.currentTarget;
        if (files) {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = e => {
                const splitKeyValue = (variable) => {
                    const index = variable.indexOf('=');
                    return {
                        key: variable.substring(0, index),
                        value: variable.substring(index + 1),
                    };
                };
                const content = e.target?.result;
                if (content) {
                    const currentKeyValues = envVars.map(variable => splitKeyValue(variable));
                    const keyValuesToImport = content.split('\n').map(line => splitKeyValue(line));
                    const newKeyValues = keyValuesToImport.filter(toImport => currentKeyValues.filter(current => current.key === toImport.key && current.value === toImport.value).length === 0);
                    newKeyValues.forEach(({ key, value }) => {
                        if (currentKeyValues.some(current => current.key === key)) {
                            notification.show(`Error while importing variable "${key}": Key already existing with a different value`, { type: 'error' });
                        }
                        else {
                            schema
                                .validate({ key, value })
                                .then(value => {
                                value && handleVariableAdd(`${value.key}=${value.value}`);
                                notification.show(`Added variable'${value?.key}' successfully`);
                            })
                                .catch(error => {
                                notification.show(`Error while importing variable ${error.value.key}: ${error.message}`, { type: 'error' });
                            });
                        }
                    });
                }
            };
            event.target.value = '';
        }
    };
    return (React.createElement(Box, { classes: { root: ownClasses.box }, header: React.createElement("div", { className: ownClasses.boxHeader },
            React.createElement(Title, { type: "h1" }, "Environment Variables"),
            React.createElement(Button, { href: `data:text/plain;charset=utf-8,${encodeURIComponent(envVars.join('\n'))}`, htmlAttrs: { download: `${owner}-env-variables.properties` }, classes: { root: ownClasses.headerAction } }, "Export"),
            React.createElement(Button, { classes: { root: ownClasses.headerAction }, onClick: () => refImportFileLoader.current?.click() }, "Import"),
            React.createElement("input", { className: ownClasses.fileUploader, type: "file", ref: refImportFileLoader, onChange: handleImport })) },
        React.createElement(List, { variables: envVars, emptyMsg: emptyMsg, onRemove: handleVariableRemove }),
        React.createElement(Form, { onSubmit: handleVariableAdd, variables: envVars })));
};
export default EnvVariables;
