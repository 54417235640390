import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import BaseAlert from '../../lib/BaseAlert';
import { useStyles } from './styles';
const Notification = ({ message, position, open, duration, type, onClose }) => {
    const [isOpen, setIsOpen] = useState(open);
    const classes = useStyles();
    const handleClose = () => {
        setIsOpen(false);
    };
    return (React.createElement(Snackbar, { classes: {
            root: classes.root,
        }, anchorOrigin: position, open: isOpen, onClose: onClose, autoHideDuration: duration, ClickAwayListenerProps: {
            onClickAway: () => {
                return false;
            },
        } },
        React.createElement(BaseAlert, { classes: {
                root: classes.alertRoot,
                message: classes.alertMessage,
            }, type: type, action: type === 'error' && React.createElement(Icon, { icon: "times-circle", onClick: handleClose }) }, message)));
};
export default Notification;
