import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import React, { useState, useEffect, forwardRef } from 'react';
import Measure from 'react-measure';
import usePage from '../../common/services/page/use';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import SelectAutomation from '../../common/ui/components/SelectAutomation';
import Code from '../../common/ui/lib/BaseCode';
import Box from '../../common/ui/lib/BaseBox';
import SummaryJob from '../summary';
import Title from '../../common/ui/lib/BaseTitle';
import useNotification from '../../common/services/notification/use';
import { useRequester } from '../../common/services/dataChannel/use';
import { constructRouteForJobRerun, constructRerunMessage } from '../../common/helpers';
import SelectCommit from '../../common/ui/components/SelectCommit';
import ButtonFork from '../../common/ui/components/ButtonFork';
import { useStyles } from './styles';
const MAX_NUMBER_OF_LOG_LINES = 500;
const CodeJobLog = forwardRef(({ machineStatus, logLines, highlightedLines, firstLineNumber, gutterWidth, fullLogPath, classes }, logGutterRef) => {
    if (machineStatus === 'booting') {
        return React.createElement(Code, { classes: classes, lines: ['Machine is booting up ...'] });
    }
    else if (machineStatus === 'running' && logLines.length === 0) {
        return React.createElement(Code, { classes: classes, lines: ['Loading...'] });
    }
    return (React.createElement(Code, { classes: classes, lines: logLines, highlightedLines: highlightedLines, hasLineNumbers: true, ref: logGutterRef, gutterWidth: gutterWidth, maxLines: MAX_NUMBER_OF_LOG_LINES, showAllUrl: fullLogPath, firstLineNumber: firstLineNumber }));
});
CodeJobLog.displayName = 'CodeJobLog';
const PageJob = () => {
    const ownClasses = useStyles();
    const notification = useNotification();
    const requester = useRequester();
    const location = useLocation();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('job');
    const job = payload;
    const [commandLines, setCommandLines] = useState([]);
    const [commandLogLines, setCommandLogLines] = useState([]);
    const [commandHighlightedLines, setCommandHighlightedLines] = useState([]);
    const [monitorLines, setMonitorLines] = useState([]);
    const [monitorLogLines, setMonitorLogLines] = useState([]);
    const [monitorHighlightedLines, setMonitorHighlightedLines] = useState([]);
    const [summary, setSummary] = useState();
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
    const [firstCommandLogNumber, setFirstCommandLogNumber] = useState(0);
    const [firstMonitorLogNumber, setFirstMonitorLogNumber] = useState(0);
    const [gutterWidth, setGutterWidth] = useState();
    const onGutterResize = (contentRect) => {
        contentRect?.bounds?.width && setGutterWidth(contentRect.bounds.width);
    };
    const handleSshCopy = () => {
        copy(`ssh factory@${job.machine.ip}`);
        notification.show('SSH command has been copied to clipboard.');
    };
    const handleRerun = () => {
        notification.show(constructRerunMessage({ name: job.name, type: 'Job' }));
        requester({ route: constructRouteForJobRerun(job.symbol) });
    };
    const getSummary = (job) => {
        const { status, started, ended, machine } = job;
        const summary = {
            status,
            started,
            ended,
            menu: {
                rerun: true,
                copySsh: {
                    disabled: !Boolean(machine.ip),
                    tooltip: machine.message,
                },
            },
        };
        return summary;
    };
    const hideScrollbar = () => {
        document.getElementsByTagName('html')[0].classList.add('with-hidden-scrollbar');
    };
    const showScrollBar = () => {
        document.getElementsByTagName('html')[0].classList.remove('with-hidden-scrollbar');
    };
    const scrollToBottom = () => {
        document.documentElement.scrollTop = document.documentElement.scrollHeight;
    };
    const getFirstLogNumber = (log) => {
        if (Object.keys(log).length === 0)
            return 1;
        const sortedLogNumbers = Object.keys(log).sort((a, b) => Number(a) - Number(b));
        if (sortedLogNumbers.length >= MAX_NUMBER_OF_LOG_LINES) {
            return Number(sortedLogNumbers[sortedLogNumbers.length - MAX_NUMBER_OF_LOG_LINES]);
        }
        return Number(sortedLogNumbers[0]);
    };
    useEffect(() => {
        job?.command && setCommandLines(job.command.split('\n'));
        job?.commandLog && setCommandLogLines(Object.values(job.commandLog));
        job?.commandScriptLineNumbers && setCommandHighlightedLines(job?.commandScriptLineNumbers);
        job?.monitor && setMonitorLines(job.monitor.split('\n'));
        job?.monitorLog && setMonitorLogLines(Object.values(job.monitorLog));
        job?.monitorScriptLineNumbers && setMonitorHighlightedLines(job?.monitorScriptLineNumbers);
        job && setSummary(getSummary(job));
    }, [job]);
    const isScrolledNearBottom = () => {
        const NEAR_BOTTOM_PROXIMITY = 40;
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        const isScrollNearBottom = scrollHeight - scrollTop - clientHeight < NEAR_BOTTOM_PROXIMITY;
        const isReceivingLogs = job && job.status === 'running';
        return (isReceivingLogs || true) && isScrollNearBottom;
    };
    useEffect(() => {
        setShouldScrollToBottom(isScrolledNearBottom);
        job?.commandLog && setFirstCommandLogNumber(getFirstLogNumber(job.commandLog));
    }, [commandLogLines]);
    useEffect(() => {
        setShouldScrollToBottom(isScrolledNearBottom);
        job?.monitorLog && setFirstMonitorLogNumber(getFirstLogNumber(job.monitorLog));
    }, [monitorLogLines]);
    useEffect(() => {
        if (shouldScrollToBottom) {
            hideScrollbar();
            scrollToBottom();
            setShouldScrollToBottom(false);
        }
        else if (!isScrolledNearBottom()) {
            showScrollBar();
        }
    });
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "There are no logs available for this job.", navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                page.owner.repository.parent && React.createElement(ButtonFork, { parentUrl: page.owner.repository.parent }),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            page.owner?.repository?.commit?.automation && (React.createElement(SelectAutomation, { automationTree: page.owner.repository.commit.automation.symbol, onAutomationChange: navHandlers.handleAutomationChange, onAutomationClose: navHandlers.handleAutomationClose }))) }, summary && (React.createElement(Box, { header: React.createElement(SummaryJob, { title: `Job: ${job.name}`, summary: summary, onSshCopy: handleSshCopy, onRerun: handleRerun }) },
        React.createElement(Measure, { bounds: true, onResize: onGutterResize }, ({ measureRef: refGutter }) => (React.createElement(React.Fragment, null,
            React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Command Script"),
            React.createElement(Code, { classes: { root: ownClasses.codeScript }, lines: commandLines, hasLineNumbers: true, ref: commandLines.length >= commandLogLines.length ? refGutter : undefined, gutterWidth: commandLines.length < commandLogLines.length ? gutterWidth : undefined }),
            React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Command Output"),
            React.createElement(CodeJobLog, { classes: { root: ownClasses.codeScript }, machineStatus: job.machine.status, logLines: commandLogLines, highlightedLines: commandHighlightedLines, firstLineNumber: firstCommandLogNumber, ref: commandLogLines.length > commandLines.length ? refGutter : undefined, gutterWidth: commandLogLines.length <= commandLines.length ? gutterWidth : undefined, fullLogPath: `${location.pathname}/command.log` }),
            job.monitor && (React.createElement(React.Fragment, null,
                React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Monitor Script"),
                React.createElement(Code, { classes: { root: ownClasses.codeScript }, lines: monitorLines, hasLineNumbers: true, ref: monitorLines.length >= monitorLogLines.length ? refGutter : undefined, gutterWidth: monitorLines.length < monitorLogLines.length ? gutterWidth : undefined }),
                React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Monitor Output"),
                React.createElement(CodeJobLog, { machineStatus: job.machine.status, logLines: monitorLogLines, highlightedLines: monitorHighlightedLines, firstLineNumber: firstMonitorLogNumber, ref: monitorLogLines.length > monitorLines.length ? refGutter : undefined, gutterWidth: monitorLogLines.length <= monitorLines.length ? gutterWidth : undefined, fullLogPath: `${location.pathname}/monitor.log` }))))))))));
};
export default PageJob;
