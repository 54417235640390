import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import LayoutPage from '../../../../common/ui/layouts/Page';
import Navigation from '../../../../common/ui/components/Navigation';
import Select from '../../../../common/ui/components/Select';
import LoadingIndicator from '../../../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../../../common/ui/components/Navigation/linebreak';
import Label from '../../../../common/ui/lib/BaseLabel';
import usePage from '../../../../common/services/page/use';
import Box from '../../../../common/ui/lib/BaseBox';
import Title from '../../../../common/ui/lib/BaseTitle';
import { useRequester } from '../../../../common/services/dataChannel/use';
import { useStyles } from './styles';
import BranchRuleForm from './form';
const PageBranchRule = () => {
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('branch-rule');
    const branchRule = payload;
    const requester = useRequester();
    const routeParams = useParams();
    const history = useHistory();
    const handleSubmit = (data) => {
        requester({
            route: `${routeParams.owner}/${routeParams.repository}/settings/branch-rule/${branchRule.pattern}/set`,
            body: {
                'branch-rule': data,
            },
        });
        history.push(`/${routeParams.owner}/${routeParams.repository}/settings`);
    };
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            React.createElement(Label, { onClose: navHandlers.handleOwnerSettingsClose }, "settings"),
            React.createElement(Label, { classes: { root: ownClasses.navLabelRoot, label: ownClasses.navLabel }, onClose: navHandlers.handleBranchRuleClose }, "branch rule"),
            React.createElement(Select, Object.assign({}, page.owner?.repository?.['branch-rule'], { onChange: navHandlers.handleBranchRuleChange }))) }, branchRule && (React.createElement(Box, { header: React.createElement(Title, { type: "h1" }, "Branch Rule") },
        React.createElement(BranchRuleForm, { onSubmit: handleSubmit, rule: branchRule })))));
};
export default PageBranchRule;
