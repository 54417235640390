import { useLocation } from 'react-router-dom';
import { useDataChannel } from '../dataChannel/use';
import useNavigation from '../navigation/use';
import { deepFind } from '../../utils';
const usePage = (dataRef) => {
    const location = useLocation();
    const { page, navHandlers } = useNavigation();
    const { payload, hasNoData, hasReceivedEndMessage, isNavLoading, isConnected } = useDataChannel(location, dataRef);
    return {
        payload: deepFind(payload, dataRef),
        hasNoData,
        hasReceivedEndMessage,
        isConnected,
        page,
        navHandlers: navHandlers,
        isNavLoading: isNavLoading,
    };
};
export default usePage;
