import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            width: (props) => (props.fullWidth ? '100%' : 'auto'),
        },
        inputRoot: {
            width: '100%',
        },
        selectInputWithClose: {
            borderRadius: `0 ${factoryTheme.shape.borderRadius.small}px ${factoryTheme.shape.borderRadius.small}px 0`,
        },
        baseSelectRoot: {
            height: '100%',
        },
    });
});
