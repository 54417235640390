import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(() => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });
});
