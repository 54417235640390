import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
import { pipelineStyles } from '../../common/styles';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        ...pipelineStyles({ factoryTheme }),
        approvalButton: {
            width: `calc(100% - ${factoryTheme.spacing(6) * 2}px)`,
            backgroundColor: factoryTheme.palette.green[200],
            margin: `${factoryTheme.spacing(4)}px ${factoryTheme.spacing(6)}px ${factoryTheme.spacing(6)}px ${factoryTheme.spacing(6)}px`,
        },
    });
});
