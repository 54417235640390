import { makeStyles } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'inline-block',
            verticalAlign: 'middle',
            padding: 0,
        },
        iconBox: {
            width: 14,
            height: 14,
            backgroundColor: Color(factoryTheme.palette.purple[400]).alpha(0.2).string(),
            borderRadius: factoryTheme.shape.borderRadius.smaller,
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
        },
        iconBoxChecked: {
            backgroundColor: factoryTheme.palette.purple[400],
        },
        checked: {
            color: `${factoryTheme.palette.green[200]} !important`,
            fontSize: `${factoryTheme.typography.fontSize.smallest}px !important`,
        },
    }, {
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        checked: {
            '&:hover': {
                backgroundColor: 'transparent !important',
            },
        },
    });
});
