import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../ui/styles/helpers';
export const useStyles = makeStyles(() => {
    return createFactoryStyles({
        root: {
            minWidth: 280,
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: 11,
            right: 'auto',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    });
});
