import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import Title from '../../../common/ui/lib/BaseTitle';
import TitleTraceCommit from '../../common/ui/TitleTraceCommit';
import TrendChart from '../../charts/TrendChart';
import { CHART_CONFIG } from '../../charts/config';
import { getEngine } from '../../charts/engine';
import { useStyles } from './styles';
const PerformanceOverview = ({ data, pathList, ranges, onPathChange }) => {
    const ownClasses = useStyles();
    const [orderedPathList, setOrderedPathList] = useState([]);
    const [traceData, setTraceData] = useState({});
    const [chartWidth, setChartWidth] = useState(0);
    const [chartHeight, setChartHeight] = useState(0);
    const [selectedScales] = useState({});
    const containerRef = useRef(null);
    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        const _chartWidth = containerRef.current.clientWidth;
        // eslint-disable-next-line no-underscore-dangle
        const _chartHeight = CHART_CONFIG.CHART.HEIGHT;
        setChartWidth(_chartWidth);
        setChartHeight(_chartHeight);
        getEngine(_chartWidth, _chartHeight);
    }, []);
    const getOrderedPathList = () => {
        if (!traceData)
            return [];
        return pathList?.sort() || Object.keys(traceData).sort();
    };
    const handleClick = (path) => {
        onPathChange([{ selected: path, path: { optional: true } }]);
    };
    const getTraceData = () => {
        if (!data)
            return {};
        const dataObj = data?.['trace-overviews'];
        const response = {};
        const percentileMap = { 0: '05', 1: '25', 2: '50', 3: '75', 4: '95' };
        Object.entries(dataObj).forEach(([tracePath, traceValue]) => {
            const trendChartData = {};
            Object.entries(traceValue.durations).forEach(([iteration, iterationValue]) => {
                for (let i = 0; i < iterationValue.length; i++) {
                    const percentile = percentileMap[i];
                    if (!trendChartData[percentile])
                        trendChartData[percentile] = {};
                    if (!trendChartData[percentile][iteration])
                        trendChartData[percentile][iteration] = {};
                    trendChartData[percentile][iteration] = iterationValue[i];
                }
            });
            response[tracePath] = trendChartData;
        });
        return response;
    };
    useEffect(() => {
        setOrderedPathList(getOrderedPathList());
        setTraceData(getTraceData());
    }, [data, pathList]);
    const getScale = (path) => {
        return selectedScales[path] || 'Linear';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: containerRef },
            data?.commit && (React.createElement(TitleTraceCommit, { data: data?.commit, classes: { root: ownClasses.title }, id: data?.id, name: data?.name, created: data?.created })),
            orderedPathList.map((path) => {
                return (React.createElement(Box, { classes: { root: ownClasses.box }, key: path, header: React.createElement(Title, { type: "h1" },
                        "Traces for ",
                        path), onClick: () => handleClick(path) },
                    React.createElement(TrendChart, { width: chartWidth, height: chartHeight, data: traceData[path], isLogScaled: getScale(path) === 'Log', ranges: {
                            iterationRange: ranges.iterationRange,
                            durationRange: ranges.durationRange[path],
                        } })));
            }))));
};
PerformanceOverview.displayName = 'PerformanceOverview';
export default PerformanceOverview;
