import clsx from 'clsx';
import React from 'react';
import Label from '../../../../common/ui/lib/BaseLabel';
import SelectTree from '../../../../common/ui/components/SelectTree';
import { addOptionalSelectToEnd } from '../../helpers';
import { useStyles } from './styles';
const SelectTraceTracker = ({ tracker, onTrackerChange }) => {
    const ownClasses = useStyles();
    const handleTrackerChange = (newTrackerTree) => {
        onTrackerChange(addOptionalSelectToEnd(newTrackerTree, 'tracker'));
    };
    const handleTrackerClear = () => {
        onTrackerChange({ optional: true });
    };
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { showEmptyCloseBtn: true, classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText } }, "Trace Tracker"),
        React.createElement(SelectTree, { tree: tracker, selectProps: { optional: true }, treeRef: "tracker", classes: { root: ownClasses.item }, onChange: newTrackerTree => handleTrackerChange(newTrackerTree), onClose: () => handleTrackerClear() })));
};
export default SelectTraceTracker;
