import { Link } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import WithConditionalWrapper from '../helpers/WithConditionalWrapper';
import { extractDataFromSymbol, consturctUrlForCommitPage, shortenSha } from '../../../helpers';
import { useStyles } from './styles';
const CommitSha = ({ commitSymbol, isNotLinked, classes }) => {
    const ownClasses = useStyles();
    const sha = shortenSha(extractDataFromSymbol(commitSymbol).commit);
    return (React.createElement(WithConditionalWrapper, { condition: !isNotLinked, wrapper: children => React.createElement(Link, { to: consturctUrlForCommitPage(sha) }, children) },
        React.createElement("code", { className: clsx(ownClasses.shaBox, classes?.root) }, sha)));
};
export default CommitSha;
