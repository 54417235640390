import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            marginRight: factoryTheme.spacing(3),
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            color: `${factoryTheme.palette.purple[100]} !important`,
            fontSize: '20px',
            transform: 'rotate(180deg)',
        },
    });
});
