import React from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import NavigationLineBreak from '../../common/ui/components/Navigation/linebreak';
import SelectAutomation from '../../common/ui/components/SelectAutomation';
import PipelineBuild from '../types/Build';
import PipelineRelease from '../types/Release';
import usePage from '../../common/services/page/use';
import SelectCommit from '../../common/ui/components/SelectCommit';
import ButtonFork from '../../common/ui/components/ButtonFork';
const PagePipeline = () => {
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('pipeline');
    const pipeline = payload;
    const pipelineSummaryWhitelist = ['run', 'status', 'started', 'ended', 'menu'];
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "There are no workflows recorded for this pipeline!", navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                page.owner.repository.parent && React.createElement(ButtonFork, { parentUrl: page.owner.repository.parent }),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null),
            React.createElement(NavigationLineBreak, null),
            page.owner?.repository?.commit?.automation && (React.createElement(SelectAutomation, { automationTree: page.owner.repository.commit.automation.symbol, onAutomationChange: navHandlers.handleAutomationChange, onAutomationClose: navHandlers.handleAutomationClose }))) },
        pipeline?.name === 'build' && (React.createElement(PipelineBuild, { build: { [pipeline.run]: pipeline }, summaryWhitelist: pipelineSummaryWhitelist })),
        pipeline?.name === 'release' && (React.createElement(PipelineRelease, { release: { [pipeline.run]: pipeline }, summaryWhitelist: pipelineSummaryWhitelist }))));
};
export default PagePipeline;
