import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import Title from '../../../common/ui/lib/BaseTitle';
import TitleTraceCommit from '../../common/ui/TitleTraceCommit';
import TreeChart from '../../charts/TreeChart';
import { getEngine } from '../../charts/engine';
import { CHART_CONFIG } from '../../charts/config';
import { useStyles } from './styles';
const PerformanceTree = ({ data }) => {
    const ownClasses = useStyles();
    const container = useRef(null);
    const [chartWidth, setChartWidth] = useState(0);
    const [chartHeight, setChartHeight] = useState(0);
    useEffect(() => {
        const newChartWidth = container?.current?.clientWidth;
        const newChartHeight = CHART_CONFIG.CHART.HEIGHT;
        setChartWidth(newChartWidth);
        setChartHeight(newChartHeight);
        getEngine(newChartWidth, newChartHeight);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: container },
            React.createElement(TitleTraceCommit, { data: data?.commit }),
            Object.entries(data?.['trace-tree']).map(([key, pathData]) => {
                return (React.createElement(Box, { classes: { root: ownClasses.pathBox }, key: `path-${key}`, header: React.createElement(Title, { type: "h1" },
                        " Trace ",
                        pathData.id,
                        " ") },
                    React.createElement(TreeChart, { width: chartWidth, height: chartHeight, data: pathData })));
            }))));
};
PerformanceTree.displayName = 'PerformanceTree';
export default PerformanceTree;
