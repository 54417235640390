import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import usePage from '../../common/services/page/use';
import BaseButton from '../../common/ui/lib/BaseButton';
import { spinner, computeDownloadSize } from './helpers';
import { useStyles } from './styles';
const PageJobOutput = () => {
    const ownClasses = useStyles();
    const location = useLocation();
    const { payload } = usePage('output-logs');
    const logs = payload;
    const [spinnerText, setSpinnerText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [lineNumberWidth, setLineNumberWidth] = useState(0);
    const [lineNumbers, setLineNumbers] = useState('');
    let spinnerAnimationInterval;
    useEffect(() => {
        if (typeof logs !== 'undefined') {
            clearInterval(spinnerAnimationInterval);
            setIsLoading(false);
            let newLineNumbers = '';
            const len = logs.logs.split('\n').length;
            setLineNumberWidth(Math.max(1, Math.floor(Math.log10(len)) + 1) * 13);
            for (let i = 1; i <= len; i++)
                newLineNumbers = `${newLineNumbers + i}\n`;
            setLineNumbers(newLineNumbers);
        }
    }, [logs]);
    useEffect(() => {
        spinnerAnimationInterval = global.setInterval(function updateSpinnerText() {
            const content = spinner.animate.bind(spinner);
            setSpinnerText(content);
        }, 50);
    }, []);
    return (React.createElement("div", { className: ownClasses.root }, isLoading ? (React.createElement("div", { className: ownClasses.spinner }, spinnerText)) : logs.logs ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ownClasses.lineNumbers, style: { width: `${lineNumberWidth}px` } }, lineNumbers),
        React.createElement("div", { className: ownClasses.code, style: { paddingLeft: `${lineNumberWidth}px` } }, logs.logs),
        React.createElement("a", { href: `data:text/plain;charset=utf-8,${encodeURIComponent(logs.logs)}`, download: location.pathname.substr(1) },
            React.createElement(BaseButton, { href: `data:text/plain;charset=utf-8,${encodeURIComponent(logs.logs)}`, htmlAttrs: { download: location.pathname.substr(1) }, classes: { root: ownClasses.downloadButton } },
                "Download (",
                computeDownloadSize(logs.logs),
                ")")))) : (React.createElement("p", { className: ownClasses.emptyMsg }, "There are no logs available for this job."))));
};
export default PageJobOutput;
