export const performanceAnalysisNavigation = (factoryTheme) => ({
    item: {
        marginRight: factoryTheme.spacing(3),
    },
    label: {
        width: '105px',
    },
    labelText: {
        textAlign: 'left',
    },
});
