import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import List from '../../../ui/lib/BaseList';
import ListItem from '../../../ui/lib/BaseList/item';
import Button from '../../../ui/lib/BaseButton';
import { useStyles } from './styles';
const EnvVariableList = ({ variables, emptyMsg, onRemove }) => {
    const ownClasses = useStyles();
    return (React.createElement(List, { emptyMsg: emptyMsg }, variables.map(variable => (React.createElement(ListItem, { classes: { content: ownClasses.variableListItem }, key: variable, actions: React.createElement(Button, { onClick: () => onRemove(variable) },
            React.createElement(Icon, { icon: ['fal', 'times'] })) }, variable)))));
};
export default EnvVariableList;
