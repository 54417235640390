import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { SET_PAGE } from '../../common/store/reducers/page';
import PageOwnerList from '../../Owner/list';
import PageOwner from '../../Owner/single';
import PageRepository from '../../Repository/single';
import PageCommit from '../../Commit/single';
import PagePipelineRuns from '../../Pipeline/runs';
import PagePipeline from '../../Pipeline/single';
import PageWorkflowRuns from '../../Workflow/runs';
import PageWorkflow from '../../Workflow/single';
import PageJobRuns from '../../Job/runs';
import PageJob from '../../Job/single';
import PageJobOutput from '../../Job/output';
import PageOwnerSettings from '../../Owner/settings';
import PageRepositorySettings from '../../Repository/settings';
import PageBranchRule from '../../Repository/settings/branchRules/single';
import PagePeformanceAnalaysis from '../../PerformanceAnalysis';
import PageUserSettings from '../../User/settings';
import { capitalise } from '../../common/utils';
import { shortenSha } from '../../common/helpers';
import { convertParamsToCamelCase, getPageTree } from './helpers';
const withPageLoader = (PageComponent, partialPage, key, routeProps) => {
    class PageLoader extends React.Component {
        constructor(props) {
            super(props);
            partialPage && props.setPage(partialPage);
        }
        render() {
            return React.createElement(PageComponent, Object.assign({ key: key }, routeProps));
        }
    }
    const mapsDispatchToProps = (dispatch) => ({
        setPage: (partialPage) => dispatch({ type: SET_PAGE, payload: partialPage }),
    });
    return connect(null, mapsDispatchToProps)(PageLoader);
};
const FactoryRoute = ({ component: Component, constructPage, constructTitle, ...rest }) => {
    return (React.createElement(Route, Object.assign({}, rest, { render: props => {
            let partialPage;
            if (constructPage) {
                const camelCaseParams = convertParamsToCamelCase(props.match.params);
                partialPage = constructPage(camelCaseParams, props.location.search ? JSON.parse(decodeURI(props.location.search.substr(3))) : undefined);
            }
            document.title = constructTitle ? constructTitle(props.match.params) : 'Factory';
            const PageComponent = withPageLoader(Component, partialPage, props.match.url + location.search, props);
            return React.createElement(PageComponent, null);
        } })));
};
const Router = () => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(FactoryRoute, { exact: true, path: "/", component: PageOwnerList }),
            React.createElement(FactoryRoute, { exact: true, path: "/:owner/:repository/:commit([0-9a-fA-f]{40})/analysis/performance-analysis", component: PagePeformanceAnalaysis, constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Performance Analysis`, constructPage: (params, query) => {
                    const queryPerformanceAnalysis = query?.analysis;
                    const performanceAnalysis = {
                        id: queryPerformanceAnalysis.id,
                        compare: queryPerformanceAnalysis.compare,
                        trace: {
                            id: queryPerformanceAnalysis.trace?.id,
                            path: queryPerformanceAnalysis.trace?.path || [{ optional: true }],
                            tracker: queryPerformanceAnalysis.trace?.tracker || { optional: true },
                            labels: queryPerformanceAnalysis.trace?.labels || { names: [] },
                            iteration: queryPerformanceAnalysis.trace?.iteration || undefined,
                        },
                    };
                    const page = getPageTree(Object.values(params), Object.keys(params));
                    page.owner.repository.commit.analysis = { 'performance-analysis': performanceAnalysis };
                    return page;
                } }),
            React.createElement(FactoryRoute, { exact: true, path: "/settings", constructTitle: () => `Factory | Settings`, component: PageUserSettings }),
            React.createElement(FactoryRoute, { exact: true, path: "/:owner", constructTitle: (params) => `${params.owner}`, constructPage: (params) => getPageTree(Object.values(params), Object.keys(params)), component: PageOwner }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)}`, constructPage: (params) => getPageTree(Object.values(params), Object.keys(params)), component: PageCommit }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/settings', constructTitle: (params) => `${params.owner} | Settings`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params), Object.keys(params));
                    page.owner.settings = true;
                    return page;
                }, component: PageOwnerSettings }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository', constructTitle: (params) => `${params.owner}/${params.repository}`, constructPage: (params) => getPageTree(Object.values(params), Object.keys(params)), component: PageRepository }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/settings', constructTitle: (params) => `${params.owner}/${params.repository} | Settings`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params), Object.keys(params));
                    page.owner.settings = true;
                    return page;
                }, component: PageRepositorySettings }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/settings/branch-rule/:branchRule', constructTitle: (params) => `${params.owner}/${params.repository} | Settings branch rule: ${params.branchRule}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params), Object.keys(params));
                    page.owner.repository['branch-rule'].settings = true;
                    return page;
                }, component: PageBranchRule }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:branch', constructTitle: (params) => `${params.owner}/${params.repository}@${params.branch}`, constructPage: (params) => getPageTree(Object.values(params), Object.keys(params)), component: PageRepository }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Pipeline: ${capitalise(params.pipeline)}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(1).fill('symbol'));
                    return page;
                }, component: PagePipelineRuns }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Pipeline: ${capitalise(params.pipeline)}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(2).fill('symbol'));
                    return page;
                }, component: PagePipeline }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun/:workflow', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Workflow: ${capitalise(params.workflow)}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(3).fill('symbol'));
                    return page;
                }, component: PageWorkflowRuns }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun/:workflow/:workflowRun', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Workflow: ${capitalise(params.workflow)}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(4).fill('symbol'));
                    return page;
                }, component: PageWorkflow }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun/:workflow/:workflowRun/:job', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Job: ${params.job}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(5).fill('symbol'));
                    return page;
                }, component: PageJobRuns }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun/:workflow/:workflowRun/:job/:jobRun', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Job: ${params.job}`, constructPage: (params) => {
                    const page = getPageTree(Object.values(params).slice(0, 3), Object.keys(params).slice(0, 3));
                    page.owner.repository.commit.automation = getPageTree(Object.values(params).slice(3), Array(6).fill('symbol'));
                    return page;
                }, component: PageJob }),
            React.createElement(FactoryRoute, { exact: true, path: '/:owner/:repository/:commit(\\b[0-9a-f]{40}\\b)/:pipeline/:pipelineRun/:workflow/:workflowRun/:job/:jobRun/(command|monitor).log', constructTitle: (params) => `${params.owner}/${params.repository}@${shortenSha(params.commit)} | Job Logs: ${params.job}`, component: PageJobOutput }))));
};
export default Router;
