import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import StatusesLegend from '../../common/ui/components/StatusLegend';
import CommitSummary from '../../Commit/summary';
import { consturctUrlForCommitPage, formatDateTime } from '../../common/helpers';
import usePage from '../../common/services/page/use';
import ButtonVerbose from '../../common/ui/components/ButtonVerbose';
import Button from '../../common/ui/lib/BaseButton';
import ButtonFork from '../../common/ui/components/ButtonFork';
import { useStyles } from './styles';
import { sortCommits } from './helpers';
const PageRepository = () => {
    const [shouldHidePipelineRelease, setshouldHidePipelineRelease] = useState(true);
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('repository');
    const repository = payload;
    const [sortedCommits, setSortedCommits] = useState([]);
    useEffect(() => {
        const incomingCommits = repository?.branch.commits;
        setSortedCommits(sortCommits(incomingCommits));
    }, [repository]);
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "No commits recorded for this repository yet!", navigation: React.createElement(Navigation, { links: React.createElement(React.Fragment, null,
                React.createElement(Button, { to: `/${page.owner?.selected}/${page.owner?.repository?.selected}/settings` },
                    React.createElement("div", { className: ownClasses.settingsButtonContent },
                        React.createElement(Icon, { icon: ['fal', 'cog'] }),
                        React.createElement("p", null, "Settings")))) },
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.branch && (React.createElement(React.Fragment, null,
                React.createElement(Select, Object.assign({}, page.owner.repository.branch, { onClose: navHandlers.handleBranchClose, onChange: navHandlers.handleBranchChange })),
                page.owner.repository.parent && React.createElement(ButtonFork, { parentUrl: page.owner.repository.parent }),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/tree/${page.owner.repository.branch.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null)) }, sortedCommits.length > 0 && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ownClasses.topBar },
            React.createElement(ButtonVerbose, { onToggle: isVerbose => setshouldHidePipelineRelease(isVerbose) }),
            React.createElement(StatusesLegend, null)),
        sortedCommits.map(commit => (React.createElement(CommitSummary, { key: commit.sha, commitSymbol: commit.symbol, url: consturctUrlForCommitPage(commit.symbol), title: ` - ${formatDateTime(commit.created)} @${commit.author}`, pipelines: commit.pipelines, configuration: commit.configuration, shouldHidePipelineRelease: shouldHidePipelineRelease && commit?.['approval-status']?.['is-approved'] === false })))))));
};
export default PageRepository;
