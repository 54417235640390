import { makeStyles } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles } from '../../styles/helpers';
const fixedWidthText = (factoryTheme) => ({
    fontFamily: factoryTheme.typography.fontFamily.fixedWidth.variationB,
    fontSize: factoryTheme.typography.fontSize.smallest,
});
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            overflow: 'auto',
            backgroundColor: factoryTheme.palette.purple[600],
        },
        lines: {
            whiteSpace: 'nowrap',
        },
        line: {
            minWidth: 'fit-content',
            lineHeight: '25px',
            color: factoryTheme.palette.purple[100],
            alignItems: 'center',
            '&:hover': {
                backgroundColor: Color(factoryTheme.palette.white[200]).alpha(0.1).string(),
            },
        },
        lineNumber: {
            ...fixedWidthText(factoryTheme),
            minWidth: ({ controlledGutterWidth }) => controlledGutterWidth,
            backgroundColor: factoryTheme.palette.purple[600],
            borderRight: `1px solid ${factoryTheme.palette.purple[300]}`,
            boxSizing: 'content-box',
            textAlign: 'right',
            position: 'absolute',
            userSelect: 'none',
            '& > p': {
                padding: factoryTheme.spacing(0, 1),
                width: '100%',
            },
        },
        lineHighlighted: {
            color: Color(factoryTheme.palette.green[200]).alpha(0.9).string(),
            backgroundColor: Color(factoryTheme.palette.green[100]).alpha(0.2).string(),
        },
        lineContent: {
            ...fixedWidthText(factoryTheme),
            height: 25,
            padding: factoryTheme.spacing(0, 1),
            paddingLeft: ({ controlledGutterWidth }) => controlledGutterWidth + 5,
            '& > a': {
                'text-decoration': 'underline',
            },
        },
        showAll: {
            ...fixedWidthText(factoryTheme),
            lineHeight: '25px',
            textAlign: 'center',
            '& > a': {
                width: '100%',
                display: 'block',
            },
        },
    });
});
