import React from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import Title from '../../../../common/ui/lib/BaseTitle';
import CommitSha from '../../../../common/ui/components/CommitSha';
import { useStyles } from './style';
const TitleTraceCommit = ({ data, classes, name, id, created }) => {
    const ownClasses = useStyles();
    const formatDateTime = (date) => {
        if (!date)
            return 'undefined';
        return format(date, 'yyyy-MM-dd HH-mm-ss');
    };
    const tagComponent = data.tag ? (React.createElement(React.Fragment, null,
        React.createElement(CommitSha, { commitSymbol: data.symbol }),
        React.createElement("span", { className: "fixed-width-text" }, data.tag),
        React.createElement("span", null, " - "))) : null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { type: "h2", hasLine: true, classes: { root: clsx(ownClasses.root, classes?.root) } },
            tagComponent,
            React.createElement(CommitSha, { commitSymbol: data.symbol }),
            React.createElement("span", null,
                ' ',
                "- ",
                formatDateTime(new Date(data.created)),
                " @",
                data.author,
                ' '),
            React.createElement("br", null)),
        name && created && (React.createElement("span", { className: ownClasses.meta },
            "Analysis ID: ",
            id,
            " ",
            React.createElement("br", null),
            "Name: ",
            name,
            " ",
            React.createElement("br", null),
            "Created: ",
            format(Date.parse(`${created}`), 'yyyy-MM-dd HH:mm:ss')))));
};
export default TitleTraceCommit;
