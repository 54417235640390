import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
const headerHeight = 50;
const footerHeight = 65;
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        main: {
            minHeight: `calc(100% - ${headerHeight}px - ${footerHeight}px)`,
            margin: '0 auto',
        },
        defaultView: {
            width: 1000,
        },
        comparisonView: {
            width: 1600,
        },
        noDataMessage: {
            color: factoryTheme.palette.purple[100],
            fontSize: factoryTheme.typography.fontSize.large,
            lineHeight: '35px',
            textAlign: 'center',
            marginTop: factoryTheme.spacing(4),
        },
        quote: {
            color: factoryTheme.palette.purple[200],
            fontStyle: 'italic',
        },
    });
});
export const useHeaderStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            height: headerHeight,
            backgroundColor: factoryTheme.palette.purple[700],
        },
        content: {
            width: 1000,
            height: headerHeight,
            margin: '0 auto',
        },
        logo: {
            width: 27,
            height: 27,
            display: 'block',
        },
        connectionIndicator: {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        menuToggler: {
            minWidth: 110,
            height: headerHeight,
            padding: 0,
            justifyContent: 'flex-end',
        },
        menuAvatar: {
            width: 22,
            height: 22,
            borderRadius: '100%',
            border: `1px solid ${factoryTheme.palette.green[200]}`,
            marginLeft: factoryTheme.spacing(1),
        },
        loginBtn: {
            padding: 0,
        },
        loginBtnContent: {
            height: headerHeight,
            display: 'flex',
            alignItems: 'center',
            '& > p': {
                marginRight: factoryTheme.spacing(1),
            },
        },
        profileMenuItem: {
            height: 27,
        },
    }, {
        root: {
            maxWidth: '100%',
        },
    });
});
export const useFooterStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: footerHeight,
            width: '100%',
            padding: factoryTheme.spacing(5, 0),
        },
        image: {
            width: 165,
            display: 'block',
            margin: '0 auto',
        },
    });
});
