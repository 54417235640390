import React from 'react';
import * as PIXI from 'pixi.js';
import { CHART_CONFIG } from '../config';
import { getShortNotation, minmax, roundOneDecimal, safeLog10 } from '../../../common/utils';
import { useChart, useChartRenderer } from '../mixins';
import { createBackground, createCurvedLine, createHoverOverlayText, drawDashedPolygon, getLabels, getTextSize, removeChildIfExists, createPoints, drawCurvedLine, getMap, createCoordinateSystem, } from '../utils';
import { useStyles } from './style';
const MultiIterationTrendChart = ({ width, height, data, ranges, isLogScaled, switchToLine, }) => {
    const ownClasses = useStyles();
    const { rootRef, overlayRef, canvasRef, stage, setStage } = useChart();
    const calculateDataMap = (data, ranges) => {
        let iterationRange;
        if (ranges && ranges.iterationRange) {
            ({ iterationRange } = ranges);
        }
        else {
            iterationRange = minmax(Object.values(data)
                .flatMap((x) => Object.keys(x))
                .map(x => Number(x)));
        }
        let durationRange;
        if (ranges && ranges.durationRange) {
            ({ durationRange } = ranges);
        }
        else {
            durationRange = [0, Math.max(...Object.values(data).flatMap((percentileData) => Object.values(percentileData)))];
        }
        const [mapX] = getMap(iterationRange, [
            CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT,
            width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT,
        ]);
        const topPadding = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const bottomPadding = CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const [mapY, mapYInverse] = getMap(durationRange, [height - bottomPadding, topPadding]);
        const [mapYLog, mapYLogInverse] = getMap(durationRange.map(safeLog10), [height - bottomPadding, topPadding]);
        return {
            iterationRange,
            durationRange,
            mapX,
            mapY,
            mapYLog,
            mapYInverse,
            mapYLogInverse,
        };
    };
    const render = (data, mouseX, mouseY, logScaleAnimationProgress) => {
        const { durationRange, mapX, mapY, mapYLog, mapYInverse, mapYLogInverse } = calculateDataMap(data, ranges);
        const updateBackground = () => {
            const createChartBackground = () => {
                const background = createBackground(width, height);
                const rightBorder = new PIXI.Graphics();
                rightBorder.lineStyle(...Object.values(CHART_CONFIG.COORDINATE_SYSTEM.OUTLINE.STYLE));
                rightBorder.moveTo(width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, CHART_CONFIG.CHART.PADDING.VERTICAL.TOP);
                rightBorder.lineTo(width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM);
                background.addChild(rightBorder);
                return background;
            };
            if (!stage.getChildByName(CHART_CONFIG.LAYER.BACKGROUND.NAME)) {
                const background = createChartBackground();
                background.name = CHART_CONFIG.LAYER.BACKGROUND.NAME;
                background.zIndex = CHART_CONFIG.LAYER.BACKGROUND.Z_INDEX;
                stage.addChild(background);
            }
        };
        const updateGraph = (data, logScaleAnimationProgress) => {
            const createGraph = (data, logScaleAnimationProgress) => {
                const localMapY = (y) => (1 - logScaleAnimationProgress) * mapY(y) + logScaleAnimationProgress * mapYLog(safeLog10(y));
                const pixelData = {};
                Object.entries(data).forEach(([percent, percentileData]) => {
                    pixelData[percent] = [];
                    Object.entries(percentileData).forEach(([iteration, duration]) => {
                        pixelData[percent].push([mapX(Number(iteration)), localMapY(duration)]);
                    });
                });
                const createChartArea = (vertices, color, maxY) => {
                    const line = new PIXI.Graphics();
                    line.lineStyle(...Object.values(CHART_CONFIG.CHART.TREND.LINE.STYLE));
                    line.moveTo(vertices[0][0], vertices[0][1]);
                    drawCurvedLine(vertices, line);
                    const blankArea = new PIXI.Graphics();
                    blankArea.beginFill(CHART_CONFIG.CHART.BG_COLOR);
                    blankArea.moveTo(vertices[0][0], vertices[0][1]);
                    drawCurvedLine(vertices, blankArea);
                    blankArea.lineTo(vertices[vertices.length - 1][0], maxY);
                    blankArea.lineTo(vertices[0][0], maxY);
                    blankArea.endFill();
                    const percentileShape = new PIXI.Graphics();
                    percentileShape.alpha = CHART_CONFIG.CHART.TREND.SHAPE.ALPHA;
                    percentileShape.beginFill(color);
                    percentileShape.moveTo(vertices[0][0], vertices[0][1]);
                    drawCurvedLine(vertices, percentileShape);
                    percentileShape.lineTo(vertices[vertices.length - 1][0], maxY);
                    percentileShape.lineTo(vertices[0][0], maxY);
                    percentileShape.endFill();
                    const container = new PIXI.Container();
                    container.addChild(blankArea);
                    container.addChild(percentileShape);
                    container.addChild(line);
                    return container;
                };
                const graph = new PIXI.Container();
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    let percentileShape;
                    if (config.BOLD) {
                        percentileShape = createCurvedLine(pixelData[config.PERCENT], CHART_CONFIG.CHART.TREND.LINE.BOLD_STYLE);
                    }
                    else {
                        percentileShape = createChartArea(pixelData[config.PERCENT], config.COLOR, height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM);
                        // we need this on all shapes (even bottom one). Otherwise we will catch clicks underneath the chart as clicks on 05 percentile
                        percentileShape.interactive = true;
                        if (config.PERCENT !== '05') {
                            percentileShape.buttonMode = true;
                            percentileShape.mousedown = () => {
                                switchToLine();
                            };
                        }
                    }
                    const points = createPoints(pixelData[config.PERCENT], CHART_CONFIG.CHART.TREND.POINT.RADIUS, CHART_CONFIG.CHART.TREND.POINT.COLOR, CHART_CONFIG.CHART.TREND.POINT.ALPHA);
                    graph.addChild(percentileShape);
                    graph.addChild(points);
                });
                return graph;
            };
            const lastGraph = stage.getChildByName(CHART_CONFIG.LAYER.GRAPH.NAME);
            if (!lastGraph ||
                lastGraph.chart.data !== data ||
                lastGraph.chart.logScaleAnimationProgress !== logScaleAnimationProgress) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.GRAPH.NAME);
                const graph = createGraph(data, logScaleAnimationProgress);
                graph.name = CHART_CONFIG.LAYER.GRAPH.NAME;
                graph.zIndex = CHART_CONFIG.LAYER.GRAPH.Z_INDEX;
                graph.chart = { data, logScaleAnimationProgress };
                stage.addChild(graph);
            }
        };
        const updateCoordinateSystem = (data, logScaleAnimationProgress) => {
            const createChartCoordinateSystem = (data) => {
                const eslintHack = Object.values(data)[0];
                const iterationLabels = Object.keys(eslintHack).map(label => ({
                    label: label.toString(),
                    pos: mapX(Number(label)),
                }));
                const durationLabelsLinear = getLabels(...durationRange, 8, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: getShortNotation(label), pos: mapY(label) }));
                const durationLabelsLog = getLabels(...durationRange.map(safeLog10), CHART_CONFIG.COORDINATE_SYSTEM.TICK.COUNT.VERTICAL, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS_WITH_FRACTIONS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: label.toString(), pos: mapYLog(label) }));
                const coordinateSystemLinear = createCoordinateSystem(width, height, iterationLabels, durationLabelsLinear, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ITERATION, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                coordinateSystemLinear.name = 'linear';
                coordinateSystemLinear.alpha = 0;
                const coordinateSystemLog = createCoordinateSystem(width, height, iterationLabels, durationLabelsLog, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ITERATION, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                coordinateSystemLog.name = 'log';
                coordinateSystemLog.alpha = 0;
                const coordinateSystem = new PIXI.Container();
                coordinateSystem.addChild(coordinateSystemLinear);
                coordinateSystem.addChild(coordinateSystemLog);
                return coordinateSystem;
            };
            const lastCoordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (!lastCoordinateSystem || lastCoordinateSystem.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
                const coordinateSystem = createChartCoordinateSystem(data);
                coordinateSystem.name = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME;
                coordinateSystem.zIndex = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.Z_INDEX;
                coordinateSystem.chart = { data, logScaleAnimationProgress };
                stage.addChild(coordinateSystem);
            }
            const coordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (logScaleAnimationProgress === 1) {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
            else if (logScaleAnimationProgress === 0) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else if (isLogScaled) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
        };
        const updateHoverOverlay = (data, mouseX, mouseY) => {
            const createHoverOverlay = () => {
                const hoverOverlay = new PIXI.Container();
                const lineVertical = new PIXI.Graphics();
                lineVertical.name = 'lineVertical';
                lineVertical.lineStyle(...Object.values(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE));
                drawDashedPolygon([
                    { x: 0, y: height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM },
                    { x: 0, y: CHART_CONFIG.CHART.PADDING.VERTICAL.TOP },
                ], lineVertical, 1, 2);
                hoverOverlay.addChild(lineVertical);
                const lineHorizontal = new PIXI.Graphics();
                lineHorizontal.name = 'lineHorizontal';
                lineHorizontal.lineStyle(...Object.values(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE));
                drawDashedPolygon([
                    { x: width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, y: 0 },
                    { x: CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, y: 0 },
                ], lineHorizontal, 1, 2);
                hoverOverlay.addChild(lineHorizontal);
                const labelY = createHoverOverlayText();
                labelY.name = 'labelY';
                labelY.x =
                    CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                hoverOverlay.addChild(labelY);
                const labelX = createHoverOverlayText();
                labelX.name = 'labelX';
                labelX.y = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP;
                hoverOverlay.addChild(labelX);
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    const labelTrend = createHoverOverlayText();
                    labelTrend.name = `labelTrend${config.PERCENT}`;
                    labelTrend.y = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + config.HOVER_OVERLAY_VERTICAL_MARGIN;
                    hoverOverlay.addChild(labelTrend);
                });
                return hoverOverlay;
            };
            const lastHoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (!lastHoverOverlay || lastHoverOverlay.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
                const hoverOverlay = createHoverOverlay();
                hoverOverlay.name = CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME;
                hoverOverlay.zIndex = CHART_CONFIG.LAYER.HOVER_OVERLAY.Z_INDEX;
                hoverOverlay.chart = { data, mouseX, mouseY };
                stage.addChild(hoverOverlay);
            }
            const hoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (mouseX >= CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT / 2.0 &&
                mouseY >= CHART_CONFIG.CHART.PADDING.VERTICAL.TOP &&
                mouseX <= width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT / 2.0 &&
                mouseY <= height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM) {
                let minDist = Number.MAX_VALUE;
                let closestIteration;
                const eslintHack = Object.values(data)[0];
                Object.keys(eslintHack).forEach(iteration => {
                    const x = mapX(parseInt(iteration));
                    if (Math.abs(mouseX - x) < minDist) {
                        minDist = Math.abs(mouseX - x);
                        closestIteration = iteration;
                    }
                });
                const closestX = mapX(closestIteration);
                const mapBackY = isLogScaled ? mapYLogInverse : mapYInverse;
                hoverOverlay.alpha = 1;
                hoverOverlay.getChildByName('lineVertical').x = closestX;
                hoverOverlay.getChildByName('lineHorizontal').y = mouseY;
                hoverOverlay.getChildByName('labelY').y = mouseY;
                hoverOverlay.getChildByName('labelY').text = roundOneDecimal(mapBackY(mouseY)).toString();
                const labelX = hoverOverlay.getChildByName('labelX');
                const [textWidth] = getTextSize(closestIteration.toString(), CHART_CONFIG.CHART.HOVER_OVERLAY.TEXT.STYLE);
                labelX.x = closestX - CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL - textWidth;
                labelX.text = closestIteration;
                CHART_CONFIG.CHART.TREND.CONFIG.forEach(config => {
                    const labelTrend = hoverOverlay.getChildByName(`labelTrend${config.PERCENT}`);
                    labelTrend.x = closestX + CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                    labelTrend.text = data[config.PERCENT][closestIteration].toString();
                });
            }
            else {
                hoverOverlay.alpha = 0;
            }
        };
        updateBackground();
        updateGraph(data, logScaleAnimationProgress);
        updateCoordinateSystem(data, logScaleAnimationProgress);
        updateHoverOverlay(data, mouseX, mouseY);
    };
    const { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart } = useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, ranges, isLogScaled, render);
    const handleMouseEnter = () => {
        swapInWebglCanvas();
        renderChart();
    };
    const handleMouseOut = () => {
        swapOutWebglCanvas();
        renderChart();
        copyChart();
    };
    const handleMouseMove = () => {
        renderChart();
    };
    return (React.createElement("div", { ref: rootRef, className: ownClasses.root },
        React.createElement("div", { ref: overlayRef, className: ownClasses.overlay, onMouseEnter: handleMouseEnter, onMouseOut: handleMouseOut, onMouseMove: handleMouseMove }),
        React.createElement("canvas", { ref: canvasRef, width: width * devicePixelRatio, height: height * devicePixelRatio })));
};
export default MultiIterationTrendChart;
