import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            padding: factoryTheme.spacing(6, 0),
        },
        items: {
            flex: 1,
            display: 'inline-flex',
            flexWrap: 'wrap',
            '& > *': {
                marginRight: factoryTheme.spacing(3),
            },
        },
        links: {
            display: 'inline-flex',
        },
    });
});
export const useLineBreakStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            marginBottom: factoryTheme.spacing(3),
        },
    });
});
