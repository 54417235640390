import React from 'react';
import clsx from 'clsx';
import Slider from '../../../../common/ui/lib/BaseSlider';
import Label from '../../../../common/ui/lib/BaseLabel';
import { useStyles } from './styles';
const SliderIteration = ({ selected, options, onIterationChange }) => {
    const ownClasses = useStyles();
    const handleRangeChange = (selectedRange) => {
        onIterationChange({ selected: selectedRange, options });
    };
    const handleRangeReset = () => {
        onIterationChange({ selected: undefined, options });
    };
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Label, { classes: { label: clsx(ownClasses.label, ownClasses.item), text: ownClasses.labelText }, showEmptyCloseBtn: true }, "Trace Iteration"),
        React.createElement(Slider, { selected: selected, options: options, onRangeChange: handleRangeChange, onRangeReset: handleRangeReset })));
};
export default SliderIteration;
