import React from 'react';
import { Checkbox } from '@material-ui/core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useStyles } from './styles';
const BaseCheckbox = props => {
    const ownClasses = useStyles({ checked: props.checked });
    return (React.createElement(Checkbox, Object.assign({ classes: { root: ownClasses.root, checked: ownClasses.checked }, icon: React.createElement("div", { className: ownClasses.iconBox }), checkedIcon: React.createElement("div", { className: clsx(ownClasses.iconBox, ownClasses.iconBoxChecked) },
            React.createElement(Icon, { icon: ['fal', 'check'] })) }, props, { disableRipple: true })));
};
export default BaseCheckbox;
