import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ spacing }) => {
    return createFactoryStyles({
        comparisonParts: {
            display: 'flex',
        },
        comparisonPart: {
            width: '50%',
        },
        comparisonPartOne: {
            marginRight: spacing(4),
        },
        comparisonPartTwo: {},
    });
});
