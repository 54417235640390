import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { cloneJSON, deepFind } from '../../../utils';
import Select from '../Select';
import Title from '../../lib/BaseTitle';
import { shortenSha } from '../../../helpers';
import Label from '../../lib/BaseLabel';
import Tooltip from '../../lib/BaseTooltip';
import Code from '../../lib/BaseCode';
import { useTableStyles, useErrorStyles, useSummuryStyles } from './styles';
var COLUMN_TYPES;
(function (COLUMN_TYPES) {
    COLUMN_TYPES["PRIMARY"] = "primary";
    COLUMN_TYPES["STATUS"] = "status";
    COLUMN_TYPES["MENU"] = "menu";
})(COLUMN_TYPES || (COLUMN_TYPES = {}));
const defaultProps = {
    columns: {
        0: {
            title: `Repositories that this commit depends on`,
            dataRef: `dependee.repository`,
            type: COLUMN_TYPES.PRIMARY,
        },
        1: { title: `Commit / Tag`, dataRef: `dependee.sha`, type: COLUMN_TYPES.PRIMARY, shorten: true },
        2: { title: 'Status', dataRef: `status`, type: COLUMN_TYPES.STATUS },
        4: { title: '', type: COLUMN_TYPES.MENU, menuItems: { rerun: true } },
    },
};
const AnalysisError = (props) => {
    const ownClasses = useErrorStyles();
    const lines = props.error.split('\n');
    return (React.createElement("div", { className: ownClasses.rootError },
        React.createElement(Code, { classes: {
                line: ownClasses.error,
                lineNumber: ownClasses.error,
                root: ownClasses.error,
            }, hasLineNumbers: true, lines: lines })));
};
const SummaryAnalyzes = props => {
    const ownClasses = useSummuryStyles();
    const { analysis, title, onRunChange } = props;
    const findSelectedRun = (analyzes) => {
        for (const a of Object.keys(analyzes)) {
            if (analyzes[a].selected)
                return analyzes[a];
        }
        return Object.values(analyzes)[0];
    };
    const selectedAnalysis = findSelectedRun(analysis);
    const selectedID = selectedAnalysis ? String(selectedAnalysis.id) : '';
    const optionalIDs = Object.keys(analysis).map(x => String(x));
    const aggregatedStatus = selectedAnalysis.status;
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(Title, { classes: { root: ownClasses.title }, type: "h3" }, title),
        React.createElement("div", { className: ownClasses.details },
            React.createElement("div", { className: ownClasses.details },
                analysis && (React.createElement("div", { className: ownClasses.detail },
                    React.createElement(Select, { selected: selectedID, options: optionalIDs, minWidth: 50, onChange: newAnalysis => onRunChange(newAnalysis) }))),
                analysis && (React.createElement(React.Fragment, null,
                    React.createElement("div", { "data-tip": true, "data-for": "status", className: ownClasses.detail },
                        React.createElement(Label, { width: 100, classes: {
                                label: ownClasses[aggregatedStatus],
                            } }, aggregatedStatus)),
                    React.createElement(Tooltip, { id: "status" }, "Status")))))));
};
const TableAnalyzes = props => {
    const ownClasses = useTableStyles();
    const { analyzes, columns } = props;
    // @ts-ignore
    const [analyzesWithSelectedRun, setAnalyzesWithSelectedRun] = useState(null);
    const [rows, setRows] = useState([]);
    const findSelectedRun = (analyzes) => {
        for (const a of Object.keys(analyzes)) {
            if (analyzes[a].selected)
                return analyzes[a];
        }
        return Object.values(analyzes)[0];
    };
    const reconstructAnalyzesWithLastRunAsSelected = () => {
        const processedAnalyzes = cloneJSON(analyzes);
        if (Object.values(processedAnalyzes).length) {
            const firstAnalysis = Object.values(processedAnalyzes)[0];
            const isSelectedAlreadySet = Object.values(firstAnalysis).some(run => run.selected);
            if (!isSelectedAlreadySet) {
                Object.values(processedAnalyzes).forEach(runs => {
                    const runNumbers = Object.keys(runs);
                    const lastRunNumber = runNumbers[runNumbers.length - 1];
                    const lastRunData = runs[lastRunNumber];
                    lastRunData.selected = true;
                });
            }
        }
        return processedAnalyzes;
    };
    const constructCell = (columnType, dataRef, selectedRun, shorten) => {
        const cell = {};
        switch (columnType) {
            case COLUMN_TYPES.PRIMARY:
            case COLUMN_TYPES.STATUS: {
                if (!deepFind(selectedRun, dataRef))
                    throw Error(`[TableAnalyzes]: Provided dataRef '${dataRef}' does not exist in the analysis run data.`);
                cell.text = deepFind(selectedRun, dataRef);
                if (shorten && cell?.text?.length === 40)
                    cell.text = shortenSha(cell.text);
                break;
            }
            case COLUMN_TYPES.MENU:
                break;
            default:
                throw new Error(`[TableAnalyzes]: '${columnType}' is not a valid column type`);
        }
        return cell;
    };
    const handleRunSelect = (newID) => {
        const analyzes = cloneJSON(analyzesWithSelectedRun);
        Object.values(analyzes['dependency-analysis']).forEach((runData) => (runData.selected = false));
        analyzes['dependency-analysis'][newID].selected = true;
        setAnalyzesWithSelectedRun(analyzes);
    };
    const constructRows = () => {
        const rows = [];
        if (!analyzesWithSelectedRun?.['dependency-analysis'] ||
            !findSelectedRun(analyzesWithSelectedRun['dependency-analysis'])['commit-dependency'])
            return rows;
        const selectedAnalysisRepos = findSelectedRun(analyzesWithSelectedRun['dependency-analysis'])['commit-dependency'];
        Object.values(selectedAnalysisRepos).forEach(repoSymbol => {
            const cells = [];
            Object.values(columns).forEach(column => {
                const cell = constructCell(column.type, column.dataRef, repoSymbol, column.shorten);
                cells.push(cell);
            });
            rows.push({ cells });
        });
        return rows;
    };
    useEffect(() => {
        setRows(constructRows());
    }, [analyzesWithSelectedRun]);
    useEffect(() => {
        setAnalyzesWithSelectedRun(reconstructAnalyzesWithLastRunAsSelected());
    }, [analyzes]);
    if (!rows || !analyzesWithSelectedRun)
        return React.createElement(React.Fragment, null);
    const errorText = findSelectedRun(analyzesWithSelectedRun['dependency-analysis'])?.error;
    return (React.createElement(React.Fragment, null,
        React.createElement(SummaryAnalyzes, { title: `Dependency Analysis`, analysis: analyzesWithSelectedRun['dependency-analysis'], onRunChange: handleRunSelect }),
        React.createElement("table", { className: ownClasses.table },
            React.createElement("thead", null,
                React.createElement("tr", { className: clsx(ownClasses.tr, ownClasses.trHead) }, Object.values(columns).map(column => (React.createElement("th", { className: clsx(ownClasses.th, ownClasses[column.type]), key: column.title }, column.title))))),
            React.createElement("tbody", null, rows.map((row, index) => (React.createElement("tr", { key: `tr-${index}`, className: clsx(ownClasses.tr, ownClasses.trBody) }, Object.values(columns).map((column, cIndex) => (React.createElement("td", { key: `td-${cIndex}`, className: clsx(ownClasses.td, ownClasses[column.type]) },
                React.createElement(React.Fragment, null,
                    column.type === COLUMN_TYPES.PRIMARY && React.createElement("p", null, row.cells[cIndex].text),
                    column.type === COLUMN_TYPES.STATUS && (React.createElement("div", { className: clsx(ownClasses.status, ownClasses[row?.cells?.[cIndex]?.text]) },
                        React.createElement("p", null, row.cells[cIndex].text)))))))))))),
        errorText && React.createElement(AnalysisError, { error: errorText })));
};
TableAnalyzes.defaultProps = defaultProps;
export default TableAnalyzes;
