import React from 'react';
import NotificationProvider from '../common/services/notification/provider';
import { useStyles } from './styles';
import Router from './Router';
const App = () => {
    const ownClasses = useStyles();
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement(NotificationProvider, null,
            React.createElement(Router, null))));
};
export default App;
