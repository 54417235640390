import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import createFactoryTheme from '../common/ui/styles/theme';
import initIconLibrary from '../common/ui/assets/icons';
import store from '../common/store';
import App from './App';
import '../common/ui/styles/reset.css';
import '../common/ui/styles/base.scss';
initIconLibrary();
ReactDOM.render(React.createElement(ThemeProvider, { theme: createFactoryTheme({}) },
    React.createElement(Provider, { store: store },
        React.createElement(App, null))), document.getElementById('root'));
