import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            '& $buttonRoot': {
                borderRadius: 0,
            },
            '& $buttonRoot:first-child': {
                borderTopLeftRadius: factoryTheme.shape.borderRadius.small,
                borderBottomLeftRadius: factoryTheme.shape.borderRadius.small,
            },
            '& $buttonRoot:last-child': {
                borderTopRightRadius: factoryTheme.shape.borderRadius.small,
                borderBottomRightRadius: factoryTheme.shape.borderRadius.small,
                marginRight: 0,
            },
        },
        buttonRoot: {
            marginRight: 2,
        },
    });
});
