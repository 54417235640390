import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import StatusesLegend from '../../common/ui/components/StatusLegend';
import RepositorySummary from '../../Repository/summary';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import usePage from '../../common/services/page/use';
import Button from '../../common/ui/lib/BaseButton';
import ButtonVerbose from '../../common/ui/components/ButtonVerbose';
import { useStyles } from './styles';
const PageOwner = ({ location }) => {
    const ownClasses = useStyles();
    const [shouldHideWorkflowValidation, setShouldHideWorkflowValidation] = useState(true);
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('owner');
    const owner = payload;
    const sortedRepositories = owner && owner.repositories
        ? Object.values(owner.repositories).sort((a, b) => {
            if (a.updated && !b.updated)
                return -1;
            else if (!a.updated && b.updated)
                return 1;
            else if (!a.updated && !b.updated)
                return a.name < b.name ? -1 : 1;
            return Date.parse(a.updated) > Date.parse(b.updated) ? -1 : 1;
        })
        : [];
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "No repositories recorded for this account yet!", navigation: React.createElement(Navigation, { links: React.createElement(React.Fragment, null,
                React.createElement(Button, { to: `${location.pathname}/settings` },
                    React.createElement("div", { className: ownClasses.settingsButtonContent },
                        React.createElement(Icon, { icon: ['fal', 'cog'] }),
                        React.createElement("p", null, "Settings")))) },
            page.owner && (React.createElement(React.Fragment, null,
                React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange })),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null)) }, sortedRepositories.length > 0 && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: ownClasses.topBar },
            React.createElement(ButtonVerbose, { onToggle: isVerbose => setShouldHideWorkflowValidation(isVerbose) }),
            React.createElement(StatusesLegend, null)),
        sortedRepositories.map(repo => (React.createElement(RepositorySummary, { key: repo.name, url: `/${owner?.name}/${repo.name}/${repo.branch ? `${repo.branch}` : ''}`, title: `${repo.name}${repo.branch ? `@${repo.branch}` : ''}`, pipelines: repo.pipelines, analyses: repo.analysis, shouldHideWorkflowValidation: shouldHideWorkflowValidation })))))));
};
export default PageOwner;
