import merge from 'deepmerge';
import { createStyles } from '@material-ui/core';
export const createFactoryStyles = (styles, overrides = {}) => {
    return createStyles(merge(styles, overrides));
};
export const hideScrollbar = () => ({
    '&::-webkit-scrollbar': { width: '0 !important', height: '0 !important' },
    '-ms-overflow-style': 'none',
});
export const statusColor = (status, theme) => {
    switch (status) {
        case 'success':
            return theme.palette.green[200];
        case 'running':
            return theme.palette.blue[100];
        case 'error':
            return theme.palette.red[100];
        case 'waiting':
            return theme.palette.yellow[100];
        case 'none':
            return theme.palette.grey[100];
        default:
            throw new Error('[statusColor] Given status is not valid.');
    }
};
