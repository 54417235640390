import React from 'react';
import StatusTile from '../StatusTile';
import { useStyles } from './styles';
const StatusLegend = () => {
    const ownClasses = useStyles();
    const statuses = ['none', 'waiting', 'running', 'success', 'error'];
    return (React.createElement("div", { className: ownClasses.root },
        React.createElement("ul", { className: ownClasses.items }, statuses.map(status => (React.createElement("li", { className: ownClasses.item, key: status },
            React.createElement(StatusTile, { classes: { root: ownClasses.statusTile }, key: status, status: status }),
            React.createElement("p", { className: ownClasses.itemText }, status)))))));
};
export default StatusLegend;
