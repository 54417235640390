import React, { useState } from 'react';
import Box from '../../../common/ui/lib/BaseBox';
import Title from '../../../common/ui/lib/BaseTitle';
import { removeFromArrayByValue } from '../../../common/utils';
import List from './list';
import Form from './form';
import { useStyles } from './styles';
const RepoBranchRules = ({ emptyMsg, patterns, onAdd, onRemove }) => {
    const ownClasses = useStyles();
    const [rulePatterns, setRulePatterns] = useState(patterns);
    const handleRuleRemove = (pattern) => {
        const patternsUpdated = removeFromArrayByValue(rulePatterns, pattern);
        setRulePatterns(patternsUpdated);
        onRemove(pattern);
    };
    const handleRuleAdd = (pattern) => {
        setRulePatterns([...rulePatterns, pattern]);
        onAdd(pattern);
    };
    return (React.createElement(Box, { classes: { root: ownClasses.branchRules }, header: React.createElement(Title, { type: "h1" }, "Branch Rules") },
        React.createElement(List, { emptyMsg: emptyMsg, patterns: rulePatterns, onRemove: handleRuleRemove }),
        React.createElement(Form, { patterns: rulePatterns, onSubmit: handleRuleAdd })));
};
export default RepoBranchRules;
