import { makeStyles } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles } from '../../../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        field: {
            display: 'flex',
            alignItems: 'center',
            '& > $error': {
                color: factoryTheme.palette.red[100],
                marginLeft: factoryTheme.spacing(2),
                '&:empty': {
                    display: 'none',
                },
            },
        },
        patternInput: {
            width: 170,
        },
        table: {
            width: '100%',
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            margin: factoryTheme.spacing(3, 0),
        },
        tr: {
            '&:nth-child(odd)': {
                backgroundColor: Color(factoryTheme.palette.purple[600]).alpha(0.4).string(),
            },
        },
        td: {
            height: 25,
            borderRight: `1px solid ${factoryTheme.palette.purple[300]}`,
            verticalAlign: 'middle',
        },
        tdLabel: {
            paddingLeft: factoryTheme.spacing(2),
        },
        tdInput: {
            textAlign: 'center',
            width: 100,
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        saveBtn: {
            width: 100,
        },
        error: {},
    });
});
