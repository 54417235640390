import { makeStyles } from '@material-ui/core';
import Color from 'color';
import { createFactoryStyles } from '../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            height: '100%',
            '& a': {
                color: factoryTheme.palette.green[200],
                textDecoration: 'none',
            },
            '& *::-webkit-scrollbar': {
                width: 5,
                height: 3,
                position: 'absolute',
            },
            '& *:hover::-webkit-scrollbar-thumb': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
            '& *::-webkit-scrollbar-thumb:window-inactive': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
            '& *::-webkit-scrollbar-track': {
                background: 'transparent',
            },
            '& *::-webkit-scrollbar-thumb': {
                background: Color(factoryTheme.palette.green[200]).alpha(0.5).string(),
            },
        },
    });
});
