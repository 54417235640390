import React, { useState, useRef, useEffect } from 'react';
import { Select, InputBase, TextField } from '@material-ui/core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import Item from '../BaseMenu/item';
import { useStyles, useOpenButtonStyle } from './styles';
const OpenButton = ({ onClick, classes }) => {
    const ownClasses = useOpenButtonStyle();
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root), onClick: onClick },
        React.createElement(Icon, { className: ownClasses.icon, icon: ['fas', 'caret-down'] })));
};
const defaultProps = {
    minWidth: 106,
};
const BaseSelect = props => {
    const { options, selectedValue, placeholder, minWidth, disabled, onChange, onEnter, classes, searchable, } = props;
    const ownClasses = useStyles({ minWidth });
    const [controlledValue, setControlledValue] = useState(selectedValue);
    const [optionsWidth, setOptionsWidth] = useState(minWidth);
    const selectInput = useRef();
    useEffect(() => {
        if (selectInput.current) {
            const currentWidth = parseInt(window.getComputedStyle(selectInput.current).width.replace('px', ''));
            const newWidth = currentWidth > minWidth ? currentWidth : minWidth;
            setOptionsWidth(newWidth);
        }
    }, [controlledValue]);
    useEffect(() => {
        setControlledValue(selectedValue);
    }, [selectedValue]);
    const handleChange = (event) => {
        setControlledValue(event.target.value);
        onChange(event.target.value);
    };
    const handleSelect = (_event, value) => {
        setControlledValue(value?.name || '');
        onChange(value?.name ? value.name : '');
    };
    const handleOptionMouseEnter = (event) => {
        // when the option is too long, it becomes scrollable
        // when user hover overs such option, we scroll all the way to the right
        // to make it clear that the option is scrollable
        event.currentTarget.scrollLeft = minWidth;
    };
    const component = searchable ? (React.createElement(Autocomplete, { options: options, classes: {
            root: ownClasses.dropdownRoot,
            inputRoot: ownClasses.dropdownInputRoot,
            input: ownClasses.dropdownInput,
            listbox: ownClasses.paper,
            noOptions: ownClasses.paper,
            popper: ownClasses.dropdownPopper,
            clearIndicator: ownClasses.clearIndicator,
        }, inputValue: controlledValue, onChange: handleSelect, popupIcon: () => React.createElement(OpenButton, { classes: { root: classes?.openButton } }), getOptionLabel: option => option.name, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: placeholder, onChange: handleChange, onKeyDown: (e) => {
                if (e.keyCode === 13 && onEnter)
                    onEnter();
            } }))) })) : (React.createElement(Select, { classes: { root: clsx(ownClasses.root, classes?.root) }, ref: selectInput, value: controlledValue, onChange: handleChange, input: React.createElement(InputBase, { disabled: disabled, classes: { input: clsx(ownClasses.input, classes?.input), root: classes?.inputRoot } }), IconComponent: () => React.createElement(OpenButton, { classes: { root: classes?.openButton } }), displayEmpty: true, renderValue: (selected) => {
            if (selected === '' && !options.find(({ id }) => id === '')) {
                return React.createElement("span", { className: ownClasses.placeholder }, placeholder);
            }
            return options.find(option => option.id === selected)?.name;
        }, MenuProps: {
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            },
            getContentAnchorEl: null,
            classes: { paper: clsx(ownClasses.paper, classes?.menuPaper), list: ownClasses.list },
            disablePortal: true,
        } }, options.map(({ id, name }) => (React.createElement(Item, { value: id, key: name, width: optionsWidth },
        React.createElement("p", { className: clsx(ownClasses.option, { [ownClasses.selectedOption]: id === controlledValue }, classes?.option), onMouseEnter: handleOptionMouseEnter }, id.length > 0 ? name : React.createElement("em", null, name)))))));
    return component;
};
export default BaseSelect;
