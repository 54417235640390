import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            marginBottom: factoryTheme.spacing(4),
            '&:hover $repoTitle': {
                color: factoryTheme.palette.green[200],
            },
        },
        summaryTitle: {
            marginBottom: factoryTheme.spacing(2),
        },
        statusTile: {
            margin: factoryTheme.spacing(0, 1, 1, 0),
        },
        repoTitle: {},
    });
});
