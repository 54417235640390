import * as React from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
const defaultProps = {
    variant: 'contained',
    size: 'smaller',
    type: 'secondary',
    htmlAttrs: { type: 'button' },
};
export const displayName = 'BaseButton';
const BaseButton = props => {
    const { children, classes, href, to, variant, size, type, startIcon, endIcon, onClick, disabled, htmlAttrs, } = props;
    const ownClasses = useStyles({ size, type });
    const linkProps = Boolean(to) ? { component: Link, to } : {};
    return (React.createElement(Button, Object.assign({ classes: {
            root: clsx(ownClasses.root, classes?.root),
            label: clsx(ownClasses.label, classes?.label),
            contained: ownClasses.variantContained,
            startIcon: classes?.startIcon,
            endIcon: classes?.endIcon,
        }, disabled: disabled, href: href, variant: variant, startIcon: startIcon, endIcon: endIcon, disableFocusRipple: true, disableElevation: true, onClick: onClick }, linkProps, htmlAttrs), children));
};
BaseButton.defaultProps = defaultProps;
BaseButton.displayName = displayName;
export default BaseButton;
