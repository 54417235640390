import { makeStyles, fade } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
const menuMaxHeight = 350;
export const useItemStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            height: 24,
            color: factoryTheme.palette.white[200],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: factoryTheme.spacing(0, 2),
            '& > p, & > svg': {
                fontSize: factoryTheme.typography.fontSize.smaller,
            },
            '&:hover': {
                backgroundColor: fade(factoryTheme.palette.green[200], 0.07),
                '& > p, & > svg': {
                    color: `${factoryTheme.palette.green[200]} !important`,
                },
            },
        },
    });
});
export const useMenuStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            position: 'relative',
        },
        list: {
            maxHeight: menuMaxHeight,
            minWidth: 'fit-content',
            backgroundColor: factoryTheme.palette.purple[600],
            border: `1px solid ${factoryTheme.palette.purple[400]}`,
            borderTop: 'none',
            borderRadius: `0 0 ${factoryTheme.shape.borderRadius.small}px ${factoryTheme.shape.borderRadius.small}px`,
            boxShadow: `0 2px 20px 0 ${fade(factoryTheme.palette.purple[600], 0.29)}`,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflowY: 'auto',
        },
        popper: {
            width: '100%',
            minWidth: 'fit-content',
            left: 1,
            zIndex: 1500,
        },
        openToggler: {
            transform: 'rotate(180deg)',
        },
    }, {
        list: {
            padding: 0,
        },
    });
});
