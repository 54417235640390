import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            height: 24,
        },
        label: {
            height: '100%',
            width: ({ width }) => width,
            backgroundColor: factoryTheme.palette.purple[400],
            borderRadius: factoryTheme.shape.borderRadius.small,
            color: factoryTheme.palette.white[200],
            display: 'flex',
            alignItems: 'center',
            padding: factoryTheme.spacing(0, 2),
        },
        closableLabel: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        clickableLabel: {
            color: factoryTheme.palette.green[200],
            cursor: 'pointer',
        },
        labelText: {
            width: '100%',
            fontSize: factoryTheme.typography.fontSize.smaller,
            textAlign: 'center',
        },
    });
});
