import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
const textWidth = 60;
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        items: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        item: {
            display: 'flex',
            justifyContent: 'flex-end',
            '&:last-child > p': {
                width: 'auto',
            },
        },
        itemText: {
            width: textWidth,
        },
        statusTile: {
            marginRight: factoryTheme.spacing(1),
        },
    });
});
