import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            width: '100%',
            marginBottom: factoryTheme.spacing(2),
        },
        alertRoot: {
            width: '100%',
        },
        alertMessage: {
            width: '100%',
            textAlign: 'center',
        },
    }, {
        root: {
            position: 'static',
            transform: 'none',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
    });
});
