import React, { useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import { CHART_CONFIG } from '../config';
import { getShortNotation, minmax, roundOneDecimal, safeLog10, average } from '../../../common/utils';
import { useChart, useChartRenderer } from '../mixins';
import { createBackground, createCurvedLine, createHoverOverlayText, drawDashedPolygon, getLabels, removeChildIfExists, drawCurvedLine, getMap, createCoordinateSystem, createSimplePoint, } from '../utils';
import { getEngine } from '../engine';
import { useStyles } from './style';
const MultiIterationLineChart = ({ width, height, data, ranges, isLogScaled, onIdChange, onTrackerChange, }) => {
    const ownClasses = useStyles();
    const trackerTooltipWrapper = useRef(null);
    const [hoveredTracker, setHoveredTracker] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const { rootRef, overlayRef, canvasRef, stage, setStage } = useChart();
    const calculateDataMap = (data, ranges) => {
        let iterationRange;
        if (ranges && ranges.iterationRange) {
            ({ iterationRange } = ranges);
        }
        else {
            iterationRange = minmax(data
                .map((x) => x.data)
                .flatMap((x) => Object.keys(x))
                .map((x) => Number(x)));
        }
        let durationRange;
        if (ranges && ranges.durationRange) {
            ({ durationRange } = ranges);
        }
        else {
            durationRange = [0, Math.max(...data
                    .map((x) => x.data)
                    .flatMap((lineData) => Object.values(lineData).flatMap(x => x))
                    .map((x) => x.duration))];
        }
        const [mapX] = getMap(iterationRange, [
            CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT,
            width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT,
        ]);
        const topPadding = CHART_CONFIG.CHART.PADDING.VERTICAL.TOP + CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const bottomPadding = CHART_CONFIG.CHART.OFFSET.VERTICAL;
        const [mapY, mapYInverse] = getMap(durationRange, [height - bottomPadding, topPadding]);
        const [mapYLog, mapYLogInverse] = getMap(durationRange.map(safeLog10), [height - bottomPadding, topPadding]);
        return {
            iterationRange,
            durationRange,
            mapX,
            mapY,
            mapYLog,
            mapYInverse,
            mapYLogInverse,
        };
    };
    const render = (data, mouseX, mouseY, logScaleAnimationProgress) => {
        const { durationRange, mapX, mapY, mapYLog, mapYInverse, mapYLogInverse } = calculateDataMap(data, ranges);
        const updateBackground = () => {
            if (!stage.getChildByName(CHART_CONFIG.LAYER.BACKGROUND.NAME)) {
                const background = createBackground(width, height);
                background.name = CHART_CONFIG.LAYER.BACKGROUND.NAME;
                background.zIndex = CHART_CONFIG.LAYER.BACKGROUND.Z_INDEX;
                stage.addChild(background);
            }
        };
        const updateGraph = (data, logScaleAnimationProgress) => {
            const createGraph = (data, logScaleAnimationProgress) => {
                const localMapY = (y) => (1 - logScaleAnimationProgress) * mapY(y) + logScaleAnimationProgress * mapYLog(safeLog10(y));
                const pixelData = [];
                data.forEach((line) => {
                    const linePixelData = { tracker: line.tracker, data: [] };
                    pixelData.push(linePixelData);
                    Object.entries(line.data).forEach(([iteration, points]) => {
                        const pointsPixelData = { points: [] };
                        linePixelData.data.push(pointsPixelData);
                        const x = mapX(Number(iteration));
                        points.forEach((point) => {
                            const y = localMapY(point.duration);
                            pointsPixelData.points.push({ point: [x, y], id: point.id });
                        });
                        const avgY = localMapY(average(points.map((x) => x.duration)));
                        pointsPixelData.joint = [x, avgY];
                    });
                });
                const graph = new PIXI.Container();
                graph.sortableChildren = true;
                pixelData.forEach((lineData) => {
                    const { tracker } = lineData;
                    const pointsData = lineData.data.map((x) => x.joint);
                    const line = createCurvedLine(pointsData, CHART_CONFIG.CHART.LINE.STYLE);
                    line.name = `line-${tracker}`;
                    const expansionPoints = new PIXI.Container();
                    const points = new PIXI.Container();
                    lineData.data.forEach((iterationData) => {
                        if (iterationData.points.length > 1) {
                            const jointPoint = new PIXI.Graphics();
                            jointPoint.alpha = CHART_CONFIG.CHART.LINE.JOINT_POINT.ALPHA;
                            jointPoint.beginFill(CHART_CONFIG.CHART.LINE.JOINT_POINT.COLOR);
                            // @ts-ignore
                            jointPoint.drawCircle(...iterationData.joint, CHART_CONFIG.CHART.LINE.JOINT_POINT.RADIUS);
                            jointPoint.endFill();
                            const expandedIndicator = new PIXI.Graphics();
                            expandedIndicator.beginFill(CHART_CONFIG.CHART.LINE.JOINT_POINT.COLOR);
                            expandedIndicator.drawCircle(...iterationData.joint, 
                            // @ts-ignore
                            CHART_CONFIG.CHART.LINE.JOINT_POINT.RADIUS);
                            expandedIndicator.endFill();
                            expandedIndicator.beginFill(CHART_CONFIG.CHART.LINE.JOINT_POINT.EXPANDED_INDICATOR_COLOR);
                            expandedIndicator.drawCircle(...iterationData.joint, 
                            // @ts-ignore
                            CHART_CONFIG.CHART.LINE.JOINT_POINT.RADIUS - 1);
                            expandedIndicator.endFill();
                            const expandedPoints = new PIXI.Container();
                            iterationData.points.forEach((pointData) => {
                                const expandedLine = new PIXI.Graphics();
                                // @ts-ignore
                                expandedLine.lineStyle(CHART_CONFIG.CHART.LINE.STYLE);
                                // @ts-ignore
                                expandedLine.moveTo(...iterationData.joint);
                                // @ts-ignore
                                expandedLine.lineTo(...pointData.point);
                                expandedPoints.addChild(expandedLine);
                                const expandedPoint = createSimplePoint(pointData.point, () => {
                                    onIdChange(pointData.id);
                                }, () => {
                                    Object.assign(
                                    // @ts-ignore
                                    expandedLine.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.HALF_FOCUS_STYLE);
                                    Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.HALF_FOCUS_STYLE);
                                    // @ts-ignore
                                    expandedPoint.geometry.graphicsData[1].shape.radius =
                                        CHART_CONFIG.CHART.LINE.POINT.RADIUS_HOVERED;
                                    // @ts-ignore
                                    expandedPoint.geometry.graphicsData[0].shape.radius =
                                        CHART_CONFIG.CHART.LINE.POINT.RADIUS_HOVERED - 1;
                                    expandedPoint.geometry.invalidate();
                                    expandedLine.geometry.invalidate();
                                    line.geometry.invalidate();
                                }, () => {
                                    Object.assign(
                                    // @ts-ignore
                                    expandedLine.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.STYLE);
                                    Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.STYLE);
                                    // @ts-ignore
                                    expandedPoint.geometry.graphicsData[1].shape.radius =
                                        CHART_CONFIG.CHART.LINE.POINT.RADIUS;
                                    // @ts-ignore
                                    expandedPoint.geometry.graphicsData[0].shape.radius =
                                        CHART_CONFIG.CHART.LINE.POINT.RADIUS - 1;
                                    expandedPoint.geometry.invalidate();
                                    expandedLine.geometry.invalidate();
                                    line.geometry.invalidate();
                                });
                                expandedPoints.addChild(expandedPoint);
                            });
                            jointPoint.interactive = true;
                            jointPoint.buttonMode = true;
                            jointPoint.hitArea = new PIXI.Circle(...iterationData.joint, CHART_CONFIG.CHART.LINE.INTERACTIVE_RADIUS);
                            jointPoint.mousedown = () => {
                                if (jointPoint.children.length === 0) {
                                    jointPoint.addChild(expandedIndicator);
                                    expansionPoints.addChild(expandedPoints);
                                    getEngine().render();
                                }
                                else {
                                    jointPoint.removeChild(expandedIndicator);
                                    expansionPoints.removeChild(expandedPoints);
                                    getEngine().render();
                                }
                            };
                            points.addChild(jointPoint);
                        }
                        else {
                            const point = createSimplePoint(iterationData.points[0].point, () => {
                                onIdChange(iterationData.points[0].id);
                            }, () => {
                                Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.HALF_FOCUS_STYLE);
                                // @ts-ignore
                                point.geometry.graphicsData[1].shape.radius =
                                    CHART_CONFIG.CHART.LINE.POINT.RADIUS_HOVERED;
                                // @ts-ignore
                                point.geometry.graphicsData[0].shape.radius =
                                    CHART_CONFIG.CHART.LINE.POINT.RADIUS_HOVERED - 1;
                                point.geometry.invalidate();
                                line.geometry.invalidate();
                            }, () => {
                                Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.STYLE);
                                // @ts-ignore
                                point.geometry.graphicsData[1].shape.radius = CHART_CONFIG.CHART.LINE.POINT.RADIUS;
                                // @ts-ignore
                                point.geometry.graphicsData[0].shape.radius =
                                    CHART_CONFIG.CHART.LINE.POINT.RADIUS - 1;
                                point.geometry.invalidate();
                                line.geometry.invalidate();
                            });
                            points.addChild(point);
                        }
                    });
                    const interactiveShape = new PIXI.Graphics();
                    interactiveShape.beginFill(0x00ff00, 0.000001);
                    const clickWidth = 5;
                    interactiveShape.moveTo(pointsData[0][0], pointsData[0][1] - clickWidth);
                    drawCurvedLine(pointsData, interactiveShape, 0, -clickWidth);
                    interactiveShape.lineTo(pointsData[pointsData.length - 1][0], pointsData[pointsData.length - 1][1] + clickWidth);
                    drawCurvedLine(pointsData.reverse(), interactiveShape, 0, clickWidth);
                    interactiveShape.closePath();
                    interactiveShape.endFill();
                    interactiveShape.interactive = true;
                    line.interactive = true;
                    line.buttonMode = true;
                    line.addChild(interactiveShape);
                    line.on('mouseover', (event) => {
                        Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.FOCUS_STYLE);
                        line.geometry.invalidate();
                        getEngine().render();
                        const { clientX: x, clientY: y } = event.data.originalEvent;
                        setHoveredTracker(tracker);
                        setTooltipPosition({ x: x, y: y - 30 });
                    });
                    line.on('mousemove', (event) => {
                        const { clientX: x, clientY: y } = event.data.originalEvent;
                        setTooltipPosition({ x: x, y: y - 30 });
                    });
                    line.on('mouseout', () => {
                        Object.assign(line.geometry.graphicsData[0].lineStyle, CHART_CONFIG.CHART.LINE.STYLE);
                        line.geometry.invalidate();
                        setHoveredTracker(null);
                    });
                    line.on('mousedown', () => {
                        const formObject = (arr) => {
                            if (arr.length !== 0) {
                                const lastElement = arr[arr.length - 1];
                                arr.pop();
                                const resp = {};
                                resp.selected = lastElement;
                                resp.tracker = formObject(arr);
                                if (!resp.tracker) {
                                    resp.optional = true;
                                    delete resp.tracker;
                                }
                                return resp;
                            }
                            return null;
                        };
                        const trackerParts = tracker.split(':');
                        const trackerObj = formObject(trackerParts.reverse());
                        onTrackerChange(trackerObj);
                    });
                    line.zIndex = 0;
                    graph.addChild(line);
                    points.zIndex = 1;
                    graph.addChild(points);
                    expansionPoints.zIndex = 2;
                    graph.addChild(expansionPoints);
                });
                return graph;
            };
            const lastGraph = stage.getChildByName(CHART_CONFIG.LAYER.GRAPH.NAME);
            if (!lastGraph ||
                lastGraph.chart.data !== data ||
                lastGraph.chart.logScaleAnimationProgress !== logScaleAnimationProgress) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.GRAPH.NAME);
                const graph = createGraph(data, logScaleAnimationProgress);
                graph.name = CHART_CONFIG.LAYER.GRAPH.NAME;
                graph.zIndex = CHART_CONFIG.LAYER.GRAPH.Z_INDEX;
                graph.chart = { data, logScaleAnimationProgress };
                stage.addChild(graph);
            }
        };
        const updateCoordinateSystem = (data, logScaleAnimationProgress) => {
            const createChartCoordinateSystem = (data) => {
                const iterationLabels = Object.keys(data[0].data).map(label => ({
                    label: label.toString(),
                    pos: mapX(Number(label)),
                }));
                const durationLabelsLinear = getLabels(...durationRange, 8, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: getShortNotation(label), pos: mapY(label) }));
                const durationLabelsLog = getLabels(...durationRange.map(safeLog10), CHART_CONFIG.COORDINATE_SYSTEM.TICK.COUNT.VERTICAL, CHART_CONFIG.COORDINATE_SYSTEM.TICK.STEPS_WITH_FRACTIONS)
                    .filter(x => x >= 0)
                    .map(label => ({ label: label.toString(), pos: mapYLog(label) }));
                const coordinateSystemLinear = createCoordinateSystem(width, height, iterationLabels, durationLabelsLinear, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ITERATION, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                coordinateSystemLinear.name = 'linear';
                coordinateSystemLinear.alpha = 0;
                const coordinateSystemLog = createCoordinateSystem(width, height, iterationLabels, durationLabelsLog, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.ITERATION, CHART_CONFIG.COORDINATE_SYSTEM.UNIT_LABEL.DURATION);
                coordinateSystemLog.name = 'log';
                coordinateSystemLog.alpha = 0;
                const coordinateSystem = new PIXI.Container();
                coordinateSystem.addChild(coordinateSystemLinear);
                coordinateSystem.addChild(coordinateSystemLog);
                return coordinateSystem;
            };
            const lastCoordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (!lastCoordinateSystem || lastCoordinateSystem.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
                const coordinateSystem = createChartCoordinateSystem(data);
                coordinateSystem.name = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME;
                coordinateSystem.zIndex = CHART_CONFIG.LAYER.COORDINATE_SYSTEM.Z_INDEX;
                coordinateSystem.chart = { data, logScaleAnimationProgress };
                stage.addChild(coordinateSystem);
            }
            const coordinateSystem = stage.getChildByName(CHART_CONFIG.LAYER.COORDINATE_SYSTEM.NAME);
            if (logScaleAnimationProgress === 1) {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
            else if (logScaleAnimationProgress === 0) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else if (isLogScaled) {
                coordinateSystem.getChildByName('linear').alpha = 1;
                coordinateSystem.getChildByName('log').alpha = 0;
            }
            else {
                coordinateSystem.getChildByName('log').alpha = 1;
                coordinateSystem.getChildByName('linear').alpha = 0;
            }
        };
        const updateHoverOverlay = (data, mouseX, mouseY) => {
            const createHoverOverlay = () => {
                const hoverOverlay = new PIXI.Container();
                const lineHorizontal = new PIXI.Graphics();
                lineHorizontal.name = 'lineHorizontal';
                // @ts-ignore
                lineHorizontal.lineStyle(CHART_CONFIG.CHART.HOVER_OVERLAY.LINE_STYLE);
                drawDashedPolygon([
                    { x: width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT, y: 0 },
                    { x: CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT, y: 0 },
                ], lineHorizontal, 1, 2);
                hoverOverlay.addChild(lineHorizontal);
                const labelY = createHoverOverlayText();
                labelY.name = 'labelY';
                labelY.x =
                    CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT + CHART_CONFIG.CHART.HOVER_OVERLAY.MARGIN.HORIZONTAL;
                hoverOverlay.addChild(labelY);
                return hoverOverlay;
            };
            const lastHoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (!lastHoverOverlay || lastHoverOverlay.chart.data !== data) {
                removeChildIfExists(stage, CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
                const hoverOverlay = createHoverOverlay();
                hoverOverlay.name = CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME;
                hoverOverlay.zIndex = CHART_CONFIG.LAYER.HOVER_OVERLAY.Z_INDEX;
                hoverOverlay.chart = { data, mouseX, mouseY };
                stage.addChild(hoverOverlay);
            }
            const hoverOverlay = stage.getChildByName(CHART_CONFIG.LAYER.HOVER_OVERLAY.NAME);
            if (mouseX >= CHART_CONFIG.CHART.PADDING.HORIZONTAL.LEFT &&
                mouseY >= CHART_CONFIG.CHART.PADDING.VERTICAL.TOP &&
                mouseX <= width - CHART_CONFIG.CHART.PADDING.HORIZONTAL.RIGHT &&
                mouseY <= height - CHART_CONFIG.CHART.PADDING.VERTICAL.BOTTOM) {
                const mapBackY = isLogScaled ? mapYLogInverse : mapYInverse;
                hoverOverlay.alpha = 1;
                hoverOverlay.getChildByName('lineHorizontal').y = mouseY;
                hoverOverlay.getChildByName('labelY').y = mouseY;
                hoverOverlay.getChildByName('labelY').text = roundOneDecimal(mapBackY(mouseY)).toString();
            }
            else {
                hoverOverlay.alpha = 0;
            }
        };
        updateBackground();
        updateGraph(data, logScaleAnimationProgress);
        updateCoordinateSystem(data, logScaleAnimationProgress);
        updateHoverOverlay(data, mouseX, mouseY);
    };
    const { swapInWebglCanvas, swapOutWebglCanvas, renderChart, copyChart } = useChartRenderer(stage, setStage, rootRef, overlayRef, canvasRef, width, height, data, ranges, isLogScaled, render);
    const handleMouseEnter = () => {
        swapInWebglCanvas();
        renderChart();
    };
    const handleMouseOut = () => {
        swapOutWebglCanvas();
        renderChart();
        copyChart();
    };
    const handleMouseMove = () => {
        renderChart();
    };
    return (React.createElement("div", { ref: rootRef, className: ownClasses.root },
        React.createElement("div", { ref: overlayRef, className: ownClasses.overlay, onMouseEnter: handleMouseEnter, onMouseOut: handleMouseOut, onMouseMove: handleMouseMove }),
        React.createElement("div", { className: ownClasses.tooltip, ref: trackerTooltipWrapper, style: { left: tooltipPosition.x, top: tooltipPosition.y, display: hoveredTracker ? 'flex' : 'none' } }, hoveredTracker),
        React.createElement("canvas", { ref: canvasRef, width: width * window.devicePixelRatio, height: height * window.devicePixelRatio })));
};
export default MultiIterationLineChart;
