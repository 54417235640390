import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
import { workflowStyles } from '../../common/styles';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        ...workflowStyles(factoryTheme),
        approvalButton: {
            width: '100%',
            marginTop: factoryTheme.spacing(4),
            backgroundColor: factoryTheme.palette.green[200],
        },
    });
});
