import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ spacing }) => {
    return createFactoryStyles({
        title: {
            marginBottom: spacing(4),
        },
        box: {
            marginBottom: spacing(4),
        },
    });
});
