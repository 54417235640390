import React from 'react';
import LayoutPage from '../../common/ui/layouts/Page';
import usePage from '../../common/services/page/use';
import Box from '../../common/ui/lib/BaseBox';
import Title from '../../common/ui/lib/BaseTitle';
import Input from '../../common/ui/lib/BaseInput';
import Button from '../../common/ui/lib/BaseButton';
import { useRequester } from '../../common/services/dataChannel/use';
import useNotification from '../../common/services/notification/use';
import { DiscordWebhookUrl } from '../../common/components/DiscordWebhookUrl/form';
import { DiscordUserId } from '../../common/components/DiscordUserIds/form';
import { useStyles } from './styles';
const PageUserSettings = () => {
    const ownClasses = useStyles();
    const { payload, hasNoData, hasReceivedEndMessage, isConnected } = usePage('user');
    const accessToken = payload['access-token'];
    const requester = useRequester();
    const notification = useNotification();
    const handleTokenRegenerate = () => {
        requester({ route: 'settings/regenerate' });
    };
    const handleDiscordIdSave = (value) => {
        requester({ route: 'settings/discord-id-save', body: { 'discord-id': value } });
        notification.show('Discord ID saved');
    };
    const handleDiscordWebhookUrlSave = (value) => {
        requester({ route: 'settings/discord-webhook-url-save', body: { 'discord-webhook-url': value } });
        notification.show('Discord Webhook URL saved');
    };
    const handlePermissionCacheClear = () => {
        requester({ route: 'settings/clear-permission-cache' });
        notification.show('Permission cache has been cleared.');
    };
    return (React.createElement(LayoutPage, { classes: { main: ownClasses.layoutMain }, hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected },
        accessToken && (React.createElement(Box, { classes: { root: ownClasses.box }, header: React.createElement(Title, { type: "h1" }, "Access Token") },
            React.createElement("div", { className: ownClasses.accessTokenField },
                React.createElement(Input, { name: "accessToken", type: "text", value: accessToken, readOnly: true, classes: { root: ownClasses.tokenInput } }),
                React.createElement(Button, { onClick: handleTokenRegenerate }, "Regenerate")))),
        React.createElement(Box, { classes: { root: ownClasses.box }, header: React.createElement(Title, { type: "h1" }, "Discord ID") },
            React.createElement(DiscordUserId, { onSubmit: (_username, id) => {
                    handleDiscordIdSave(id);
                }, showUsername: false, username: payload?.['username'], id: payload?.['discord-id'] })),
        React.createElement(Box, { header: React.createElement(Title, { type: "h1" }, "Discord Webhook URL") },
            React.createElement(DiscordWebhookUrl, { onSubmit: handleDiscordWebhookUrlSave, url: payload?.['discord-webhook-url'] })),
        React.createElement(Button, { classes: { root: ownClasses.clearCacheButton }, onClick: handlePermissionCacheClear }, "Clear permission cache")));
};
export default PageUserSettings;
