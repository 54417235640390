import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { displayName as BaseLabelName } from '../BaseLabel';
import { useStyles } from './styles';
const sanityCheck = (children) => {
    if (React.Children.toArray(children).some(child => !child.type ||
        typeof child.type === 'string' ||
        child.type.displayName !== BaseLabelName)) {
        throw new Error('[BaseLabelGroup] BaseLabelGroup only accepts BaseLabel(s) as its children.');
    }
};
const BaseLabelGroup = ({ children }) => {
    const ownClasses = useStyles();
    sanityCheck(children);
    return (React.createElement("div", { className: ownClasses.root }, React.Children.toArray(children).map(child => {
        const Label = child;
        return cloneElement(Label, {
            classes: {
                ...Label.props.classes,
                root: clsx(ownClasses.labelRoot, Label.props.classes?.root),
                label: clsx(ownClasses.label, Label.props.classes?.label),
            },
        });
    })));
};
export default BaseLabelGroup;
