import * as PIXI from 'pixi.js';
import { CHART_CONFIG } from './config';
let engine;
export const getEngine = (width, height) => {
    if (!engine) {
        const chartStyle = {
            width: width,
            height: height,
            antialias: true,
            backgroundColor: CHART_CONFIG.CHART.BG_COLOR,
            resolution: window.devicePixelRatio,
        };
        engine = new PIXI.Application(chartStyle);
        engine.renderer.plugins.interaction.moveWhenInside = true;
        engine.renderer.plugins.interaction.useSystemTicker = false;
        engine.ticker.autoStart = false;
        engine.ticker.stop();
    }
    if (width && height) {
        engine.renderer.resize(width, height);
    }
    return engine;
};
