import React, { useState, createContext } from 'react';
import Notification from '../../ui/components/Notification';
import { useStyles } from './styles';
const CalledOutsideProviderError = new Error("You're calling this function from a component that is not a child of NotificationProvider.");
export const NotificationContext = createContext({
    show: () => {
        throw CalledOutsideProviderError;
    },
    hide: () => {
        throw CalledOutsideProviderError;
    },
    hideAll: () => {
        throw CalledOutsideProviderError;
    },
});
const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const defaultDurations = {
        info: 5000,
        success: 3000,
        error: undefined,
    };
    const defaultType = 'info';
    const defaultPosition = {
        vertical: 'top',
        horizontal: 'center',
    };
    const show = (message, { type, duration, position } = {}) => {
        const id = Math.random();
        const notification = {
            key: id,
            open: true,
            message,
            type: type || defaultType,
            duration: duration || defaultDurations[type || defaultType],
            position: position || defaultPosition,
        };
        setNotifications(notifications => [...notifications, notification]);
        return id;
    };
    const hide = (id) => {
        setNotifications(notifications => notifications.filter(n => n.key !== id));
    };
    const hideAll = () => {
        setNotifications([]);
    };
    const classes = useStyles();
    return (React.createElement(NotificationContext.Provider, { value: { show, hide, hideAll } },
        children,
        React.createElement("div", { className: classes.root }, notifications.map(({ key, ...notification }) => {
            return React.createElement(Notification, Object.assign({ key: key }, notification, { onClose: () => hide(key) }));
        }))));
};
export default NotificationProvider;
