import { isTreeNode } from '../../common/ui/components/SelectTree';
import { isObject, cloneJSON } from '../../common/utils';
export const removeOptions = (page, ignoreList = []) => {
    const removeOptionsRec = (currPage, parentKey = '') => {
        if (isObject(currPage)) {
            Object.keys(currPage).forEach(key => {
                if (key === 'options' && !ignoreList.includes(parentKey))
                    delete currPage.options;
                else
                    removeOptionsRec(currPage[key], key);
            });
        }
        else if (Array.isArray(currPage)) {
            currPage.forEach((child) => {
                removeOptionsRec(child);
            });
        }
    };
    const newPage = cloneJSON(page);
    removeOptionsRec(newPage);
    return newPage;
};
export const addOptionalSelectToEnd = (tree, dataRef) => {
    let subTree = tree;
    while (subTree) {
        if (isTreeNode(subTree, dataRef))
            subTree = subTree[dataRef];
        else
            break;
    }
    if (subTree.selected) {
        delete subTree.optional;
        subTree[dataRef] = { optional: true };
    }
    return tree;
};
