import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../styles/helpers';
export const useSummaryStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            padding: factoryTheme.spacing(2, 0),
            '&:first-child': {
                paddingTop: 0,
            },
            '&:last-child': {
                paddingBottom: 0,
            },
        },
    });
});
export const useSummaryColStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: (props) => ({
            borderRight: `1px solid ${factoryTheme.palette.purple[300]}`,
            padding: factoryTheme.spacing(1, 2, 0, 1),
            display: 'flex',
            flex: 1,
            '&:last-child': {
                borderRight: 'none',
            },
            transition: 'all 0.3s',
            ...props.dynamicStyle,
            marginTop: props.isHidden ? '-25px' : 0,
        }),
        stacked: {
            borderRight: 'none',
        },
    });
});
const summaryTitleWidth = 255;
const summaryTitleWidthBig = 330;
export const useSummaryRowStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            borderBottom: '1px solid transparent',
            display: 'flex',
            overflow: 'hidden',
            '&:last-child': {
                borderBottom: `1px solid ${factoryTheme.palette.purple[300]}`,
            },
        },
        bigWidth: {
            minWidth: `${summaryTitleWidthBig}px!important`,
        },
        title: {
            color: factoryTheme.palette.white[200],
            minWidth: summaryTitleWidth,
            padding: factoryTheme.spacing(1),
            fontSize: factoryTheme.typography.fontSize.smaller,
            lineHeight: '14px',
        },
        row: {
            width: '100%',
            borderLeft: `1px solid ${factoryTheme.palette.purple[300]}`,
            display: 'flex',
            flexDirection: 'row',
        },
        stacked: {
            flexDirection: 'column',
        },
    });
});
