import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import Button from '../../../ui/lib/BaseButton';
import Input from '../../../ui/lib/BaseInput';
import { getSchemaForManualEntry } from './schema';
import { useStyles } from './styles';
const EnvVariableForm = ({ onSubmit, variables }) => {
    const ownClasses = useStyles();
    const schema = getSchemaForManualEntry(variables);
    const { handleSubmit: submitHandler, register, errors, getValues, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const firstFieldRef = useRef(null);
    const errorMessages = Object.entries(errors).map(([, error]) => error?.message);
    const handleSubmit = () => {
        const fieldValues = getValues();
        const { key, value } = fieldValues;
        onSubmit(`${key}=${value}`);
        reset();
        firstFieldRef.current?.focus();
    };
    return (React.createElement("form", { autoComplete: "off", onSubmit: submitHandler(handleSubmit) },
        React.createElement("div", { className: ownClasses.fields },
            React.createElement(Input, { name: "key", ref: e => {
                    register(e);
                    firstFieldRef.current = e;
                }, classes: { root: ownClasses.inputKey }, type: "text", placeholder: "Key" }),
            React.createElement("span", null, "="),
            React.createElement(Input, { name: "value", ref: register(), classes: { root: ownClasses.inputValue }, type: "text", placeholder: "Value" }),
            React.createElement(Button, { htmlAttrs: { type: 'submit' }, classes: { root: ownClasses.addBtn } },
                React.createElement(Icon, { icon: ['fal', 'plus'] }))),
        React.createElement("p", { className: ownClasses.error }, errorMessages[0])));
};
export default EnvVariableForm;
