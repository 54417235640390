import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '../../lib/BaseButton';
import { useStyles } from './styles';
const ButtonVerbose = ({ onToggle }) => {
    const [isVerbose, setIsVerbose] = useState(true);
    const ownClasses = useStyles({ isVerbose });
    const handleClick = () => {
        onToggle(!isVerbose);
        setIsVerbose(!isVerbose);
    };
    return (React.createElement(Button, { classes: { root: clsx({ [ownClasses.verbose]: isVerbose }) }, onClick: handleClick }, "Verbose"));
};
export default ButtonVerbose;
