import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Measure from 'react-measure';
import { useStyles } from './styles';
const removeTailEmptyLines = (lines) => {
    let filteredLines = [...lines];
    while (filteredLines[filteredLines.length - 1] === '')
        filteredLines = filteredLines.slice(0, -1);
    return filteredLines;
};
const wrapUrlsWithLink = (lines) => {
    const urlRegex = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;
    const parsedLines = lines.map(line => {
        let parsedLine = line;
        line.match(urlRegex)?.forEach(match => {
            let url = match;
            const trailingSymbolsToRemove = ',."\')( `:;/\\';
            while (trailingSymbolsToRemove.includes(url.substr(url.length - 1)))
                url = url.slice(0, -1);
            parsedLine = parsedLine.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
        });
        return parsedLine;
    });
    return parsedLines;
};
const retainSpaces = (lines) => {
    return lines.map(line => line.replace(/\s\s/g, '&nbsp;&nbsp;'));
};
const parseLines = (lines) => {
    return lines.map(line => new DOMParser().parseFromString(line, 'text/html'));
};
const defaultProps = {
    firstLineNumber: 1,
};
const BaseCode = forwardRef((props, refGutter) => {
    const { lines, highlightedLines, hasLineNumbers, gutterWidth, maxLines, showAllUrl, firstLineNumber, classes, } = props;
    const [processedLines, setProcessedLines] = useState([]);
    const [controlledGutterWidth, setControlledGutterWidth] = useState(gutterWidth || 0);
    const ownClasses = useStyles({ controlledGutterWidth });
    const shouldDisplayShowAll = maxLines && showAllUrl && firstLineNumber > 1;
    const getLineNumber = (lineCount) => {
        return maxLines && lines.length > maxLines ? lineCount + (lines.length - maxLines) : lineCount;
    };
    const shouldHighlightLine = (index) => {
        return Boolean(hasLineNumbers && highlightedLines && highlightedLines.includes(getLineNumber(index) + 1));
    };
    useEffect(() => {
        let slicedLines = [...lines];
        if (maxLines && maxLines < lines.length)
            slicedLines = lines.slice(lines.length - maxLines);
        const readyLines = wrapUrlsWithLink(removeTailEmptyLines(retainSpaces(slicedLines)));
        const parsedLines = parseLines(readyLines);
        setProcessedLines(parsedLines);
    }, [lines]);
    useEffect(() => {
        gutterWidth && setControlledGutterWidth(gutterWidth);
    }, [gutterWidth]);
    const onGutterResize = (contentRect) => {
        !gutterWidth && contentRect?.bounds?.width && setControlledGutterWidth(contentRect.bounds.width);
    };
    return (React.createElement("div", { className: clsx(ownClasses.root, classes?.root) },
        React.createElement("div", { className: ownClasses.lines },
            shouldDisplayShowAll && (React.createElement("div", { className: ownClasses.showAll },
                React.createElement(Link, { to: showAllUrl, target: "_blank" }, "Show all ..."))),
            processedLines.map((line, index) => (React.createElement("div", { key: index, className: clsx(ownClasses.line, classes?.line) },
                hasLineNumbers && (React.createElement(Measure, { bounds: true, onResize: onGutterResize }, ({ measureRef: refGutterText }) => (React.createElement("div", { ref: index === processedLines.length - 1 ? refGutter : null, className: clsx(ownClasses.lineNumber, ownClasses.lineNumber) },
                    React.createElement("p", { ref: index === processedLines.length - 1 ? refGutterText : null }, getLineNumber(index) + firstLineNumber))))),
                React.createElement("pre", { className: clsx(ownClasses.lineContent, {
                        [ownClasses.lineHighlighted]: shouldHighlightLine(getLineNumber(index) + firstLineNumber - 1),
                    }), dangerouslySetInnerHTML: { __html: line.body.innerHTML } })))))));
});
BaseCode.defaultProps = defaultProps;
export default BaseCode;
