import { intervalToDuration } from 'date-fns';
import { useEffect, useRef } from 'react';
export const isObject = (value) => {
    return value && typeof value === 'object' && value.constructor === Object;
};
export const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
export const safeLog10 = (x) => (x >= 1 ? Math.log10(x) : 0);
export const roundOneDecimal = (x) => {
    if (x > 100)
        return Math.round(x);
    return Math.round(x * 10) / 10;
};
export const getShortNotation = (x) => {
    if (x >= 1000) {
        return `${roundOneDecimal(x / 1000)}k`;
    }
    return x.toString();
};
export const clamp = (num, min, max) => (num <= min ? min : num >= max ? max : num);
export const deepFind = (obj, path) => {
    const paths = path.split('.');
    let current = obj;
    for (let i = 0; i < paths.length; i++) {
        const p = paths[i];
        if (typeof current[p] === 'undefined')
            return undefined;
        current = current[p];
    }
    return current;
};
export const cloneJSON = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};
export const groupBy = (array, ...accessors) => {
    if (!accessors.length) {
        return array;
    }
    const [accessor, ...remainingAccessors] = accessors;
    const groups = {};
    array.forEach((item) => {
        const key = accessor(item);
        groups[key] = groups[key] || [];
        groups[key].push(item);
    });
    Object.entries(groups).forEach(([key, value]) => {
        groups[key] = groupBy(value, ...remainingAccessors);
    });
    return groups;
};
export const percentile = (array, percent) => {
    return array[Math.floor((array.length * percent) / 100)];
};
export const setValueOfNestedProp = (obj, path, value) => {
    let schema = obj;
    const pathArr = path.split('.');
    for (let i = 0; i < pathArr.length - 1; i++) {
        const pathStr = pathArr[i];
        if (!schema[pathStr])
            schema[pathStr] = {};
        schema = schema[pathStr];
    }
    if (value === '')
        delete schema[pathArr[pathArr.length - 1]];
    else
        schema[pathArr[pathArr.length - 1]] = value;
    return obj;
};
export const average = (array) => array.reduce((prev, curr) => prev + curr) / array.length;
export const compareJSON = (a = {}, b = {}) => {
    return JSON.stringify(a) === JSON.stringify(b);
};
export const getDuration = (start, end) => {
    const duration = intervalToDuration({ start, end });
    const padZeros = (x) => (x < 10 ? `0${x}` : String(x));
    const hours = duration.hours ? padZeros(duration.hours) : '00';
    const minutes = duration.minutes ? padZeros(duration.minutes) : '00';
    const seconds = duration.seconds ? padZeros(duration.seconds) : '00';
    return `${duration.days ? `${duration.days} days ` : ''} ${hours}:${minutes}:${seconds}`;
};
export const deletePropertyPath = (obj, path) => {
    if (!obj || !path)
        throw new Error('[deletePropertyPath] object or path not provided.');
    const updatedObj = cloneJSON(obj);
    const keys = path.split('.');
    keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
            delete acc[key];
            return true;
        }
        return acc[key];
    }, updatedObj);
    return updatedObj;
};
export const minmax = (array) => [Math.min(...array), Math.max(...array)];
export const capitalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const removeFromArrayByValue = (arr, value) => {
    const index = arr.indexOf(value);
    const updatedArr = [...arr];
    updatedArr.splice(index, 1);
    return updatedArr;
};
export const compareArrays = (a, b) => {
    if (a === b)
        return true;
    if (a === null || b === null)
        return false;
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
};
export const deepEquals = (a, b) => {
    if (a === Object(a) && b === Object(b)) {
        if (!compareArrays(Object.keys(a).sort(), Object.keys(b).sort()))
            return false;
        for (const key of Object.keys(a)) {
            if (!deepEquals(a[key], b[key]))
                return false;
        }
        return true;
    }
    if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length)
            return false;
        for (const key in a) {
            if (!deepEquals(a[key], b[key]))
                return false;
        }
        return true;
    }
    return a === b;
};
export function useEffectWithObjectDependencies(func, dependencies) {
    const firstCall = useRef(true);
    const previousDependencies = useRef(dependencies);
    useEffect(() => {
        if (firstCall.current || !deepEquals(previousDependencies.current, dependencies)) {
            func();
        }
        firstCall.current = false;
        previousDependencies.current = dependencies;
    }, dependencies);
}
// NOTE: vue-router complains when the new route being `push`ed or `replace`d is identical to the current one.
// We create a wrapper here to suppress this.
export const pushRouteSafe = (router, path, query) => {
    router.push({ path, query }, () => ({}), () => ({}));
};
