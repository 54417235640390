import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        guide: {
            padding: factoryTheme.spacing(0, 2),
        },
        hint: {
            color: factoryTheme.palette.purple[100],
        },
        error: {
            color: factoryTheme.palette.red[100],
        },
    });
});
