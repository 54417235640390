import { makeStyles } from '@material-ui/core/styles';
import { createFactoryStyles, statusColor } from '../../styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
        },
        title: {
            flexGrow: 1,
        },
        details: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        detail: {
            marginLeft: factoryTheme.spacing(2),
        },
        menuToggler: {
            width: 24,
            height: 24,
            backgroundColor: factoryTheme.palette.purple[400],
            borderRadius: factoryTheme.shape.borderRadius.small,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > svg': {
                color: factoryTheme.palette.green[200],
            },
        },
        statusText: {
            color: `${factoryTheme.palette.purple[500]} !important`,
            fontWeight: 'bold',
        },
        menuItemText: {
            color: factoryTheme.palette.green[200],
        },
        success: {
            backgroundColor: `${statusColor('success', factoryTheme)} !important`,
        },
        running: {
            backgroundColor: `${statusColor('running', factoryTheme)} !important`,
        },
        error: {
            backgroundColor: `${statusColor('error', factoryTheme)} !important`,
        },
        waiting: {
            backgroundColor: `${statusColor('waiting', factoryTheme)} !important`,
        },
        none: {
            backgroundColor: `${statusColor('none', factoryTheme)} !important`,
        },
    });
});
