import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import React from 'react';
import Button from '../../../../common/ui/lib/BaseButton';
import Input from '../../../../common/ui/lib/BaseInput';
import getSchema from './schema';
import { useStyles } from './styles';
const RepoBranchRuleForm = ({ onSubmit, patterns }) => {
    const ownClasses = useStyles();
    const schema = getSchema(patterns);
    const { handleSubmit: submitHandler, register, errors, getValues, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const errorMessages = Object.entries(errors).map(([, error]) => error?.message);
    const handleSubmit = () => {
        const { pattern } = getValues();
        onSubmit(pattern);
        reset();
    };
    return (React.createElement("form", { autoComplete: "off", onSubmit: submitHandler(handleSubmit) },
        React.createElement("div", { className: ownClasses.fields },
            React.createElement(Input, { name: "pattern", ref: register(), classes: { root: ownClasses.input }, type: "text", placeholder: "Pattern" }),
            React.createElement(Button, { htmlAttrs: { type: 'submit' }, classes: { root: ownClasses.addBtn } },
                React.createElement(Icon, { icon: ['fal', 'plus'] }))),
        React.createElement("p", { className: ownClasses.error }, errorMessages[0])));
};
export default RepoBranchRuleForm;
