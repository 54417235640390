import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { constructUrlForOwnerPage, constructUrlForRepoSettingsPage, constructUrlForBranchRulePage, constructUrlForRepoPage, constructUrlForCommitPage, constructUrlForRepoPageBranch, constructUrlForAutomationPage, } from './helpers';
const useNavigation = () => {
    const page = useSelector((state) => state.page);
    const history = useHistory();
    const navHandlers = {
        handleOwnerClose() {
            const url = '/';
            history.push(url);
        },
        handleOwnerChange(newOwner) {
            const url = constructUrlForOwnerPage(page, newOwner);
            history.replace(url);
        },
        handleOwnerSettingsClose() {
            const url = constructUrlForOwnerPage(page);
            history.push(url);
        },
        handleRepoClose() {
            const url = constructUrlForOwnerPage(page);
            history.push(url);
        },
        handleRepoChange(newRepo) {
            const url = constructUrlForRepoPage(page, newRepo);
            history.push(url);
        },
        handleRepoSettingsClose() {
            const url = constructUrlForRepoPage(page);
            history.push(url);
        },
        handleBranchRuleClose() {
            const url = constructUrlForRepoSettingsPage(page);
            history.push(url);
        },
        handleBranchRuleChange(newBranchRule) {
            const url = constructUrlForBranchRulePage(page, newBranchRule);
            history.push(url);
        },
        handleCommitClose() {
            const url = constructUrlForRepoPage(page);
            history.push(url);
        },
        handleCommitChange(newCommit) {
            const url = constructUrlForCommitPage(page, newCommit);
            history.push(url);
        },
        handleBranchClose() {
            const url = constructUrlForRepoPage(page);
            history.push(url);
        },
        handleBranchChange(newBranch) {
            const url = constructUrlForRepoPageBranch(page, newBranch);
            history.push(url);
        },
        handleAutomationChange(newAutomation) {
            const url = constructUrlForAutomationPage(page, newAutomation);
            history.push(url);
        },
        handleAutomationClose() {
            const url = constructUrlForCommitPage(page);
            history.push(url);
        },
        handleRunClose() {
            const url = constructUrlForCommitPage(page);
            history.push(url);
        },
    };
    return { page, navHandlers };
};
export default useNavigation;
