import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Input from '../../../ui/lib/BaseInput';
import ButtonGroup from '../../../ui/lib/BaseButtonGroup';
import Button from '../../../ui/lib/BaseButton';
import { useStyles } from './styles';
import { getDiscordWebhookUrlSchema } from './schema';
export const DiscordWebhookUrl = ({ onSubmit, url }) => {
    const ownClasses = useStyles();
    const { handleSubmit, getValues, errors, register, setValue } = useForm({
        resolver: yupResolver(getDiscordWebhookUrlSchema()),
    });
    const handleDiscordWebhookUrlSubmit = () => {
        const updatedValues = getValues();
        onSubmit(updatedValues.discordWebhookUrl);
    };
    return (React.createElement("div", null,
        React.createElement("form", { autoComplete: "off", onSubmit: handleSubmit(handleDiscordWebhookUrlSubmit) },
            React.createElement("div", { className: ownClasses.discordWebhookUrlField },
                React.createElement(Input, { name: "discordWebhookUrl", type: "text", defaultValue: url, ref: register(), classes: { root: ownClasses.tokenInput } }),
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { onClick: () => {
                            setValue('discordWebhookUrl', '');
                            onSubmit('');
                        } },
                        React.createElement(Icon, { icon: ['fal', 'times'] })),
                    React.createElement(Button, { htmlAttrs: { type: 'submit' } }, "Save")))),
        React.createElement("p", { className: ownClasses.error }, errors.discordWebhookUrl?.message)));
};
