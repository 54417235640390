import React, { useState } from 'react';
import Measure from 'react-measure';
import LayoutPage from '../../common/ui/layouts/Page';
import Navigation from '../../common/ui/components/Navigation';
import Select from '../../common/ui/components/Select';
import LoadingIndicator from '../../common/ui/lib/BaseLoadingIndictor';
import ButtonGithub from '../../common/ui/components/ButtonGithub';
import PipelineBuild from '../../Pipeline/types/Build';
import PipelineRelease from '../../Pipeline/types/Release';
import usePage from '../../common/services/page/use';
import SelectCommit from '../../common/ui/components/SelectCommit';
import Box from '../../common/ui/lib/BaseBox';
import Title from '../../common/ui/lib/BaseTitle';
import Label from '../../common/ui/lib/BaseLabel';
import Code from '../../common/ui/lib/BaseCode';
import { useStyles } from './styles';
const PageCommit = () => {
    const ownClasses = useStyles();
    const [gutterWidth, setGutterWidth] = useState(0);
    const onGutterResize = (contentRect) => {
        contentRect?.bounds?.width && setGutterWidth(contentRect.bounds.width);
    };
    const { payload, hasNoData, hasReceivedEndMessage, isConnected, page, navHandlers, isNavLoading } = usePage('commit');
    const commit = payload;
    const pipelineSummaryWhitelist = [
        'status',
        'started',
        'ended',
        'select',
        'menu',
    ];
    let sourceLines = [];
    let errorLines = [];
    if (commit?.configuration?.source && commit?.configuration?.error) {
        sourceLines = commit.configuration.source?.split('\n');
        errorLines = commit.configuration.error?.split('\n');
    }
    return (React.createElement(LayoutPage, { hasNoData: hasNoData, hasReceivedEndMessage: hasReceivedEndMessage, isConnected: isConnected, noDataMessage: "No pipelines recorded for this commit yet!", navigation: React.createElement(Navigation, null,
            page.owner && (React.createElement(Select, Object.assign({}, page.owner, { onClose: navHandlers.handleOwnerClose, onChange: navHandlers.handleOwnerChange }))),
            page.owner?.repository && (React.createElement(Select, Object.assign({}, page.owner.repository, { onClose: navHandlers.handleRepoClose, onChange: navHandlers.handleRepoChange }))),
            page.owner?.repository?.commit && (React.createElement(React.Fragment, null,
                React.createElement(SelectCommit, Object.assign({}, page.owner.repository.commit, { onClose: navHandlers.handleCommitClose, onChange: navHandlers.handleCommitChange })),
                React.createElement(ButtonGithub, { githubUrl: `https://github.com/${page.owner.selected}/${page.owner.repository.selected}/commit/${page.owner.repository.commit.selected}` }))),
            isNavLoading && React.createElement(LoadingIndicator, null)) },
        commit?.configuration?.status === 'valid' && (React.createElement(React.Fragment, null,
            commit?.pipelines?.build && (React.createElement(PipelineBuild, { build: commit.pipelines.build, summaryWhitelist: pipelineSummaryWhitelist })),
            commit?.pipelines?.release && (React.createElement(PipelineRelease, { release: commit.pipelines.release, summaryWhitelist: pipelineSummaryWhitelist })))),
        commit?.configuration?.status === 'invalid' && (React.createElement(Box, { header: React.createElement("div", { className: ownClasses.automationVerificationHeader },
                React.createElement(Title, { type: "h2" }, "./.factory/automation.yml"),
                React.createElement(Label, { classes: { label: ownClasses.labelInvalid } }, "Invalid")) }, sourceLines.length && errorLines.length && (React.createElement(React.Fragment, null,
            React.createElement(Measure, { bounds: true, onResize: onGutterResize }, ({ measureRef: refGutter }) => (React.createElement(React.Fragment, null,
                React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Source"),
                React.createElement(Code, { classes: { root: ownClasses.sourceCode }, hasLineNumbers: true, lines: sourceLines, ref: sourceLines.length >= errorLines.length ? refGutter : undefined, gutterWidth: sourceLines.length < errorLines.length ? gutterWidth : undefined }),
                React.createElement(Title, { classes: { root: ownClasses.codeTitle }, type: "h3", hasLine: true }, "Error"),
                React.createElement(Code, { classes: {
                        line: ownClasses.errorCodeLine,
                        lineNumber: ownClasses.errorCodeLineNumber,
                    }, hasLineNumbers: true, lines: errorLines, ref: errorLines.length > sourceLines.length ? refGutter : undefined, gutterWidth: errorLines.length <= sourceLines.length ? gutterWidth : undefined }))))))))));
};
export default PageCommit;
