import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        topBar: {
            height: 24,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: factoryTheme.spacing(3),
        },
        settingsButtonContent: {
            display: 'flex',
            alignItems: 'center',
            '& > p': {
                marginLeft: factoryTheme.spacing(1),
            },
        },
    });
});
