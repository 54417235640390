import React, { useEffect, useState } from 'react';
import MultiIterationTrendChart from '../MultiIterationTrendChart';
import SingleIterationTrendChart from '../SingleIterationTrendChart';
const TrendChart = ({ width, height, data, ranges, isLogScaled, switchToLine }) => {
    const getIterations = () => {
        return Array.from(new Set(Object.values(data).flatMap(x => Object.keys(x))));
    };
    const getOnlyOneIteration = () => {
        const iterations = getIterations();
        return iterations.length === 1;
    };
    const [onlyOneIteration, setOnlyOneIteration] = useState(getOnlyOneIteration());
    const [singleIterationData, setSingleIterationData] = useState(null);
    const getSingleIterationData = () => {
        const iteration = getIterations()[0];
        const dataaa = { iteration, percentiles: {} };
        Object.entries(data).forEach(([key, value]) => {
            dataaa.percentiles[key] = Object.values(value)[0];
        });
        return dataaa;
    };
    useEffect(() => {
        setOnlyOneIteration(getOnlyOneIteration());
        setSingleIterationData(getSingleIterationData());
    }, [data]);
    let chart = null;
    if (onlyOneIteration && singleIterationData)
        chart = (React.createElement(SingleIterationTrendChart, { data: singleIterationData, width: width, height: height, isLogScaled: isLogScaled, ranges: ranges }));
    if (!onlyOneIteration && data)
        chart = (React.createElement(MultiIterationTrendChart, { data: data, width: width, height: height, isLogScaled: isLogScaled, ranges: ranges, switchToLine: () => switchToLine && switchToLine() }));
    return React.createElement(React.Fragment, null, chart);
};
export default TrendChart;
