import { makeStyles, fade } from '@material-ui/core/styles';
import Color from 'color';
import { createFactoryStyles, statusColor } from '../../styles/helpers';
const rowHeight = 24;
const thTd = (factoryTheme) => ({
    borderRight: `1px solid ${factoryTheme.palette.purple[300]}`,
    boxSizing: 'border-box',
    textAlign: 'center',
    '&:last-child': {
        borderRight: 'none',
    },
});
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        title: {
            marginBottom: factoryTheme.spacing(4),
        },
        table: {
            width: '100%',
            border: `1px solid ${factoryTheme.palette.purple[300]}`,
            fontSize: factoryTheme.typography.fontSize.smaller,
        },
        tr: {
            width: '100%',
            lineHeight: `${rowHeight}px`,
            alignItems: 'center',
        },
        trHead: {
            borderBottom: `1px solid ${factoryTheme.palette.purple[300]}`,
            backgroundColor: Color(factoryTheme.palette.purple[600]).alpha(0.4).string(),
        },
        trBody: {
            '&:nth-child(even), &:nth-child(even) $selectInput': {
                backgroundColor: Color(factoryTheme.palette.purple[600]).alpha(0.4).string(),
            },
            '&:nth-child(odd), &:nth-child(odd) $selectInput': {
                backgroundColor: factoryTheme.palette.purple[500],
            },
            '&:nth-child(even) $selectOpenButton': {
                backgroundColor: Color(factoryTheme.palette.purple[400]).darken(0.18).string(),
            },
            '&:nth-child(odd) $selectOpenButton': {
                backgroundColor: Color(factoryTheme.palette.purple[400]).darken(0.03).string(),
            },
            '&:hover a > p': {
                color: factoryTheme.palette.green[200],
            },
        },
        th: {
            ...thTd(factoryTheme),
            color: factoryTheme.palette.purple[200],
            fontWeight: 'normal',
        },
        td: {
            ...thTd(factoryTheme),
            '& > a': { color: factoryTheme.palette.white[200] },
        },
        selectInput: {
            borderRadius: 0,
        },
        selectMenu: {},
        selectOpenButton: {
            borderLeft: 'none',
        },
        rowMenu: {
            backgroundColor: factoryTheme.palette.purple[400],
            '& > svg': {
                color: factoryTheme.palette.green[200],
            },
        },
        primary: {
            textAlign: 'left',
            flex: 1,
            paddingLeft: factoryTheme.spacing(2),
        },
        run: {
            width: 57,
        },
        time: {
            width: 144,
        },
        status: {
            width: 144,
        },
        regular: {
            width: 144,
        },
        menu: {
            width: rowHeight,
        },
        menuItemText: {
            color: factoryTheme.palette.green[200],
        },
        success: {
            backgroundColor: fade(statusColor('success', factoryTheme), 0.25),
            color: statusColor('success', factoryTheme),
        },
        running: {
            backgroundColor: fade(statusColor('running', factoryTheme), 0.25),
            color: factoryTheme.palette.purple[50],
        },
        error: {
            backgroundColor: fade(statusColor('error', factoryTheme), 0.25),
            color: statusColor('error', factoryTheme),
        },
        waiting: {
            backgroundColor: fade(statusColor('waiting', factoryTheme), 0.25),
            color: statusColor('waiting', factoryTheme),
        },
        none: {
            backgroundColor: fade(statusColor('none', factoryTheme), 0.25),
            color: statusColor('none', factoryTheme),
        },
    });
});
