import clsx from 'clsx';
import React from 'react';
import LoadingIndicator from '../../lib/BaseLoadingIndictor';
import PageHeader from './Header';
import PageFooter from './Footer';
import { useStyles } from './styles';
const LayoutPage = ({ classes, children, navigation, hasReceivedEndMessage, hasNoData, isConnected, noDataMessage, comparison, }) => {
    const ownClasses = useStyles();
    const shouldShowLoading = !hasReceivedEndMessage && isConnected;
    const shouldShowNoDataMsg = hasNoData && noDataMessage;
    const shouldShowContent = !shouldShowNoDataMsg;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { isConnected: isConnected }),
        React.createElement("main", { className: clsx(ownClasses.main, classes?.main, comparison ? ownClasses.comparisonView : ownClasses.defaultView) },
            navigation,
            shouldShowLoading && React.createElement(LoadingIndicator, null),
            shouldShowNoDataMsg && React.createElement("p", { className: ownClasses.noDataMessage }, noDataMessage),
            shouldShowContent && children),
        React.createElement(PageFooter, null)));
};
export default LayoutPage;
