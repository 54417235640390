import { makeStyles } from '@material-ui/core';
import { createFactoryStyles } from '../../../common/ui/styles/helpers';
export const useStyles = makeStyles(({ factory: factoryTheme }) => {
    return createFactoryStyles({
        boxHeader: {
            paddingBottom: factoryTheme.spacing(2),
        },
        boxContent: {
            paddingTop: factoryTheme.spacing(2),
        },
    });
});
