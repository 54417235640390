import React from 'react';
import SummaryPipeline from '../../summary';
import Box from '../../../common/ui/lib/BaseBox';
import WorkflowQuality from '../../../Workflow/types/Quality';
import Divider from '../../../common/ui/lib/BaseDivider';
import WorkflowCorrectness from '../../../Workflow/types/Correctness';
import WorkflowPerformance from '../../../Workflow/types/Performance';
import { PipelineName } from '../../common/types';
import useSummary from '../../../common/services/summary/use';
import { constructUrlForPipelinePage } from '../../../common/helpers';
import { useStyles } from './styles';
const PipelineBuild = ({ build, summaryWhitelist }) => {
    const ownClasses = useStyles();
    const { selectedRun, summary, handleRunChange, handlePipelineRerun } = useSummary(build, summaryWhitelist);
    const workflowSummaryWhitelist = [
        'status',
        'started',
        'ended',
        'select',
        'menu',
    ];
    return (React.createElement(Box, { classes: { header: ownClasses.boxHeader, content: ownClasses.boxContent }, header: React.createElement(SummaryPipeline, { url: constructUrlForPipelinePage(build[selectedRun].symbol), name: PipelineName.Build, summary: summary, onRunChange: handleRunChange, onRerun: () => handlePipelineRerun(PipelineName.Build) }) },
        build[selectedRun].workflows.quality && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowQuality, { classes: { root: ownClasses.workflowBox }, quality: build[selectedRun].workflows.quality, summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider }))),
        build[selectedRun].workflows.correctness && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowCorrectness, { classes: { root: ownClasses.workflowBox }, correctness: build[selectedRun].workflows.correctness, summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider }))),
        build[selectedRun].workflows.performance && (React.createElement(React.Fragment, null,
            React.createElement(WorkflowPerformance, { classes: { root: ownClasses.workflowBox }, performance: build[selectedRun].workflows.performance, summaryWhitelist: workflowSummaryWhitelist }),
            React.createElement(Divider, { className: ownClasses.workflowDivider })))));
};
export default PipelineBuild;
