//https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const spinner = {
    A: 1,
    B: 1,
    animate() {
        const b = [];
        const z = [];
        this.A += 0.07;
        this.B += 0.03;
        const cA = Math.cos(this.A), sA = Math.sin(this.A), cB = Math.cos(this.B), sB = Math.sin(this.B);
        for (let k = 0; k < 1760; k++) {
            b[k] = k % 80 === 79 ? '\n' : ' ';
            z[k] = 0;
        }
        for (let j = 0; j < 6.28; j += 0.07) {
            // j <=> theta
            const ct = Math.cos(j), st = Math.sin(j);
            for (let i = 0; i < 6.28; i += 0.02) {
                // i <=> phi
                const sp = Math.sin(i), cp = Math.cos(i), 
                // R1 + R2*cos(theta)
                h = ct + 2, 
                // this is 1/z
                D = 1 / (sp * h * sA + st * cA + 5), 
                // this is a clever factoring of some of the terms in x' and y'
                t = sp * h * cA - st * sA;
                const x = 0 | (40 + 30 * D * (cp * h * cB - t * sB)), y = 0 | (12 + 15 * D * (cp * h * sB + t * cB)), o = x + 80 * y, N = 0 | (8 * ((st * sA - sp * ct * cA) * cB - sp * ct * sA - st * cA - cp * ct * sB));
                if (y < 22 && y >= 0 && x >= 0 && x < 79 && D > z[o]) {
                    z[o] = D;
                    b[o] = '.,-~:;=!*#$@'[N > 0 ? N : 0];
                }
            }
        }
        return b.join('');
    },
};
export const computeDownloadSize = (content) => {
    let bytes = new Blob([content]).size;
    const thresh = 1000;
    if (Math.abs(bytes) < thresh)
        return `${bytes} B`;
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};
